
import { Options, Vue } from "vue-class-component";
import GenericPage from "@/components/general-information/GenericPage.vue";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import FeaturedProducts from "@/components/featured/FeaturedProducts.vue";

@Options({
  components: {
    GenericPage, StatementBanner, FeaturedProducts 
  }
})
export default class DataDeletionPage extends Vue {
  content = `<p>An individual has the right to erasure, also known as ‘the right to be forgotten’. The principle underpinning this right is to enable an individual to request the deletion or removal of personal data where there is no compelling reason for its continued processing.</p>
    <p>Should you wish for Clifton Clothing to delete your data, please send an email to <a href="mailto:operations@cliftonclothing.com">operations@cliftonclothing.com</a> with the subject <span class="deletion-email-title">'Please delete my Clifton Clothing account'</span> and confirm the email address you have created a Clifton Clothing profile with, and we will delete your account and confirm this with you via email.</p>`;
}
