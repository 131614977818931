
import { UserAddresses } from "@/network/api";
import { mixins, Options, Vue } from "vue-class-component";
import BasciPromptModal from '@/components/misc/BasicPromptModal.vue'
import { ScrollTop } from "@/mixins/utilities";


@Options({
  components: { BasciPromptModal },
  props: { 
    address: { default: null }
  },
  emits: ['edit', 'reload', 'delete']
})
export default class AddressTile extends mixins(ScrollTop) {
  selectedAddressId = '';

  topAnchor() {
    this.scrollTop(".profile-content-container", false);
  }

  makePrimary(address: any) {
    if(!address.isPrimary) {
      let clonedAddress = {...address}
      clonedAddress.isPrimary = true;

      UserAddresses.userAddressesIdPut(clonedAddress.id, clonedAddress)
      .then((res) => {
        if(res.data.succeeded) {
          this.$notify({ type: "success", text: "Primary address updated." });
          this.$emit('reload');
        }      
      })
      .catch((error) => {
console.log(error)
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
  }

  emitDelete() {
    this.$emit('delete')
  }
}
