
import { mixins, Options, Vue } from "vue-class-component";
import router from "@/router";
import { store } from "@/store";
import { RegisterModel } from "@/api-client";
import { Form, Field, ErrorMessage } from "vee-validate";
import { SocialProviders } from "@/store/modules/auth";
import { IReCaptchaComposition, useReCaptcha  } from 'vue-recaptcha-v3'
import { CallbackTypes } from "vue3-google-login";
import Spinner from "@/components/spinner/Spinner.vue";
import { Newsletter } from "@/mixins/utilities";

@Options({
  components: { Form, Field, ErrorMessage, Spinner },
})
export default class RegistrationPage extends mixins(Newsletter) {
  newUser: RegisterModel = {
    displayName: "",
    email: "",
    password: "",
    googleRecaptchaResponse: ''
  };
  confirmPassword = "";
  socialEnabled = true;
  showPassword = false;
  showConfirmPassword = false;
  isNewsletter = false;
  termsRegister = false
  recapthcaObject: IReCaptchaComposition | undefined = undefined
  loading = false
  regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(.{8,})$/

  errors: Array<string> | null = null;

  async recaptcha () {  
    if(this.recapthcaObject) {
      this.loading = true
      // Wait until recaptcha has been loaded.
      await this.recapthcaObject.recaptchaLoaded()

      // Execute reCAPTCHA with action
      const token = await this.recapthcaObject.executeRecaptcha('register')
      
      // Do stuff with the received token.
      this.loading = false
      return token
    }
  }

  created() {
    this.recapthcaObject = useReCaptcha() as IReCaptchaComposition
  }

  togglePassword(fieldName:string) {
    let field;
    if(fieldName === 'password') {
      field = document.getElementById('register-password') as HTMLInputElement;
      this.showPassword = !this.showPassword;

      if(this.showPassword) {
        field.type = 'text'
      } else {
        field.type = 'password'
      }
    } else {
      field = document.getElementById('register-confirm-password') as HTMLInputElement;
      this.showConfirmPassword = !this.showConfirmPassword;

      if(this.showConfirmPassword) {
        field.type = 'text'
      } else {
        field.type = 'password'
      }
    }
  }

  async register() {
    this.errors = null;
    if(this.newUser.password != this.confirmPassword) {
      this.errors = ['Passwords do not match'];
    } else {
      let recaptchaToken = await this.recaptcha()
      this.loading = true

      if (this.isNewsletter) {
        this.newsletterSubscribe(this.newUser.email, 'Registration process newsletter opt-in');
      }

      if(recaptchaToken) {
        this.newUser.googleRecaptchaResponse = recaptchaToken
      }
      this.loading = true
      window.scrollTo({top: 0, behavior: 'smooth'});
      store.dispatch("auth/register", this.newUser).then((res) => {
        router.push({ name: 'EmailVerification', query: { sent: 'success' } });
        this.loading = false;
      })
      .catch((error) => {
        console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
    this.loading = false;
  }

  fbResponse(data: any) {
    if(data?.authResponse) {
      this.loading = true
      window.scrollTo({top: 0, behavior: 'smooth'});
      store
      .dispatch("auth/socialAuth", {
        provider: SocialProviders.Facebook,
        token: data.authResponse.accessToken,
      })
      .then(async (res) => {
        
        const returnUrl = router.currentRoute.value.query["returnUrl"] as string;
        await router.push({ path: returnUrl || "/" });
        this.loading = false;
      })
      .catch((error) => {
        console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
      this.loading = false;
    }
  }

  googleLogin: CallbackTypes.CredentialCallback = (response) => {
    if(response?.credential) {
      this.loading = true
      window.scrollTo({top: 0, behavior: 'smooth'});
        store
          .dispatch("auth/socialAuth", {
            provider: SocialProviders.Google,
            token: response.credential,
          })
          .then(async (res) => {
            const returnUrl = router.currentRoute.value.query["returnUrl"] as string;
            await router.push({ path: returnUrl || "/" });
            this.loading = false;
          })
          .catch((error) => {
            console.log(error)
            let errors = error.response.data.errors;
            this.loading = false;
            errors.forEach((error: any) => {
              
              let errors = error.response.data.errors;
              errors.forEach((error: any) => {
                this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
              });
            });
          });
      this.loading = false;
    }
  }
}

