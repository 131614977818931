
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { mixins, Options, Vue } from "vue-class-component";
import { Categories, Products } from "@/network/api";
import { CategoryViewModel, ProductImageViewModel, ProductViewModel } from "@/api-client";
import { CalculatePrice, Currency, UploadPath } from "@/mixins/utilities";

type FeaturedType = "product" | "category";
type FeaturedItem = {
  id: string;
  isNew?: boolean;
  name: string;
  type: FeaturedType;
  defaultQuantity?: number | null | undefined;
  defaultQuantityPrice?: number | null | undefined;
  minimumQuantity?: number | null | undefined;
  categorySlug: string | undefined;
  productSlug?: string;
  thumbnailImages: ProductImageViewModel[] | CategoryViewModel[];
};

@Options({
  components: {
    Splide,
    SplideSlide,
  },
})
export default class FeaturedAll extends mixins(UploadPath, Currency, CalculatePrice) {
  featuredProducts: Array<ProductViewModel> = [];
  featuredCategories: Array<CategoryViewModel> = [];
  featuredItems: FeaturedItem[] = [];
  options = {
    autoplay: true,
    resetProgress: true,
    clones: 0,
    // cover: true,
    mediaQuery: "max",
    rewind: true,
    rewindSpeed: 1500,
    pagination: false,
    arrows: true,
    perMove: 1,
    perPage: 4,
    pauseOnHover: true,
    pauseOnFocus: false,
    interval: 5000,
    breakpoints: {
      1600: {
        perPage: 3,
      },
      991: {
        rewindSpeed: 2000,
      },
      767: {
        perPage: 2,
      },
      575: {
        perPage: 1,
        rewindSpeed: 2500,
      },
    },
  };

  async created() {
    await this.loadInitialData().then(() => {
      this.mergeFeatured();
    });
  }

  getFromToPrice(defaultPrice: any, defaultQty: any, minQty: any) {
    if (!defaultPrice) {
      return ``;
    }

    return `From ${this.currency().symbol}${this.calculatePrice(defaultPrice, this.currency().pricingMultiplier)} each for ${defaultQty} items | Minimum ${minQty}`;
  }
  

  mergeFeatured() {
    const products: FeaturedItem[] = this.featuredProducts.map(product => {
      return {
        id: product.id,
        isNew: product.isNew,
        name: product.name,
        type: "product",
        defaultQuantity: product.defaultQuantity,
        defaultQuantityPrice: product.defaultQuantityPrice,
        minimumQuantity: product.minimumQuantity,
        categorySlug: product.primaryCategory?.fullSlug,
        productSlug: product.slug,
        thumbnailImages: product.thumbnailImages,
      }
    })
    const categories: FeaturedItem[] = this.featuredCategories.map(category => {
      return {
        id: category.id,
        isNew: false,
        name: category.name,
        type: "category",
        defaultQuantity: undefined,
        defaultQuantityPrice: undefined,
        minimumQuantity: undefined,
        categorySlug: category.fullSlug,
        productSlug: undefined,
        thumbnailImages: category.thumbnailImages,
      }
    })
    const merged: FeaturedItem[] = [...products, ...categories];

    // Fisher-Yates shuffle algorithm
    for (let i = merged.length - 1; i > 0; i--) {
      // Generate a random index from 0 to i
      const randomIndex = Math.floor(Math.random() * (i + 1));

      // Swap elements at randomIndex and i
      [merged[i], merged[randomIndex]] = [merged[randomIndex], merged[i]];
    }

    this.featuredItems = merged;
  }

  async loadInitialData() {
    await Products.productsFeaturedGet(1, 99999)
      .then((res) => {
        console.log(res);
        this.featuredProducts = res.data.resultData?.items as Array<ProductViewModel>;
      })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });

    await Categories.categoriesFeaturedGet(1, 99999)
      .then((res) => {
        console.log(res);
        this.featuredCategories = res.data.resultData?.items as Array<CategoryViewModel>;
      })
      .catch((error) => {
        console.log(error);
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }
}
