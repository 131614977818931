import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bd73ba5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "basket-product-tile-container col-12" }
const _hoisted_2 = { class: "bg-secondary-light basket-product-tile col-12 d-flex flex-column flex-md-row flex-md-wrap justify-content-md-center align-items-md-center" }
const _hoisted_3 = { class: "col-12 d-block d-md-none p-1 px-2 p-md-2 pb-1 product-details-container" }
const _hoisted_4 = { class: "m-0 h-sm-secondary name-heading" }
const _hoisted_5 = {
  key: 0,
  class: "m-0 p-md-primary"
}
const _hoisted_6 = { class: "d-flex justify-content-sm-center align-items-sm-center product-image-info-container col-12" }
const _hoisted_7 = { class: "product-details-container col-md-8 col-md-9" }
const _hoisted_8 = { class: "pb-2 py-md-2 px-2 px-md-4" }
const _hoisted_9 = { class: "col-12 d-none d-md-block" }
const _hoisted_10 = { class: "m-0 h-sm-secondary name-heading" }
const _hoisted_11 = {
  key: 0,
  class: "col-12 d-none d-md-block"
}
const _hoisted_12 = { class: "m-0 pt-1 p-md-primary" }
const _hoisted_13 = { class: "col-12 d-flex mb-1 mb-sm-2 mb-md-3 flex-column flex-md-row flex-wrap" }
const _hoisted_14 = { class: "col-12 col-md-6 d-flex flex-wrap align-items-end justify-content-md-start align-items-end pt-md-1 pt-xl-0" }
const _hoisted_15 = { class: "p-lg-primary m-0 col-12" }
const _hoisted_16 = {
  key: 0,
  class: "p-md-primary m-0 text-uppercase col-12"
}
const _hoisted_17 = {
  key: 0,
  class: "col-12 col-md-6 justify-content-end align-items-end h-sm-secondary d-flex m-0"
}
const _hoisted_18 = {
  key: 1,
  class: "p-xs-primary m-0 col-12",
  style: {"color":"crimson"}
}
const _hoisted_19 = { class: "tile-buttons col-12 col-md-7" }
const _hoisted_20 = { class: "tile-buttons col-12 col-md-5" }
const _hoisted_21 = { class: "magnify-preview-body d-flex flex-wrap h-100" }
const _hoisted_22 = { class: "col-12 d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileProductTile = _resolveComponent("ProfileProductTile")!
  const _component_SizeNameSelector = _resolveComponent("SizeNameSelector")!
  const _component_PreviewImage = _resolveComponent("PreviewImage")!
  const _component_BasicVueModal = _resolveComponent("BasicVueModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass({ 'pt-3 pt-sm-4': _ctx.i != 0 })
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.product.customizedProduct.name), 1),
            (_ctx.product.customizedProduct.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.product.customizedProduct.title), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_ProfileProductTile, {
              isSVG: true,
              showClose: false,
              border: true,
              imageUrl: _ctx.product.customizedProduct.previewImageUrl,
              backImageUrl: _ctx.product.customizedProduct.backPreviewImageUrl,
              linkTo: { name: 'Designer', query: { 'saved-design': _ctx.product.customizedProduct.id } },
              bg: 'light',
              class: "col-4 col-md-3 p-1 p-sm-2 p-md-0 pt-0 p-md-0",
              showButton: !_ctx.isCustomerQuote,
              magnify: _ctx.magnify,
              onShowMagnify: _cache[0] || (_cache[0] = ($event: any) => (_ctx.magnifyDesign = !_ctx.magnifyDesign))
            }, null, 8, ["imageUrl", "backImageUrl", "linkTo", "showButton", "magnify"]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.productName), 1)
                ]),
                (_ctx.productTitle)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.productTitle), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("h4", _hoisted_15, "QTY: " + _toDisplayString(_ctx.quantityPerItem(_ctx.product)), 1),
                    (_ctx.quantityPerItem(_ctx.product) >= _ctx.product.customizedProduct.minimumQuantity)
                      ? (_openBlock(), _createElementBlock("h6", _hoisted_16, _toDisplayString((_ctx.storeCurrency || _ctx.currency()).symbol) + _toDisplayString(_ctx.calculatePrice(_ctx.product.price, (_ctx.storeCurrency || _ctx.currency()).pricingMultiplier)) + "/unit", 1))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.quantityPerItem(_ctx.product) >= _ctx.product.customizedProduct.minimumQuantity)
                    ? (_openBlock(), _createElementBlock("h6", _hoisted_17, _toDisplayString((_ctx.storeCurrency || _ctx.currency()).symbol) + _toDisplayString(_ctx.thousandSeparator(_ctx.calculatePrice(_ctx.total, (_ctx.storeCurrency || _ctx.currency()).pricingMultiplier))), 1))
                    : (!_ctx.isStoreCustomerCart)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_18, "The minimum quantity for this product is " + _toDisplayString(_ctx.product.customizedProduct.minimumQuantity) + ". Please add units or remove the product.", 1))
                      : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["d-flex row", {'store-cust-action': _ctx.isStoreCustomerCart}])
                }, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", {
                      class: "btn-md-secondary py-2 py-xl-3 mb-xxl-1 text-center sizes mb-2 mb-md-0",
                      id: "show-size-modal",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update'), (_ctx.showModal = true)))
                    }, _toDisplayString(!_ctx.isStoreCustomerCart ? 'Add / edit sizes' : 'Enter size & details'), 1)
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('removeId', _ctx.product.id, 'save'))),
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#removeProduct",
                      class: "remove btn-md-secondary py-2 py-xl-3 mb-xxl-1 text-center"
                    }, "Remove")
                  ])
                ], 2)
              ])
            ])
          ])
        ])
      ], 2)
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_SizeNameSelector, {
        inCart: _ctx.isStoreCustomerCart ? false : true,
        quantity: _ctx.quantityPerItem(_ctx.product),
        cart: _ctx.cart,
        product: _ctx.product,
        show: _ctx.showModal,
        onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModal = false)),
        onUpdate: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('reload')))
      }, null, 8, ["inCart", "quantity", "cart", "product", "show"]),
      _createVNode(_component_BasicVueModal, {
        show: _ctx.magnifyDesign,
        onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.magnifyDesign = false)),
        class: "preview"
      }, {
        body: _withCtx(() => [
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_PreviewImage, {
              class: "product-image-container-magnify zoom col-12 col-sm-6 p-1 h-100 bg-light",
              path: `${_ctx.uploadPath}/${_ctx.product.customizedProduct.previewImageUrl}`
            }, null, 8, ["path"]),
            _createVNode(_component_PreviewImage, {
              class: "product-image-container-magnify zoom col-12 col-sm-6 p-1 h-100 bg-light",
              path: `${_ctx.uploadPath}/${_ctx.product.customizedProduct.backPreviewImageUrl}`
            }, null, 8, ["path"])
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.magnifyDesign = false)),
              class: "btn-sm-secondary flex-centered"
            }, " Close ")
          ])
        ]),
        _: 1
      }, 8, ["show"])
    ]))
  ], 64))
}