
import { CalculatePrice, ThousandSeparator, UploadPath } from "@/mixins/utilities";
import { mixins, Options, Vue } from "vue-class-component";
import ProfileProductTile from "@/components/profile/ProfileProductTile.vue";
import { OrderHistoryViewModel, OrderPublicStatus } from "@/api-client";


@Options({
  components: { ProfileProductTile },
  props: {
    product: { default: null },
    order: { default: null },
  },
  emits: ['productRecipients']
})
export default class OrderProductTile extends mixins(UploadPath, CalculatePrice, ThousandSeparator) {
  order: OrderHistoryViewModel = {
    id: "",
    referenceNumber: "",
    orderPublicStatusId: OrderPublicStatus.Processing,
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    additionalCostsConvertedTotal: 0,
    currency: {
      id: "de7c7489-fcc5-40d4-ad7c-f9de27a97f18",
      name: "Pound Sterling",
      code: "GBP",
      symbol: "£",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    orderTotal: 0,
    orderTotalIncludingVoucher: 0,
    orderItems: [],
    deliveryTotal: 0,
    adhocTotal: 0,
    orderInvoices: [],
    customerContacts: [],
    paymentsConvertedTotal: 0,
    paymentsTotal: 0
  };
  product: any;
  calculatedPrice(price: number) {
    if(this.orderCurrency && (price === 0)) {
      return (0).toFixed(this.orderCurrency.decimalPlaces);
    } else if (this.orderCurrency && price) {
      return this.calculatePrice(price, this.orderCurrency.pricingMultiplier, this.orderCurrency.decimalPlaces);
    }
    return "Error";
  }

  get orderCurrency() {
    if (this.order.id) {
      return this.order.currency;
    }
    return null;
  }

  unitPrice(price: number, quantity: number) {
    return price / quantity;
  }
}
