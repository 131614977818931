
import { CartsCreateStoreCartPostRequest, CountryViewModel, DeliveryType, OrderCartItemCreateModel, PaymentProvider, StoreCustomizedProductListViewModel, StoreOrderCartItemCreateModel, StoreOrderCartItemViewModel, StoreOrderCartViewModel, StoreStatus, StoreViewModel } from "@/api-client";
import { CalculatePrice, FormatDate, ScrollTop, ThousandSeparator, QuantityPerItem, SetMetaData, Country } from "@/mixins/utilities";
import { CartItems, Carts, Countries, StoreCustomizedProducts, Stores } from "@/network/api";
import CustomStoreOrderProductTile from "@/components/profile/custom-stores/CustomStoreOrderProductTile.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import countries from "@/assets/country-codes/countries.json";
import { mixins, Options, Vue } from "vue-class-component";
import CartProductTile from "@/components/checkout-process/cart/CartProductTile.vue";
import StripeCheckoutForm from '@/components/checkout-process/checkout/StripeCheckoutForm.vue'
import BasciPromptModal from "@/components/misc/BasicPromptModal.vue";
import { store } from "@/store";
import Spinner from "@/components/spinner/Spinner.vue";
import { useMeta } from 'vue-meta';

@Options({
  components: { CustomStoreOrderProductTile, Form, Field, ErrorMessage, CartProductTile, BasciPromptModal, StripeCheckoutForm, Spinner },
  props: {
    id: { default: "" },
  },
})
export default class CustomStoreOrderPage extends mixins(Country, FormatDate, ThousandSeparator, CalculatePrice, ScrollTop, QuantityPerItem, SetMetaData) {
  id = "";
  customerId = "";
  loading = false;
  countryCodes: any = countries;
  selectedDialCode = "";
  phoneNumber = "";
  store: StoreViewModel = {
    id: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeStatusId: StoreStatus.Approved,
    countryId: "",
    countryName: "",
    currency: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    },
    referenceNumber: ""
  };
  storeProducts: Array<StoreCustomizedProductListViewModel> = [];
  selectedCountry: CountryViewModel = {
    id: "",
    name: "",
    isDefault: true,
    code: "",
    deliveryTypes: [
      {
        id: "",
        deliveryTypeId: DeliveryType.Standard,
        deliveryTypeName: "",
        pricePerItem: 0,
        pricePerOrder: 0,
        deliveryDays: 0,
      },
    ],
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 0,
    },
    allowBankTransfers: true,
    bankTransferDetails: "",
  };
  selectedProducts: Array<string> = [];
  visibleSection = 1;
  allowedSections = [1, 2];
  sectionIds = {
    1: 'details',
    2: 'products',
    3: 'sizes',
    4: 'payment'
  }
  storeUser: StoreOrderCartViewModel = {
    id: "",
    referenceNumber: '',
    storeId: "",
    name: "",
    email: "",
    phoneNumber: "",
    items: []
  };
  storeUserClone: StoreOrderCartViewModel = {
    id: "",
    referenceNumber: '',
    storeId: "",
    name: "",
    email: "",
    phoneNumber: "",
    items: []
  };
  storeUserIsValid: boolean = false;
  firstLoad = true;
  productIdToRemove: string | null = null;
  paymentProviders: Array<PaymentProvider> = [];
  selectedPaymentType: 'card' | 'eft' | '' = '';
  notFound = false;

  get sortedDialCodes() {
    return this.countryCodes.sort((a: any, b: any) => {
      return a.dialCode - b.dialCode;
    });
  }

  get itemsInOrder() {
    if (this.storeUser) {
      let totalItems = 0;
      this.storeUser.items.forEach((item: any) => {
        item.quantities.forEach((itemQuanities: any) => {
          totalItems += itemQuanities.quantity;
        });
      });
      if (totalItems <= 9999) {
        return totalItems;
      }
      return "9999+";
    }
    return 0;
  }

  get orderItemsTotal() {
    let total = 0;
    this.storeUser.items.forEach(item => {
      total += (item.price * this.quantityPerItem(item))
    })
    return total;
  }

  async created() {
    await this.handleCustomerId("check");
    await this.loadInitialData();

    if (this.storeUserClone.phoneNumber) {
      let array = this.storeUserClone.phoneNumber.split("-");
      if (array.length >= 2) {
        this.selectedDialCode = array[0];
        array.shift();
        this.phoneNumber = array.join("-");
      }
    }
    if (this.selectedDialCode == "") {
      let selectedCountry = store.getters["location/country"];

      if (selectedCountry) {
        let matchedCountry = this.countryCodes.find((country: any) => {
          return selectedCountry.code === country.isoCode;
        });

        if (matchedCountry) {
          this.selectedDialCode = matchedCountry.dialCode;
        }
      }

      if (!this.selectedDialCode) {
        this.selectedDialCode = "+1";
      }
    }
  }

  mounted() {
    const {meta} = useMeta({})
    this.setMetaData(meta, {}, '', `Online Store Order | `)

    this.setHorizonalSroll();
  }

  updated() {
    this.setHorizonalSroll();
  }

  async handleCustomerId(action: "check" | "add" | "remove") {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    let customStores: Array<{ storeId: string; customerId: string }> = [];
    let currentStore: { storeId: string; customerId: string } | null | undefined = null;

    if (store != null && store["customStores"] != null) {
      customStores = store["customStores"];
      currentStore = customStores.find((store: { storeId: string; customerId: string }) => store.storeId == this.id);
    }

    if (action == "check") {
      if (currentStore) {
        // debugger
        this.customerId = currentStore.customerId;
      }
    }

    if (action == "add") {
      if (!customStores.length) {
        if (!store) {
          store = {};
        }

        if (!store["customStores"]) {
          store["customStores"] = [];
        }

        store["customStores"] = [{ storeId: this.id, customerId: this.customerId }];
      } else if (customStores.length && !currentStore) {
        store["customStores"] = [...customStores, { storeId: this.id, customerId: this.customerId }];
      }

      localStorage.setItem("vuex", JSON.stringify({ ...store }));
    }

    if (action == "remove") {
      if (customStores.length && currentStore) {
        const remainingStores = customStores.filter((store) => store.storeId != this.id && store.customerId != this.customerId);
        store["customStores"] = remainingStores;
        localStorage.setItem("vuex", JSON.stringify({ ...store }));
      }
    }
  } 

  setHorizonalSroll() {
    const isDesktop = window.matchMedia("(min-width: 992px)").matches;

    if(isDesktop && this.storeProducts.length > 2) {
      // horizontal scroll set up
      const container = document.getElementById("horizontal");
      // where "horizontal" is the id of the container
      container?.removeEventListener("wheel", (e) => this.horizonalSroll(e, container));
      container?.addEventListener("wheel", (e) => this.horizonalSroll(e, container));
    }
  }

  horizonalSroll(e: WheelEvent, container: HTMLElement | null) {
    if (container) {
      if (e.deltaY > 0) {
        container.scrollLeft += 100;
        e.preventDefault();
        // prevenDefault() will help avoid worrisome
        // inclusion of vertical scroll
      } else {
        container.scrollLeft -= 100;
        e.preventDefault();
      }
    }
  }

  isVisible(section: number) {
    return this.visibleSection != section;
  }

  formatEndDate(uppercase = true) {
    return this.formatDate(this.store.endDate, uppercase);
  }

  handleSectionClick(section: number) {
    if(this.allowedSections.includes(section) && this.isVisible(section)) {
      this.visibleSection = section;

      let newAllowedSections = []
      for(let i = 1; i <= section; i++) {
        newAllowedSections.push(i);
      }
      this.allowedSections = newAllowedSections;

      setTimeout(() => {
        this.scrollTop(`#${(this.sectionIds as any)[section]}`);
      }, 0);
    }
  }

  handleSelect(id: string) {
    if (!this.selectedProducts.includes(id)) {
      this.selectedProducts.push(id);
    } else {
      this.selectedProducts = this.selectedProducts.filter((item) => item !== id);
    }
  }

  validateFirstSteps() {
    (this.$refs.UserDetailBtn as any).click();
    setTimeout(() => {
      if (document.querySelectorAll(".details-container .validate-error").length < 1) {
        this.goToSizesSelection()
      }
      else {
        this.storeUserIsValid = false;
        this.scrollTop(`#details`);
      }
    }, 100);    
  }

  async goToSizesSelection() {
    await this.submitStoreUserDetails();
    if (this.storeUserIsValid && this.selectedProducts.length) {
      // submit items and open step 3
      let productsToSave: Array<string> = [];
      let productsToRemove: Array<string> = [];
      
      // if(this.storeUser.items.length) {
        this.selectedProducts.forEach(item => {
          const match = this.storeUser.items.find(storeItem => storeItem.customizedProduct.id == item);
          if(!match) {
            productsToSave.push(item);
          }
        })
      // }

      if(this.storeUser.items.length) {
        this.storeUser.items.forEach(storeItem => {
          const match = this.selectedProducts.find(item => storeItem.customizedProduct.id == item);
          if(!match) {
            productsToRemove.push(storeItem.customizedProduct.id);
          }
        })
      }

      if(productsToSave.length) {
        this.loading = true;
        await this.saveCartItem(productsToSave)
      }

      if(productsToRemove.length) {
        this.loading = true;
        await this.removeCartItem(productsToRemove)
      }

      if(productsToSave.length || productsToRemove.length) {
        await this.loadInitialData();
      }

      this.loading = false;
      this.selectedProducts = [];

      if(this.storeUser.items.length) {
        this.storeUser.items.forEach(item => {
          this.handleSelect(item.id);
        })

        if(!this.allowedSections.includes(3)) {
          this.allowedSections.push(3);
        }

        this.visibleSection = 3;
        setTimeout(() => {
          this.scrollTop(`#${(this.sectionIds as any)[this.visibleSection]}`)
        }, 0);
      }
    }
    else if (!this.storeUserIsValid) {
      this.scrollTop(`#details`);
    }
  }

  goToPayment(x:MouseEvent, checkValues = true) {
    let emptyValues = 0;
    this.selectedPaymentType = '';

    if (checkValues) {
      this.storeUser.items.forEach((item: StoreOrderCartItemViewModel) => {
        let quantities = item.quantities;
        quantities.forEach((quantity) => {
          quantity.extraCustomizations.forEach((extra) => {
            if (!extra.value) {
              emptyValues += 1;
            }
          });
        });
      });
    }

    if (emptyValues == 0) {
      this.updateStoreCart().then(() => {
        if(!this.allowedSections.includes(4)) {
          this.allowedSections.push(4);
        }
        
        this.visibleSection = 4;
        console.log((this.sectionIds as any)[this.visibleSection]);
        
        setTimeout(() => {
          this.scrollTop(`#${(this.sectionIds as any)[this.visibleSection]}`)
        }, 0);
      });
    } else {
      let modalButton = document.getElementById("showMissingModal") as any;
      if(modalButton) {
        modalButton.click();
      }
    }

    //Carts.cartsUpdateCartIdPut(this.cartId, this.cart)
  }

  handlePaymentType({target}:any) {
    this.selectedPaymentType = target.value;
  }


  async saveCartItem(productsToSave : Array<string>) {
    await Promise.all(productsToSave.map(async (item: string ) => {
      const payload: StoreOrderCartItemCreateModel  = {
        storeCustomizedProductId: item,
        totalQuantity: 1
      }

      await CartItems.cartItemsCreateStoreCartItemCartIdPost(this.customerId, payload)
      .then((res) => {
        if (res.data.succeeded) {
          return res;
        }
      })
      .catch((error) => {
        debugger
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }))
  }

  async removeCartItem(productsToRemove : Array<string>, reload = false) {
    await Promise.all(productsToRemove.map(async (item: string ) => {
      await CartItems.cartItemsDeleteStoreCartItemCartIdCartItemIdDelete(this.customerId, item)
      .then((res) => {
        if (res.data.succeeded) {
          if(this.productIdToRemove) {
            this.productIdToRemove = null;
          }

          if(reload) {
            this.loadInitialData(false);
          }

          return res;
        }
      })
      .catch((error) => {
        debugger
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }))
  }

  handleRemoveId(id: string, action = "save") {
    if (action === "save") {
      this.productIdToRemove = id;
    }

    if (action === "clear") {
      this.productIdToRemove = null;
    }
  }

  async updateStoreCart() {
    //update store ??
  }

  async checkPaymentProviders() {
    await Carts.cartsGetPaymentProvidersForStoreOrderIdGet(this.id)
    .then((res) => {
      if(res.data.succeeded) {
        this.paymentProviders = res.data.resultData as Array<PaymentProvider>       
      }
    })
    .catch((error) => {
      console.log(error)
      this.loading = false;
      let errors = error.response.data.errors;
      errors.forEach((error: any) => {
        this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
      });
    });
  }

  async submitStoreUserDetails() {
    let success = false;
    if (this.phoneNumber) {
      let numberWithCode = `${this.selectedDialCode}-${this.phoneNumber}`;
      this.storeUserClone.phoneNumber = numberWithCode;
    }

    const payload: CartsCreateStoreCartPostRequest | CartsCreateStoreCartPostRequest = {
      storeId: this.id,
      name: this.storeUserClone.name,
      email: this.storeUserClone.email,
      phoneNumber: this.storeUserClone.phoneNumber,
    };

    if(this.storeUser.id) {
      this.loading = true;
      await Carts.cartsUpdateStoreCartIdPut(this.storeUser.id, payload)
      .then((res) => {
        if (res.data.succeeded && res.data.resultData) {
          this.storeUser = res.data.resultData;
          this.storeUserClone = {...this.storeUser};
          success = true;
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    } else {
      this.loading = true;
      await Carts.cartsCreateStoreCartPost(payload)
      .then((res) => {
        if (res.data.succeeded && res.data.resultData) {
          this.storeUser = res.data.resultData;
          this.storeUserClone = {...this.storeUser};
          success = true;
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
    
    if(success) {
      this.storeUserIsValid = true
      this.customerId = this.storeUser.id;
      this.handleCustomerId("add");

      if(!this.allowedSections.includes(2)) {
        this.allowedSections.push(2);
      }
      
      this.visibleSection = 2;
      console.log((this.sectionIds as any)[this.visibleSection]);
      
      setTimeout(() => {
        this.scrollTop(`#${(this.sectionIds as any)[this.visibleSection]}`)
      }, 0);
    }
    else {
      this.storeUserIsValid = false
    }
  }

  async loadStoreProducts() {
    await StoreCustomizedProducts.storeCustomizedProductsGetActiveStoreCustomizedProductsStoreIdGet(1, 99999, this.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.storeProducts = res.data.resultData?.items as Array<StoreCustomizedProductListViewModel>;
        }
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        if (errors) {
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        }
      });
  }

  async loadStoreUser() {
    if(this.customerId) {
      await Carts.cartsGetStoreCartGet(this.customerId)
      .then((res) => {
        if (res.data.succeeded) {
          this.storeUser = res.data.resultData as StoreOrderCartViewModel;
          this.storeUserClone = {...this.storeUser};

          this.selectedProducts = this.storeUser.items.map(item => {
            return item.customizedProduct.id
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        if (errors) {
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        }
      });
    }
  }

  async getCountryInfo() {
    await Countries.countriesGet()
      .then((res) => {
        if (res.data.succeeded && res.data.resultData) {
          this.selectedCountry = res.data.resultData.find((country) => country.id === this.store.countryId) as CountryViewModel;

          if (this.selectedCountry.id != this.country().id) {
            store.dispatch("location/changeLocation", { country: this.selectedCountry });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  async loadInitialData(scroll = true) {
    this.notFound = false;
    if (this.id) {
      this.loading = true;
      await Stores.storesGetActiveStoreIdGet(this.id)
        .then(async (res) => {
          if (res.data.succeeded) {
            this.store = res.data.resultData as StoreViewModel;
            await this.loadStoreProducts();
            await this.getCountryInfo();
            this.checkPaymentProviders();

            if (this.customerId) {
              await this.loadStoreUser();
            }

            let enabledSections = [1];
            if (this.customerId) {
              enabledSections.push(2);

              if (this.storeUser.items.length) {
                enabledSections.push(3);
              }
            }
            this.allowedSections = enabledSections;
            this.visibleSection = this.allowedSections[this.allowedSections.length -1];

            if(scroll && !(this.firstLoad && this.visibleSection == 1)) {
              setTimeout(() => {
                this.scrollTop(`#${(this.sectionIds as any)[this.visibleSection]}`);
              }, 0);
            }

            this.firstLoad = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          let errors = error.response.data.errors;
          if (errors) {
            errors.forEach((error: any) => {
              if(error.friendlyMessage !== 'Store not found') {
                this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
              } else {
                this.notFound = true;
              }
            });
          }
        });
    }
  }
}
