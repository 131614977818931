
import { StoreStatus, StoreViewModel } from "@/api-client";
import { FormatDate } from "@/mixins/utilities";
import { mixins, Options, Vue } from "vue-class-component";
import BasciPromptModal from '@/components/misc/BasicPromptModal.vue'

@Options({
  components: { BasciPromptModal },
  props: {
    store: {default: null},
  },
  emits: ['edit', 'delete']
})

export default class StoreListTile extends mixins(FormatDate) {
  store: StoreViewModel = {
    id: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeStatusId: StoreStatus.Editing,
    countryId: "",
    countryName: "",
    currency: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    },
    referenceNumber: ""
  };

  // get isOpen() {
  //   if(this.store.id && this.store.endDate) {
  //     const today = new Date().getTime();
  //     const closeDate = new Date(this.store.endDate).getTime();
  //     return today > closeDate ? false : true;
  //   }
  //   return false;
  // }
}
