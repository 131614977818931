import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-12 image-container d-flex relative" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "d-flex justify-content-center w-100 h-100 position-absolute img-wrap" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplideSlide = _resolveComponent("SplideSlide")!
  const _component_Splide = _resolveComponent("Splide")!

  return (_openBlock(), _createBlock(_component_Splide, {
    class: "carousel-splide",
    options: _ctx.spliderConfig
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageArray, (image) => {
        return (_openBlock(), _createBlock(_component_SplideSlide, {
          key: image.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", {
                class: _normalizeClass(["d-flex flex-column w-100 h-100", image.boxPositionClass]),
                style: {"z-index":"1"}
              }, [
                (_ctx.checkContent(image))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["image-text-container p-4 h-auto w-auto", `text-${image.boxTextAlignmentClass}`]),
                      style: _normalizeStyle(image.boxBackgrounColour && `background-color:${image.boxBackgrounColour}`)
                    }, [
                      (image.boxTitle)
                        ? (_openBlock(), _createElementBlock("h2", {
                            key: 0,
                            class: _normalizeClass(["home-page-heading", {'m-0': !image.boxDescription && !image.buttonText}]),
                            style: _normalizeStyle(image.boxTextColour ? `color:#${image.boxTextColour}` : `color:#00233B`),
                            innerHTML: _ctx.formattedMarkdown(image.boxTitle)
                          }, null, 14, _hoisted_2))
                        : _createCommentVNode("", true),
                      (image.boxDescription)
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 1,
                            class: _normalizeClass(["home-page-body", {'m-0': !image.buttonText}]),
                            style: _normalizeStyle(image.boxTextColour ? `color:#${image.boxTextColour}` : `color:#00233B`),
                            innerHTML: _ctx.formattedMarkdown(image.boxDescription)
                          }, null, 14, _hoisted_3))
                        : _createCommentVNode("", true),
                      (image.buttonText)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 2,
                            href: image.buttonLink
                          }, [
                            _createElementVNode("button", {
                              class: _normalizeClass(["btn btn-primary", {'mt-3' : image.boxDescription || image.boxTitle}])
                            }, _toDisplayString(image.buttonText), 3)
                          ], 8, _hoisted_4))
                        : _createCommentVNode("", true)
                    ], 6))
                  : _createCommentVNode("", true)
              ], 2),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: `${_ctx.uploadPath}/${image.backgroundImageUrl}`,
                  class: "img-fluid w-100",
                  alt: "Banner image"
                }, null, 8, _hoisted_6)
              ])
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["options"]))
}