
import { CustomizedProductAdjustmentTypeViewModel } from "@/api-client";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    extras: { default: null },
    row: { default: null },
    adjustmentTypes: { default: null },
  },
  //emits: ["results"],
})
export default class NamesAndNumbers extends Vue {
  groupedExtras: Array<any> = [];
  extras: any = [];
  laoding = false;
  adjustmentTypes: Array<CustomizedProductAdjustmentTypeViewModel> = []

  created() {
    if (this.extras.length) {
      this.groupedExtras = this.groupBy(this.extras, "row");
    }
  }

  //Source: https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
  // `data` is an array of objects, `key` is the key (or property accessor) to group by
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
  groupBy(data: any, key: any) {
    return data.reduce(function (storage: any, item: any) {
      // get the first instance of the key by which we're grouping
      let group = item[key];

      // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
      storage[group] = storage[group] || [];

      // add this item to its group within `storage`
      storage[group].push(item);

      // return the updated storage to the reduce function, which will then loop through the next
      return storage;
    }, []); // [] is the initial value of the storage
  }

  pasteToRow(extra:any, group:any, index:any) {
    let content = ''
    let extraId = extra.extraId
    let row = extra.row
    window.navigator.clipboard.readText()
      .then((cliptext) => {
        if (typeof content === 'string') {
          content = cliptext
          const contentArray = content.split('\n')
          let length = 0

          this.groupedExtras.forEach((group:any, index:number) => {
            group.forEach((item:any) => {
              if((item.row >= row) && (item.extraId == extraId)) {
                if(contentArray[length]) {
                  item.value = contentArray[length]
                  length += 1;
                }
              }
            })
          })
        } else {
          this.$notify({ type: "error", text: 'Only text can be pasted as a name / number value.', ignoreDuplicates: true, duration: -1 });
        }
      })
      .catch((error) => {
        console.log(error)
      }); 
  }

  limitLength(extra: any) {
    if (extra.customizationTextMaximumLength) {
      if (extra.value.length > extra.customizationTextMaximumLength) {
        extra.value = extra.value.substring(0, extra.customizationTextMaximumLength);
      }
    }
  }

  isFit(id:string) {
    let isFit = false
    if(this.adjustmentTypes) {
      this.adjustmentTypes.forEach(adjustmentType => {
        adjustmentType.adjustments.forEach(adjustment => {
          if (adjustment.adjustmentId === id) {
            if (adjustmentType.name.toLowerCase() == 'fit') {
              isFit = true;
            }
          }
        })
      });
    }
    return isFit
  }

  beforeUnmount() {
    //this.$emit("results", this.groupedExtras);
    this.groupedExtras = [];
    //console.log("unmount names");
  }
}
