
import { mixins, Options, Vue } from "vue-class-component";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import FeaturedProducts from "@/components/featured/FeaturedProducts.vue";
import GenericBanner from "@/components/banners/GenericBanner.vue";
import InfoPageBlock from "@/components/general-information/InfoPageBlock.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";

@Options({
  components: {
    Breadcrumbs, StatementBanner, FeaturedProducts, InfoPageBlock, GenericBanner, HeadingBanner
  }
})
export default class CanYouMakeThisPage extends mixins(SetMetaData) {
  blockContent = [
    {
      bgImage: '/img/can-you-make/oscar_mayer.jpg',
      label: 'Samples of bespoke tracksuits, jackets, and shirts made for Oscar Mayer.',
      bgColor: '',
      textColour: '',
      heading: '',
      text: '',
      number: '',
      order: 1
    },
    {
      bgImage: '',
      bgColor: '#FF3D5C',
      textColour: '#F2ECEA',
      heading: 'OSCAR MAYER',
      text: `<p>Oscar Mayer asked if we could make a custom tracksuit featuring their logo, brand colors, and unique pattern. We made them these.</p>`,
      number: '',
      order: 2
    },
    {
      bgImage: '',
      bgColor: '#00233B',
      textColour: '#F2ECEA',
      heading: 'BGC PARTNERS',
      text: `<p class="mb-3">BGC Partners wanted traders jackets for each team to wear for their annual Charity Day. We made them these (and 15 other designs!)</p><p><strong>Have a bespoke style or design in mind? <br class="d-none d-md-inline" /><a href='/contact-us?from=/can-you-make-me-this'>Contact our design experts here.</a></strong></p>`,
      number: '',
      order: 3
    },
    {
      bgImage: '/img/can-you-make/bgc_partners.jpg',
      label: 'Example of custom jackets created for BGC Partners.',
      bgColor: '',
      textColour: '',
      heading: '',
      text: '',
      number: '',
      order: 4
    },
    {
      bgImage: '/img/can-you-make/bacardi.jpg',
      label: 'Hawaiian shirts created for Bacardi, fit to order.',
      bgColor: '',
      textColour: '',
      heading: '',
      text: '',
      number: '',
      order: 5
    },
    {
      bgImage: '',
      bgColor: '#F2ECEA',
      textColour: '#00233B',
      heading: 'BACARDI',
      text: `<p>Bacardi wanted Hawaiian shirts in a bespoke pattern for a global bartenders event. We created and delivered these custom shirts to competition locations worldwide.</p>`,
      number: '',
      order: 6
    }
  ]

  created() {
    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', 'Special Requests | ')
  }
}
