
import { Options, mixins } from "vue-class-component";
import { UploadPath } from "@/mixins/utilities";
import globalAxios from "axios";
import { fabric } from "fabric";
import { ProductBuilderFontViewModel } from "@/api-client";
import { store } from "@/store";
import { Vue3Lottie } from "vue3-lottie";
// import loaderJson2 from "@/assets/loader/loader2.json";
import loaderJson3 from "@/assets/loader/loader2-new-colours.json";

interface DataReturn {
  (file: string | ArrayBuffer | null): void;
}

@Options({
  props: {
    fonts: {
      type: Array,
      default: [],
    },
    selectedColour: {
      type: Object,
      default: null,
    },
    sectionHeight: { default: 0 },
  },
  emits: ["close", "patternUpdated"],
  components: { Vue3Lottie },
})
export default class PatternDesigner extends mixins(UploadPath) {
  loader = loaderJson3;
  preview = false;
  fonts: Array<ProductBuilderFontViewModel> = [];

  patternSaving = false;
  pos: any = {};
  color = "#005E7A";
  canvas: any;
  previewCanvas: any;
  isDrawingMode = false;
  repeaterHref: any = "";

  containerWidth = 680;
  designerWidth = 680;
  designerHeight = 800;
  percentage = 0.7;
  sectionHeight = 680;
  brushThickness = 20;
  repeatCount = 1;

  ignoreDataUpdate = false;
  ignoreRenderAll = false;

  selectionType = "";
  hasSelection = false;
  selectedFont = "";

  hideColour = ["select", "image"];
  // fontOpacity = 0;

  selectedTool = {
    id: "fill",
    name: "Background",
    iconClass: "fa fa-fill fa-lg",
    iconUnicode: "\uf575",
  };
  // showToolDropdown = false;
  // showShapesDropdown = false;
  textToAdd = "";

  selectToolObject = {
    id: "select",
    name: "Select / Edit",
    iconClass: "fa fa-up-down-left-right fa-lg",
    iconUnicode: "\uf047",
  };

  toolTypes = [
    { ...this.selectToolObject },
    {
      id: "paint",
      name: "Paint Tool",
      iconClass: "fa fa-paintbrush fa-lg",
      iconUnicode: "\uf1fc",
    },
    {
      id: "fill",
      name: "Background",
      iconClass: "fa fa-fill fa-lg",
      iconUnicode: "\uf575",
    },
    {
      id: "shapes",
      name: "Add Shapes",
      iconClass: "fa fa-shapes fa-lg",
      iconUnicode: "\uf61f",
    },
    {
      id: "text",
      name: "Add Text",
      iconClass: "fa fa-font fa-lg",
      iconUnicode: "\uf031",
    },
    {
      id: "image",
      name: "Add Images",
      iconClass: "fa fa-image fa-lg",
      iconUnicode: "\uf03e",
    },
  ];

  previousTool = {
    id: "paint",
    name: "Paint Tool",
    iconClass: "fa fa-paintbrush fa-lg",
    iconUnicode: "\uf1fc",
  };

  selectedShape = "";

  shapes = [
    {
      id: "square",
      name: "Square",
      iconClass: "fa fa-square-full fa-lg",
      iconUnicode: "\uf45c",
    },
    {
      id: "circle",
      name: "Circle",
      iconClass: "fa fa-circle fa-lg",
      iconUnicode: "\uf111",
    },
    {
      id: "star",
      name: "Star",
      iconClass: "fa fa-star fa-lg",
      iconUnicode: "\uf005",
    },
    {
      id: "triangle",
      name: "Triangle",
      iconClass: "fa fa-star fa-lg",
      iconUnicode: "\uf005",
    },
    {
      id: "cross",
      name: "Cross",
      iconClass: "fa fa-star fa-lg",
      iconUnicode: "\uf005",
    },
    {
      id: "arrow",
      name: "Arrow",
      iconClass: "fa fa-star fa-lg",
      iconUnicode: "\uf005",
    },
    {
      id: "heart",
      name: "Heart",
      iconClass: "fa fa-star fa-lg",
      iconUnicode: "\uf005",
    },
  ];

  drawingModeOptions: any = { color: this.color, lineWidth: 30 };

  selectedColour: any = null;

  get maxContainerHeight() {
    let height = window.innerHeight;
    let width = window.innerWidth;

    if (width < height) {
      return `${this.sectionHeight - 190}px`;
    }
    return "auto";
  }

  created() {
    window.addEventListener("resize", this.calculateContainerWidth);
  }

  destroyed() {
    window.removeEventListener("resize", this.calculateContainerWidth);
  }

  //save canvas to json / svg   https://groups.google.com/g/fabricjs/c/rL0SpBVzMNg
  //svg from url  http://www.independent-software.com/loading-an-svg-image-with-fabric-js.html
  // set BG   https://stackoverflow.com/questions/44010057/add-background-image-with-fabric-js
  // svg from url   https://stackoverflow.com/questions/15303989/loading-and-displaying-svg

  b64toBlob(b64Data: any, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  deselectAll() {
    this.canvas.discardActiveObject().renderAll();
  }

  mounted() {
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = "blue";
    fabric.Object.prototype.cornerStyle = "circle";

    this.canvas = new fabric.Canvas("canvas");

    this.canvas.backgroundColor = "#FFFFFF";

    let self = this;
    if (this.selectedColour.patternImageUrl) {
      this.canvas.setBackgroundColor(
        { source: `${this.uploadPath}/${this.selectedColour.patternImageUrl}` },
        function () {
          self.canvas.renderAll();
        },
        {
          width: this.designerWidth,
          height: this.designerHeight,
          originX: "left",
          originY: "top",
        }
      );

      let hasId = Object.prototype.hasOwnProperty.call(this.selectedColour, "id");
      if (!hasId || (hasId && !this.selectedColour.id)) {
        this.repeatCount = 1;
      }
    } else {
      this.canvas.backgroundColor = this.selectedColour.hexValue;
    }

    this.canvas.isDrawingMode = false;
    this.canvas.centeredRotation = true;

    this.canvas.on("after:render", (e: any) => {
      this.updateDataUri();
    });

    this.canvas.on("selection:created", (e: any) => {
      this.itemSelected(e);
    });

    this.canvas.on("selection:updated", (e: any) => {
      this.itemSelected(e);
    });

    this.canvas.on("selection:cleared", (e: any) => {
      this.itemDeselected(e);
    });

    this.updateDataUri();
    this.renderAll();

    this.calculateContainerWidth();
    this.freeDraw(false);

    this.selectedFont = this.fonts[0].name;
  }

  clearBg() {
    this.canvas.setBackgroundColor("#FFFFFF", this.canvas.renderAll.bind(this.canvas));
  }

  itemSelected(item: any) {
    let selectedItem = this.canvas.getActiveObject();
    this.selectionType = selectedItem.type;
    this.hasSelection = true;
    if (this.selectedTool.id !== "select") {
      this.previousTool = { ...this.selectedTool };
    }
    this.selectedTool = { ...this.selectToolObject };
    //this.fontOpacity = 0;

    if (this.selectionType === "textbox") {
      this.selectedFont = selectedItem.fontFamily;
    }
  }

  itemDeselected(item: any) {
    if (this.previousTool.id) {
      if (this.previousTool.id !== "image") {
        this.selectedTool = { ...this.previousTool };
      } else {
        this.selectedTool = { ...this.toolTypes[5] };
      }
      this.selectTool();
    }
    this.hasSelection = false;
    this.selectionType = "";
  }

  handleFontChange() {
    this.canvas.getActiveObject().fontFamily = this.selectedFont;
    this.renderAll();
  }

  handleRepeatCount(type: "plus" | "minus") {
    if (type == "plus" && this.repeatCount < 10) {
      this.repeatCount += 1;
    } else if (type == "minus" && this.repeatCount > 1) {
      this.repeatCount -= 1;
    }

    this.updateDataUri();
  }

  selectTool() {
    this.canvas.discardActiveObject().renderAll();

    if (this.selectedTool.id == "select") {
      this.previousTool = { ...this.toolTypes[0] };
      this.freeDraw(false);
    }

    if (this.selectedTool.id == "paint") {
      //this.previousTool = { ...this.toolTypes[1] };
      this.freeDraw(true);
    }

    if (this.selectedTool.id == "fill") {
      this.freeDraw(false);
    }

    if (this.selectedTool.id == "shapes") {
      this.freeDraw(false);
    }

    if (this.selectedTool.id == "image") {
      this.freeDraw(false);
    }

    this.selectionType = "";
  }

  selectShape() {
    if (this.selectedShape === "square") {
      this.addSquare();
    }

    if (this.selectedShape === "circle") {
      this.addCircle();
    }

    if (this.selectedShape === "star") {
      this.addStar();
    }

    if (this.selectedShape === "triangle") {
      this.addTriangle();
    }

    if (this.selectedShape === "cross") {
      this.addCross();
    }

    if (this.selectedShape === "arrow") {
      this.addArrow();
    }

    if (this.selectedShape === "heart") {
      this.addHeart();
    }

    this.selectedShape = "";
  }

  calculateContainerWidth() {
    let refs = this.$refs as any;
    if (refs.container) {
      this.containerWidth = refs.container.clientWidth - 40;
      this.percentage = this.containerWidth / this.designerWidth;
    }
  }

  // debounce the call frequency on this method
  renderAll() {
    if (this.ignoreRenderAll) {
      return;
    }
    this.ignoreRenderAll = true;
    setTimeout(() => {
      this.ignoreRenderAll = false;
    }, 10);
    this.canvas.renderAll();
  }

  updateDataUri() {
    if (!this.ignoreDataUpdate) {
      this.ignoreDataUpdate = true;
      let multiplier = 1; // TODO @Mon this is the image quality when its converted
      var data = this.canvas.toDataURL({ multiplier: multiplier, format: "png" });
      this.repeaterHref = data;
      this.$nextTick(() => {
        this.$emit("patternUpdated", { data, repeatCount: this.repeatCount });
        this.ignoreDataUpdate = false;
      });
    }
  }

  save(id: string) {
    let mySVG = document.querySelector(`#${id}`); // Inline SVG element
    let highResLoader = new Image(); // Not shown on page
    let highResImgUrl: string | null = null;
    let self = this;

    highResLoader.onload = function () {
      const highResDetails = {
        dpi: 300,
        width: 680,
        height: 800,
      };
      const highResScaleFactor = highResDetails.dpi / 96;

      self.patternSaving = true;
      let highResImage = new Image();
      let highResCan = document.createElement("canvas"); // Not shown on page
      let highResCtx = highResCan.getContext("2d");

      highResLoader.width = highResCan.width = highResImage!.width = highResDetails.width * highResScaleFactor;
      highResLoader.height = highResCan.height = highResImage!.height = highResDetails.height * highResScaleFactor;

      setTimeout(async () => {
        // highResCtx!.scale(highResScaleFactor, highResScaleFactor); // this seemed to mess it up
        highResCtx!.drawImage(highResLoader, 0, 0, highResLoader.width, highResLoader.height);

        highResImage!.src = highResCan.toDataURL();

        // strip the data headers
        var highResImgdata = highResImage!.src.replace("data:image/png;base64,", "");
        // then convert the base 64 to binary
        let contentType = "image/png";
        const highResBlob = self.b64toBlob(highResImgdata, contentType);

        let highResData = new FormData();
        highResData.append("file", highResBlob, "highResCustomPattern.png");

        await self.uploadImage(highResData).then((res) => {
          highResImgUrl = res;

          let lowResLoader = new Image(); // Not shown on page

          lowResLoader.onload = function () {
            self.patternSaving = true;
            let lowResImage = new Image();
            let lowResCan = document.createElement("canvas"); // Not shown on page
            let lowResCtx = lowResCan.getContext("2d");

            lowResLoader.width = lowResCan.width = lowResImage!.width = mySVG!.clientWidth;
            lowResLoader.height = lowResCan.height = lowResImage!.height = mySVG!.clientHeight;

            setTimeout(() => {
              lowResCtx!.drawImage(lowResLoader, 0, 0, lowResLoader.width, lowResLoader.height);

              lowResImage!.src = lowResCan.toDataURL();

              // strip the data headers
              var imgdata = lowResImage!.src.replace("data:image/png;base64,", "");
              // then convert the base 64 to binary
              let contentType = "image/png";
              const lowResBlob = self.b64toBlob(imgdata, contentType);

              let lowResData = new FormData();
              lowResData.append("file", lowResBlob, "customPattern.png");

              let saveAndSetPath = async () => {
                await self.uploadImage(lowResData).then((res) => {
                  self.patternSaving = false;
                  self.$emit("patternUpdated", { patternImageUrl: res, highQualityPatternImageUrl: highResImgUrl || null });
                  store.dispatch("user/addCustomPattern", { patternImageUrl: res, highQualityPatternImageUrl: highResImgUrl || null });
                  self.$emit("close");
                });
              };
              saveAndSetPath();
            }, 0);
          };

          var svgAsXML = new XMLSerializer().serializeToString(mySVG!);
          lowResLoader.src = "data:image/svg+xml," + encodeURIComponent(svgAsXML);
        });
      }, 0);
    };
    var svgAsXML = new XMLSerializer().serializeToString(mySVG!);
    highResLoader.src = "data:image/svg+xml," + encodeURIComponent(svgAsXML);
  }

  async uploadImage(formData: any) {
    const url = `${this.uploadPath}`;
    return globalAxios
      .post(url, formData)
      .then((x: any) => x.data)
      .then((img: any) => img.relativePath);
  }

  selectAddedObject() {
    let objectsLength = this.canvas.getObjects().length;
    let newItem = this.canvas.item(objectsLength - 1);
    this.freeDraw(false);
    this.canvas.setActiveObject(newItem);
  }

  addText() {
    if (this.textToAdd) {
      let text = new fabric.Textbox(this.textToAdd, {
        left: 50,
        top: 50,
        //width: 150,
        fontSize: 40,
        fill: this.color,
        fontFamily: this.selectedFont,
      });
      this.canvas.add(text);
      this.textToAdd = "";
      this.selectAddedObject();
    }
  }

  addCircle() {
    let circle = new fabric.Circle({
      left: 250,
      top: 100,
      radius: 50,
      fill: this.color,
    });
    this.canvas.add(circle);
    this.selectAddedObject();
  }

  addSquare() {
    this.canvas.add(
      new fabric.Rect({
        left: 100,
        top: 100,
        width: 100,
        height: 100,
        fill: this.color,
      })
    );
    this.selectAddedObject();
  }

  addStar() {
    this.canvas.add(
      new fabric.Path("M19.28,94.85,20.53,91q5.12-15.83,10.25-31.65a.67.67,0,0,0-.28-.89Q15.43,47.47.38,36.5A1.34,1.34,0,0,1,0,36.1H37.59a.79.79,0,0,0,.91-.66Q44.13,18,49.79.5c0-.14.1-.27.18-.5.1.27.18.45.24.64q5.64,17.45,11.28,34.89a.67.67,0,0,0,.77.58H100l0,.09L98.86,37q-14.7,10.69-29.4,21.39a.65.65,0,0,0-.29.88q5.7,17.57,11.37,35.12c0,.15.09.31.18.58l-.61-.44Q65.29,83.78,50.48,73a.72.72,0,0,0-1,0Q34.69,83.77,19.88,94.54l-.52.36Z", {
        left: 100,
        top: 100,
        width: 100,
        height: 95,
        fill: this.color,
      })
    );
    this.selectAddedObject();
  }

  addTriangle() {
    this.canvas.add(
      new fabric.Triangle({
        left: 100,
        top: 100,
        width: 100,
        height: 85,
        fill: this.color,
      })
    );
    this.selectAddedObject();
  }

  addCross() {
    this.canvas.add(
      new fabric.Polygon(
        [
          { x: 100, y: 28.82 },
          { x: 71.18, y: 28.82 },
          { x: 71.18, y: 0 },
          { x: 28.82, y: 0 },
          { x: 28.82, y: 28.82 },
          { x: 0, y: 28.82 },
          { x: 0, y: 71.18 },
          { x: 28.82, y: 71.18 },
          { x: 28.82, y: 100 },
          { x: 71.18, y: 100 },
          { x: 71.18, y: 71.18 },
          { x: 100, y: 71.18 },
          { x: 100, y: 28.82 },
        ],
        {
          left: 100,
          top: 100,
          angle: 0,
          fill: this.color,
        }
      )
    );
    this.selectAddedObject();
  }

  addArrow() {
    this.canvas.add(
      new fabric.Polygon(
        [
          { x: 76.72, y: 17.5 },
          { x: 53.43, y: 0 },
          { x: 53.43, y: 15.64 },
          { x: 0, y: 15.64 },
          { x: 0, y: 54.35 },
          { x: 53.43, y: 54.35 },
          { x: 53.43, y: 70 },
          { x: 76.72, y: 52.5 },
          { x: 100, y: 35 },
          { x: 76.72, y: 17.5 },
        ],
        {
          left: 100,
          top: 100,
          angle: 0,
          fill: this.color,
        }
      )
    );
    this.selectAddedObject();
  }

  addHeart() {
    this.canvas.add(
      new fabric.Path("M0,31.55v-5c.24,0,.15-.22.16-.34A29.31,29.31,0,0,1,34.64.5a28.82,28.82,0,0,1,15,7.72l.27.25a.15.15,0,0,0,.23,0l.19-.19A29.26,29.26,0,0,1,76.06.5a28.73,28.73,0,0,1,15.21,8,28.83,28.83,0,0,1,8.59,18.07,28.25,28.25,0,0,1-.13,6.69,29.32,29.32,0,0,1-3.17,9.85,31,31,0,0,1-5.6,7.4Q70.68,70.71,50.42,91c-.42.42-.42.42-.84,0L17.13,58.54c-2.94-2.94-5.89-5.87-8.81-8.83A28.48,28.48,0,0,1,1.85,39.53,29.9,29.9,0,0,1,.2,32.68a.94.94,0,0,0-.07-.3c-.06-.08-.12,0-.13.07v-.36c0,.1.12.09.13,0S.19,31.69,0,31.55Z", {
        left: 100,
        top: 100,
        width: 100,
        height: 95,
        fill: this.color,
      })
    );
    this.selectAddedObject();
  }

  toDataUrl(url: string, callback: DataReturn) {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
      let reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  addImage(event: any) {
    var canvas = this.canvas;
    var reader = new FileReader();
    let self = this;

    let select = function (): void {
      self.selectAddedObject();
    };

    reader.onload = function (e) {
      var type = (e.target!.result! as string).split(";")[0];
      if (type == "data:image/png" || type == "data:image/jpeg") {
        var imgObj = new Image();
        imgObj.src = e.target!.result as string;
        imgObj.onload = function () {
          var image = new fabric.Image(imgObj);
          image.set({
            angle: 0,
            opacity: 1,
          });
          var imgWidth = image.width ? image.width : 0;
          var imgHeight = image.height ? image.height : 0;
          if (imgWidth > canvas.width) {
            image.scale((canvas.width / imgWidth) * 0.98);
          } else if (imgHeight > canvas.height) {
            image.scale((canvas.height / imgHeight) * 0.98);
          }
          canvas.centerObject(image);
          canvas.add(image);
          select();
          canvas.renderAll();
        };
      } else if (type == "data:image/svg+xml") {
        var url = URL.createObjectURL(event.target!.files[0]);
        fabric.loadSVGFromURL(url, function (objects, options) {
          var svg = fabric.util.groupSVGElements(objects, options);

          // objects.forEach(function(svg) {
          //   svg.set({
          //       top: 90,
          //       left: 90,
          //       originX: 'center',
          //       originY: 'center'
          //   });
          //svg.scaleToWidth(50);
          // svg.scaleToHeight(50);
          // canvas.add(svg).renderAll();
          // });

          var svgWidth = svg.width ? svg.width : 0;
          var svgHeight = svg.height ? svg.height : 0;
          if (svgWidth > canvas.width) {
            svg.scale((canvas.width / svgWidth) * 0.98);
          } else if (svgHeight > canvas.height) {
            svg.scale((canvas.height / svgHeight) * 0.98);
          }

          canvas.add(svg);
          select();
          canvas.renderAll();
        });
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  setDrawLineColor(event: any) {
    this.color = event.target.value;
    this.drawingModeOptions.color = event.target.value;
    this.canvas.freeDrawingBrush.color = this.drawingModeOptions.color;

    if (this.canvas.isDrawingMode) {
      this.color = event.target.value;
    } else {
      if (this.canvas.getActiveObject()) {
        if (this.canvas.getActiveObject().type == "path") {
          // this.canvas.getActiveObject().set({
          //   stroke: this.color,
          // });
          this.canvas.getActiveObject().set({
            fill: this.color,
          });
        } else if (this.canvas.getActiveObject().type == "textbox") {
          this.canvas.getActiveObject().set({
            fill: this.color,
          });
        } else if (this.canvas.getActiveObject().type == "circle") {
          this.canvas.getActiveObject().set({
            fill: this.color,
          });
        } else if (this.canvas.getActiveObject().type == "rect") {
          this.canvas.getActiveObject().set({
            fill: this.color,
          });
        } else if (this.canvas.getActiveObject().type == "triangle") {
          this.canvas.getActiveObject().set({
            fill: this.color,
          });
        }
        if (this.canvas.getActiveObject().type == "polygon") {
          this.canvas.getActiveObject().set({
            fill: this.color,
          });
        }
        // this.canvas.getActiveObject().set({
        //   stroke: this.color,
        //   fill: this.color,
        // });
      } else if (this.selectedTool.id === "fill") {
        this.canvas.backgroundColor = this.color;
      }
    }

    this.renderAll();
  }

  setDrawLine(event: any) {
    // var brush = this.canvas.freeDrawingBrush;
    this.drawingModeOptions.lineWidth = parseInt(event.target.value, 10) || 1;
    // brush.color = this.drawingModeOptions.color;
    // if (brush.getPatternSrc) {
    //   brush.source = brush.getPatternSrc.call(brush);
    // }
    this.canvas.freeDrawingBrush.width = this.drawingModeOptions.lineWidth;
  }

  setDrawProps() {
    var brush = this.canvas.freeDrawingBrush;
    brush.color = this.drawingModeOptions.color;
    // if (brush.getPatternSrc) {
    //   brush.source = brush.getPatternSrc.call(brush);
    // }
    brush.width = this.drawingModeOptions.lineWidth || 1;
  }

  freeDraw(value: boolean) {
    this.canvas.isDrawingMode = this.isDrawingMode = value;
    this.setDrawProps();
  }

  deleteObject() {
    var activeObjects = this.canvas.getActiveObjects();
    this.canvas.discardActiveObject();
    this.canvas.remove(...activeObjects);
    // var target = transform.target;
    // var canvas = target.canvas;
    // canvas.remove(target);
    // this.canvas.requestRenderAll();
  }

  cloneObject() {
    //let group = this.canvas.getActiveObject();
    let activeObjects = this.canvas.getActiveObjects();
    let self = this;
    this.deselectAll();
    // this.canvas.discardActiveObject();
    // this.canvas.renderAll();
    let clonedObjects = [] as Array<any>;

    // if (activeObjects.length > 1) {
    //   let groupTop = group.top;
    //   let groupLeft = group.left;

    //   activeObjects.forEach(function (object: any) {
    //     object.clone(function (clone: any) {
    //       let left = object.left;
    //       let top = object.top;

    //       self.canvas.add(clone.set({
    //           left: groupLeft + left,
    //           top: groupTop + top,
    //         }));
    //     });
    //   });
    // } else {

    // clone object/s
    activeObjects.forEach(function (object: any) {
      object.clone(function (clone: any) {
        let left = object.left - 20;
        let top = object.top - 20;

        self.canvas.add(
          clone.set({
            left: left,
            top: top,
          })
        );

        clonedObjects.push(
          clone.set({
            left: left,
            top: top,
          })
        );
      });
    });

    // Select cloned objects
    // TODO: bug when cloning single image - fix?
    var sel = new fabric.ActiveSelection(clonedObjects, {
      canvas: this.canvas,
    });
    this.canvas.setActiveObject(sel);
    this.canvas.requestRenderAll();
    // }
  }

  changeColor(event: any) {
    this.color = event.target.value;
  }

  // setPosition(e: any) {
  //   this.pos.x = e.layerX;
  //   this.pos.y = e.layerY;
  // }
  // resize() {
  //   ctx!.canvas.width = window.innerWidth;
  //   ctx!.canvas.height = window.innerHeight;
  // }
  // draw(e: any) {
  //   if (e.buttons !== 1) {
  //     return;
  //   }
  //   var canvas: HTMLCanvasElement = this.$refs.canvas as HTMLCanvasElement;
  //   const ctx = canvas.getContext("2d");
  //   const { pos } = this;
  //   ctx!.beginPath();
  //   ctx!.lineWidth = 5;
  //   ctx!.lineCap = "round";
  //   ctx!.strokeStyle = this.color;
  //   ctx!.moveTo(pos.x, pos.y);
  //   this.setPosition(e);
  //   ctx!.lineTo(pos.x, pos.y);
  //   ctx!.stroke();
  // }
}
