
import { FormatDate, ScrollTop } from "@/mixins/utilities";
import Spinner from "@/components/spinner/Spinner.vue";
import { mixins, Options } from "vue-class-component";
import { StoreListViewModel, StoreListViewModelPaginatedListApplicationResultResultData, StoreStatus, StoreViewModel } from "@/api-client";
import StoreListTile from "@/components/profile/custom-stores/StoreListTile.vue";
import CreateEditCustomStore from "@/components/profile/custom-stores/CreateEditCustomStore.vue";
import { Stores } from "@/network/api";

@Options({
  components: { Spinner, StoreListTile, CreateEditCustomStore },
})
export default class CustomStores extends mixins(ScrollTop, FormatDate) {
  loading = false;
  storesPages: StoreListViewModelPaginatedListApplicationResultResultData = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 0,
    totalPages: 0,
    pageSize: 0,
  };
  pageIndex = 1;
  stores: Array<StoreListViewModel> = [];
  showCustomStore = false;
  selectedStore: StoreViewModel = {
    id: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeStatusId: StoreStatus.Editing,
    countryId: "",
    countryName: "",
    currency: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    },
    referenceNumber: ""
  };

  created() {
    this.stores = [];
    this.pageIndex = 1;
    this.loadInitialData().then(async () => {
      if (this.$route.query.store && this.$route.query.store == 'new') {
        this.showCustomStore = true;
      } else if(this.$route.query.store && this.stores.length) {
        await this.loadStore(this.$route.query.store as string)
        .then(() => {
          if(this.selectedStore.id) {
            this.showCustomStore = true;
          }
        })
      }
    })
  }

  mounted() {
    this.scroll();
  }

  topAnchor() {
    this.scrollTop(`.profile-content-container`, false);
  }

  createStore() {
    this.showCustomStore = true;
    this.$router.replace({ name: "Profile", query: { section: "online-stores" , store: 'new' } });
    this.topAnchor();
  }

  updateStore(store:StoreListViewModel) {
    const updatedIndex = this.stores.findIndex((item:StoreListViewModel) => item.id === store.id);
    this.stores[updatedIndex] = {...store}
  }

  edit(store: StoreViewModel, showFields = true) {
    if(store) {
      this.selectedStore = store;
    } else {
      this.selectedStore = {
        id: "",
        startDate: "",
        endDate: "",
        title: "",
        deliveryFeePerCustomer: 0,
        storeStatusId: StoreStatus.Editing,
        countryId: "",
        countryName: "",
        currency: {
          id: "",
          name: "",
          code: "USD",
          symbol: "",
          pricingMultiplier: 0,
          decimalPlaces: 2,
        },
        referenceNumber: ""
      }
    }

    if(showFields) {
      this.showCustomStore = true;
      this.$router.replace({ name: "Profile", query: { section: "online-stores" , store: this.selectedStore.id } });
    }

    this.topAnchor();
  }
  
  close() {
    this.showCustomStore = false;
    this.selectedStore = {
      id: "",
      startDate: "",
      endDate: "",
      title: "",
      deliveryFeePerCustomer: 0,
      storeStatusId: StoreStatus.Editing,
      countryId: "",
      countryName: "",
      currency: {
        id: "",
        name: "",
        code: "USD",
        symbol: "",
        pricingMultiplier: 0,
        decimalPlaces: 2,
      },
      referenceNumber: ""
    }
    this.topAnchor();
    this.$router.replace({ name: "Profile", query: { section: "online-stores" } });
    this.reload();
  }

  isScrolledIntoView(el: any) {
    let rect = el.getBoundingClientRect() as DOMRect;
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  scroll() {
    window.onscroll = () => {
      let allRefs = this.$refs as any;
      let scrolledTo = allRefs.bottom;

      if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
        this.loadMoreStores();
      }
    };
  }

  loadMoreStores() {
    if (this.loading === false && this.storesPages.hasNextPage === true) {
      this.loading = true;
      this.pageIndex += 1;

      this.loadInitialData(this.pageIndex);
    }
  }

  deleteStore() {
    this.loading = true;
    Stores.storesIdDelete(this.selectedStore.id)
    .then((res) => {
      if (res.data.succeeded) {
        this.$notify({ type: "success", text: "Online store deleted." });
        this.reload();
      }
      this.loading = false;
    })
    .catch((error) => {
      this.selectedStore = {
        id: "",
        startDate: "",
        endDate: "",
        title: "",
        deliveryFeePerCustomer: 0,
        storeStatusId: StoreStatus.Editing,
        countryId: "",
        countryName: "",
        currency: {
          id: "",
          name: "",
          code: "USD",
          symbol: "",
          pricingMultiplier: 0,
          decimalPlaces: 2,
        },
        referenceNumber: ""
      }
      console.log(error);
      this.loading = false;
      let errors = error.response.data.errors;
      errors.forEach((error: any) => {
        this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
      });
    });
  }
  
  async loadStore(id:string) {
    this.loading = true;
    await Stores.storesIdGet(id)
      .then(async (res) => {
        if (res.data.succeeded) {
          this.selectedStore = res.data.resultData as StoreViewModel;
        } else {
          this.selectedStore = {
            id: "",
            startDate: "",
            endDate: "",
            title: "",
            deliveryFeePerCustomer: 0,
            storeStatusId: StoreStatus.Editing,
            countryId: "",
            countryName: "",
            currency: {
              id: "",
              name: "",
              code: "USD",
              symbol: "",
              pricingMultiplier: 0,
              decimalPlaces: 2,
            },
            referenceNumber: ""
          }
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
        this.selectedStore = {
          id: "",
          startDate: "",
          endDate: "",
          title: "",
          deliveryFeePerCustomer: 0,
          storeStatusId: StoreStatus.Editing,
          countryId: "",
          countryName: "",
          currency: {
            id: "",
            name: "",
            code: "USD",
            symbol: "",
            pricingMultiplier: 0,
            decimalPlaces: 2,
          },
          referenceNumber: ""
        }
      });
  }

  reload() {
    this.selectedStore = {
      id: "",
      startDate: "",
      endDate: "",
      title: "",
      deliveryFeePerCustomer: 0,
      storeStatusId: StoreStatus.Editing,
      countryId: "",
      countryName: "",
      currency: {
        id: "",
        name: "",
        code: "USD",
        symbol: "",
        pricingMultiplier: 0,
        decimalPlaces: 2,
      },
      referenceNumber: ""
    }
    this.stores = []; 
    this.pageIndex = 1;
    this.loadInitialData();
    this.topAnchor();
  }

  async loadInitialData(page = this.pageIndex) {
    this.loading = true;

    await Stores.storesGet(page, 5)
      .then((res) => {
        if (res.data.succeeded) {
          this.storesPages = res.data.resultData as StoreListViewModelPaginatedListApplicationResultResultData;
          this.storesPages.items.forEach((item: any) => {
            //this is for lazy loading
            this.stores.push(item);
          });
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  beforeUnmount() {
    this.stores = [];
  }
}
