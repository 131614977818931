import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-515d9c1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12 names-and-numbers-container" }
const _hoisted_2 = {
  key: 0,
  class: "col-12"
}
const _hoisted_3 = { class: "selected-row row col-12 d-flex" }
const _hoisted_4 = { class: "col-2 col-xl-1 d-flex" }
const _hoisted_5 = { class: "col-12 xs-column d-flex flex-column mb-3" }
const _hoisted_6 = { class: "col-10 col-xl-11 d-flex row flex-wrap" }
const _hoisted_7 = { class: "col-6 col-md-4 special-col col-lg-3 xs-column d-flex flex-column mb-3" }
const _hoisted_8 = ["onUpdate:modelValue"]
const _hoisted_9 = { class: "col-6 col-md-4 special-col col-lg-3 xs-column d-flex flex-column mb-3" }
const _hoisted_10 = ["onUpdate:modelValue"]
const _hoisted_11 = { class: "col-12 d-flex" }
const _hoisted_12 = { class: "row-index col-1 h-100 pt-2 pt-md-0 d-flex align-items-md-center" }
const _hoisted_13 = { class: "m-0 pe-3 h-xs-secondary" }
const _hoisted_14 = { class: "row col-11 d-flex flex-wrap" }
const _hoisted_15 = { class: "col-12 p-xs-primary mt-3 text-truncate" }
const _hoisted_16 = ["onKeydown", "onChange", "maxlength", "onUpdate:modelValue"]
const _hoisted_17 = { class: "col-12 flex-centered mt-2" }
const _hoisted_18 = {
  key: 0,
  class: "hor-section-division"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.groupedExtras.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _withDirectives(_createElementVNode("input", {
                  class: "col-12 ps-3 disabled-input",
                  type: "text",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.row.quantity) = $event)),
                  disabled: ""
                }, null, 512), [
                  [_vModelText, _ctx.row.quantity]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _withDirectives(_createElementVNode("input", {
                  class: "col-12 ps-3 disabled-input",
                  type: "text",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.row.selectedSizeGroup.displayName) = $event)),
                  disabled: ""
                }, null, 512), [
                  [_vModelText, _ctx.row.selectedSizeGroup.displayName]
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.row.selectedAdjustments, (adjustment) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'fit' + adjustment.adjustmentId,
                  class: _normalizeClass([{'d-none': !_ctx.isFit(adjustment.adjustmentId)}, "col-6 col-md-4 special-col col-lg-3 xs-column d-flex flex-column mb-3"])
                }, [
                  _withDirectives(_createElementVNode("input", {
                    class: "col-12 ps-3 disabled-input",
                    type: "text",
                    "onUpdate:modelValue": ($event: any) => ((adjustment.name) = $event),
                    disabled: ""
                  }, null, 8, _hoisted_8), [
                    [_vModelText, adjustment.name]
                  ])
                ], 2))
              }), 128)),
              _createElementVNode("div", _hoisted_9, [
                _withDirectives(_createElementVNode("input", {
                  class: "col-12 ps-3 disabled-input",
                  type: "text",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.row.selectedSize.name) = $event)),
                  disabled: ""
                }, null, 512), [
                  [_vModelText, _ctx.row.selectedSize.name]
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.row.selectedAdjustments, (adjustment) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: adjustment.adjustmentId,
                  class: _normalizeClass([{'d-none': _ctx.isFit(adjustment.adjustmentId)}, "col-6 col-md-4 special-col col-lg-3 xs-column d-flex flex-column mb-3"])
                }, [
                  _withDirectives(_createElementVNode("input", {
                    class: "col-12 ps-3 disabled-input",
                    type: "text",
                    "onUpdate:modelValue": ($event: any) => ((adjustment.name) = $event),
                    disabled: ""
                  }, null, 8, _hoisted_10), [
                    [_vModelText, adjustment.name]
                  ])
                ], 2))
              }), 128))
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedExtras, (group, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-12 d-flex flex-wrap",
              key: `group-${index}`
            }, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("p", _hoisted_13, _toDisplayString(index + 1), 1)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (extra) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "col-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2",
                      key: `${extra.row}-${extra.extraId}`
                    }, [
                      _createElementVNode("label", _hoisted_15, _toDisplayString(extra.extraName), 1),
                      _withDirectives(_createElementVNode("input", {
                        onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.pasteToRow(extra, group, index)), ["alt"]), ["v"]),
                        onChange: ($event: any) => (_ctx.limitLength(extra)),
                        class: "col-12 ps-3 mt-1",
                        maxlength: extra.customizationTextMaximumLength && extra.customizationTextMaximumLength,
                        type: "text",
                        "onUpdate:modelValue": ($event: any) => ((extra.value) = $event)
                      }, null, 40, _hoisted_16), [
                        [_vModelText, extra.value]
                      ])
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                (_ctx.groupedExtras.length > 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}