
import { mixins, Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage  } from 'vee-validate';
import AddressTile from '@/components/profile/address-book/AddressTile.vue';
import EditAddress from '@/components/profile/address-book/EditAddress.vue';
import { UserAddressListViewModel } from "@/api-client";
import { UserAddresses } from "@/network/api";
import Spinner from "@/components/spinner/Spinner.vue";
import { ScrollTop } from "@/mixins/utilities";

@Options({
  components: { Form, Field, ErrorMessage, AddressTile, EditAddress, Spinner },
  props: { 
    user: { default: null },
    addresses: { default: [] },
    loading : { default: false },
  },
  emits: ['reload', 'loading']
})
export default class AddressBook extends mixins(ScrollTop) {
  showFields = false;
  selectedAddress: UserAddressListViewModel = {
    id: "",
    country: "",
    addressLine1: "",
    addressLine2: "",
    area: "",
    postalCode: "",
    companyName: "",
    isPrimary: false,
  };
  addresses: Array<UserAddressListViewModel> = [];

  topAnchor() {
    this.scrollTop(".profile-content-container", false);
  }

  selectAddress(address: any, showFields = true) {
    if (address) {
      this.selectedAddress = {...address};
    } else {
      this.selectedAddress = {
        id: "",
        country: "",
        addressLine1: "",
        addressLine2: "",
        area: "",
        postalCode: "",
        companyName: "",
        isPrimary: false,
      }
    }   
    if(showFields) {
      this.showFields = true;
    }
  }

  deleteAddress() {
    if (this.selectedAddress.id) {    
      this.$emit('loading', true);
      UserAddresses.userAddressesIdDelete(this.selectedAddress.id)
      .then((res) => {
        if(res.data.succeeded) {
          this.$notify({ type: "success", text: "Address deleted." });
          this.$emit('reload');
        }
        this.$emit('loading', false);
      })
      .catch((error) => {
console.log(error)
        this.$emit('loading', false);
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      })
    }
    
  }

  closeEditAddress() {
    this.showFields = false;
    this.$emit('reload')
  }
}
