import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "blog-posts-container container-fluid w-100 m-0 p-0" }
const _hoisted_2 = {
  key: 0,
  class: "categories-bar d-flex flex-wrap align-items-center align-content-center"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  ref: "posts",
  class: "row g-0 m-0 p-0 posts"
}
const _hoisted_6 = { ref: "bottom" }
const _hoisted_7 = {
  key: 0,
  class: "d-flex col-12 justify-content-center",
  style: {"min-height":"40vh"}
}
const _hoisted_8 = {
  key: 1,
  class: "d-flex col-12 justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_HeadingBanner = _resolveComponent("HeadingBanner")!
  const _component_BlogPostTile = _resolveComponent("BlogPostTile")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_FeaturedProducts = _resolveComponent("FeaturedProducts")!
  const _component_StatementBanner = _resolveComponent("StatementBanner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, { routeName: 'Blog Posts' }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_HeadingBanner, {
        short: true,
        position: 'center',
        bannerSrc: '/img/blog/blog-banner-thin-s.jpg',
        title: 'Blog'
      }, null, 8, ["bannerSrc"]),
      (_ctx.categories.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["d-flex align-items-center category py-2", { 'selected-category': _ctx.selectedCategories.includes(category.id) }]),
                key: category.id
              }, [
                _createElementVNode("div", {
                  onClick: ($event: any) => (_ctx.filterPosts(category.id)),
                  class: "category-tabs ms-4 ms-lg-5",
                  style: _normalizeStyle(`background-color:#${category.hexValue};`)
                }, null, 12, _hoisted_3),
                _createElementVNode("h6", {
                  onClick: ($event: any) => (_ctx.filterPosts(category.id)),
                  class: "category-text text-uppercase m-0 p-0 ms-2 ms-lg-3 me-1"
                }, _toDisplayString(category.name), 9, _hoisted_4)
              ], 2))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blogPostsItems, (post) => {
          return (_openBlock(), _createBlock(_component_BlogPostTile, {
            key: post.id,
            heading: post.name,
            image: post.previewImageUrl,
            categories: post.categories,
            slug: post.slug,
            selectedCategories: _ctx.selectedCategories
          }, null, 8, ["heading", "image", "categories", "slug", "selectedCategories"]))
        }), 128)),
        _createElementVNode("span", _hoisted_6, null, 512),
        (_ctx.loading && !_ctx.blogPostsItems.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_Spinner, { visible: _ctx.loading }, null, 8, ["visible"])
            ]))
          : (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_Spinner, { visible: _ctx.loading }, null, 8, ["visible"])
              ]))
            : _createCommentVNode("", true)
      ], 512)
    ]),
    _createVNode(_component_FeaturedProducts),
    _createVNode(_component_StatementBanner, {
      class: _normalizeClass({'d-none': _ctx.blogPostsItems.length < 1}),
      name: "enquiries"
    }, null, 8, ["class"])
  ], 64))
}