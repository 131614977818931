import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TermlyPolicy = _resolveComponent("TermlyPolicy")!
  const _component_GenericPage = _resolveComponent("GenericPage")!
  const _component_FeaturedProducts = _resolveComponent("FeaturedProducts")!
  const _component_StatementBanner = _resolveComponent("StatementBanner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_GenericPage, { heading: 'Cookie Policy' }, {
      default: _withCtx(() => [
        _createVNode(_component_TermlyPolicy)
      ]),
      _: 1
    }),
    _createVNode(_component_FeaturedProducts),
    _createVNode(_component_StatementBanner, { name: "enquiries" })
  ], 64))
}