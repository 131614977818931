import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "address-tile-container col-12 d-flex justify-content-center" }
const _hoisted_2 = { class: "address-tile d-flex flex-column justify-content-between bg-secondary-light col-12" }
const _hoisted_3 = { class: "address py-3 px-4 position-relative col-12" }
const _hoisted_4 = {
  key: 0,
  class: "p-md-primary mb-2 text-truncate col-11"
}
const _hoisted_5 = {
  key: 1,
  class: "p-sm-primary"
}
const _hoisted_6 = { class: "p-sm-primary" }
const _hoisted_7 = { class: "p-sm-primary" }
const _hoisted_8 = { class: "p-sm-primary" }
const _hoisted_9 = { class: "position-absolute icon-container" }
const _hoisted_10 = { class: "primary-button-container d-flex col-12 justify-content-end p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BasciPromptModal = _resolveComponent("BasciPromptModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.address.companyName)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString(_ctx.address.companyName), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.address.companyName ? 'p-sm-primary' : 'p-md-primary mb-1')
          }, _toDisplayString(_ctx.address.addressLine1), 3),
          (_ctx.address.addressLine2)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.address.addressLine2), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.address.area), 1),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.address.postalCode), 1),
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.address.country), 1),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_font_awesome_icon, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.topAnchor(), this.$emit('edit', _ctx.address))),
              icon: "fa-solid fa-pen-to-square"
            }),
            (!_ctx.address.isPrimary)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: "fa-solid fa-trash",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.topAnchor(), this.$emit('edit', _ctx.address, false))),
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#deleteAddressPrompt",
                  class: "ps-4"
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            class: _normalizeClass(["primary-button", {'is-primary': _ctx.address.isPrimary }]),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.makePrimary(_ctx.address)))
          }, _toDisplayString(_ctx.address.isPrimary ? 'Primary' : 'Set as Primary'), 3)
        ])
      ])
    ]),
    _createVNode(_component_BasciPromptModal, {
      id: "deleteAddressPrompt",
      onConfirm: _ctx.emitDelete,
      content: 'Are you sure you want to delete this address?',
      confirm: 'Yes',
      dismiss: 'No',
      title: 'Delete address'
    }, null, 8, ["onConfirm"])
  ], 64))
}