import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid generic-page-container col-12 mt-5" }
const _hoisted_2 = { class: "row d-flex justify-content-center align-items-center" }
const _hoisted_3 = {
  class: "text-uppercase w-auto h-lg-secondary text-center",
  style: {"letter-spacing":"6px"}
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.heading), 1),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("div", {
          innerHTML: _ctx.text,
          class: "text p-sm-primary py-3 py-sm-5 col-11 col-lg-9"
        }, null, 8, _hoisted_4)
      ])
    ])
  ]))
}