
import { mixins, Options, Vue } from "vue-class-component";
import { Categories } from "@/network/api";
import { CategoryViewModel } from "@/api-client";
import SplideSlider from "../carousel/SplideSlider.vue";
import { UploadPath } from "@/mixins/utilities";

@Options({
  components: {
    SplideSlider
  },
})
export default class FeaturedCategories extends mixins(UploadPath) {
  featuredCategories: Array<CategoryViewModel> = [];

  created() {
    this.loadInitialData();
  }

  loadInitialData() {
    Categories.categoriesFeaturedGet(1, 99999)
      .then((res) => {
        console.log(res);
        this.featuredCategories = res.data.resultData
          ?.items as Array<CategoryViewModel>;
      })
      .catch((error) => {
console.log(error)
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }
}
