import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "home-container container-fluid m-0 p-0" }
const _hoisted_2 = { class: "home-image-container col-12 m-0 p-0" }
const _hoisted_3 = {
  key: 0,
  class: "w-100 image-placeholder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewsletterForm = _resolveComponent("NewsletterForm")!
  const _component_SplideCarousel = _resolveComponent("SplideCarousel")!
  const _component_DesignNowSvg = _resolveComponent("DesignNowSvg")!
  const _component_CarouselBanner = _resolveComponent("CarouselBanner")!
  const _component_ChooseCreateOrderBanner = _resolveComponent("ChooseCreateOrderBanner")!
  const _component_FeaturedAll = _resolveComponent("FeaturedAll")!
  const _component_InstagramCarousel = _resolveComponent("InstagramCarousel")!
  const _component_TrustpilotBanner = _resolveComponent("TrustpilotBanner")!
  const _component_ClientSection = _resolveComponent("ClientSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NewsletterForm, { source: "Homepage popup signup form" }),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.banners.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : (_openBlock(), _createBlock(_component_SplideCarousel, {
            key: 1,
            imageArray: _ctx.banners,
            altText: 'Banner'
          }, null, 8, ["imageArray"]))
    ]),
    _createVNode(_component_DesignNowSvg, { hoverColour: "white" }),
    _createVNode(_component_CarouselBanner, { section: "Carousel 1" }),
    _createVNode(_component_ChooseCreateOrderBanner),
    _createVNode(_component_DesignNowSvg, { hoverColour: "white" }),
    _createVNode(_component_FeaturedAll),
    _createVNode(_component_CarouselBanner, {
      section: "Carousel 2",
      aboveDesignNowButton: true
    }),
    _createVNode(_component_DesignNowSvg, { hoverColour: "white" }),
    _createVNode(_component_InstagramCarousel),
    _createVNode(_component_TrustpilotBanner),
    _createVNode(_component_ClientSection, { clientLogos: _ctx.clientLogos }, null, 8, ["clientLogos"])
  ]))
}