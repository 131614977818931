
import { Options, Vue } from "vue-class-component";

@Options ({
  props: {
    heading: { default: 'No heading available' },
    text: { type: String }
  }
})
export default class GenericPage extends Vue {}
