import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02b92b4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "print-quote-page-container" }
const _hoisted_2 = {
  key: 0,
  class: "w-100",
  style: {"min-height":"60vh"}
}
const _hoisted_3 = { class: "col-12 flex-centered" }
const _hoisted_4 = {
  key: 1,
  class: "d-flex justify-content-center col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_OrderInvoiceDetail = _resolveComponent("OrderInvoiceDetail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Spinner, { visible: _ctx.loading }, null, 8, ["visible"])
          ])
        ]))
      : (_ctx.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_OrderInvoiceDetail, { cartInvoiceId: _ctx.id }, null, 8, ["cartInvoiceId"])
          ]))
        : _createCommentVNode("", true)
  ]))
}