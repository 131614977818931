
import { mixins, Options, Vue } from "vue-class-component";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import { ZohoChat, UploadPath, } from "@/mixins/utilities";
import EnquiryForm from "@/components/enquiries/EnquiryForm.vue";
import { useMeta } from "vue-meta";
import { SetMetaData } from "@/mixins/utilities";

@Options({
  components: {  HeadingBanner, Breadcrumbs, EnquiryForm },
  props: {
  },
})
export default class EnquiryPage extends mixins(UploadPath, SetMetaData, ZohoChat) {
  loaded = false;

  created() {
    const { meta } = useMeta({});
    this.setMetaData(meta, {}, "", "Contact | ");
  }

  mounted() {
    this.loaded = true;

    setTimeout(() => {
      // Source: https://webdesign.tutsplus.com/protect-html-email-links--cms-41203t
      const links: any = document.querySelectorAll("[data-part1][data-part2][data-part3]");
      for (const link of links) {
        const attrs = link.dataset;
        link.setAttribute(
          "href",
          `mailto:${attrs.part1}@${attrs.part2}.${attrs.part3}`
        );
        link.textContent = `${attrs.part1}@${attrs.part2}.${attrs.part3}`;
      }
    }, 0);
  }
}
