
import { mixins, Options } from "vue-class-component";
import Spinner from "@/components/spinner/Spinner.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import OrderSummary from "@/components/checkout-process/checkout/OrderSummary.vue";
import CheckoutLoginComponent from "@/components/checkout-process/checkout/CheckoutLoginComponent.vue";
import CheckoutUserDetailsComponent from "@/components/checkout-process/checkout/CheckoutUserDetailsComponent.vue";
import CheckoutUserBillingComponent from "@/components/checkout-process/checkout/CheckoutUserBillingComponent.vue";
import CheckoutBreadcrumbs from "@/components/checkout-process/CheckoutBreadcrumbs.vue";
import { Country, Currency, IsLoggedIn, QuantityPerItem } from "@/mixins/utilities";
import StripeCheckoutForm from '@/components/checkout-process/checkout/StripeCheckoutForm.vue'
import { Carts, Countries, Stores } from "@/network/api";
import { ApplicationError, CountryViewModel, DeliveryType, OrderCartDeliveryOptionViewModel, OrderCartViewModel, OrderCartViewModelApplicationResultResultData, OrderStatus, PaymentProvider, StoreStatus, StoreViewModel } from "@/api-client";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";
import BasciPromptModal from "@/components/misc/BasicPromptModal.vue";
import { store } from "@/store";

@Options({
  props: { 
    slug: { default: "profile-details" },
    id: {default: ''},
    accessKey: { default: '' }
  },
  components: { Spinner, HeadingBanner, Breadcrumbs, OrderSummary, CheckoutLoginComponent, CheckoutUserDetailsComponent, StripeCheckoutForm, CheckoutUserBillingComponent, BasciPromptModal, CheckoutBreadcrumbs },
})
export default class CheckoutPage extends mixins(Currency, Country , IsLoggedIn, QuantityPerItem, SetMetaData) {
  id = ''
  cartId = '';
  cart: OrderCartViewModel = {
    id: '',
    items: [],
    countryId: '',
    isGift: false,
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.Cart,
    currencyId: '',
    currencyPricingMultiplyer: 1,
    requiresCustomerApproval: true,
    orderDocuments: [],
    isQuote: false,
    additionalCostsConvertedTotal: 0,
    referenceNumber: '',
    deliveryAddress: {
      id: '',
      addressLine1:	'',
      area:	'',
      country:	'',
      postalCode:	''
    },
    billingAddress: {
      id: '',
      addressLine1:	'',
      area:	'',
      country:	'',
      postalCode:	''
    },
    customerContact: {
      id: '',
      name: '',
      email: '',
      phoneNumber: ''
    },
    accessKey: '',
    allowedPaymentProviders: [],
    paymentsTotal: 0,
    paymentsConvertedTotal: 0,
    customerContacts: []
  }
  summaryTotal: Array<any> = [];
  currentSection: 'login' | 'details' | 'order-pack' | 'payment' = "login";
  deliveryDetails: OrderCartDeliveryOptionViewModel = {
    deliveryTypeId:	DeliveryType.Standard,
    deliveryTypeName:	'',
    deliveryDays:	0,
    productionDays:	0,
    deliveryCost:	0.00,
    deliveryDelayDays: 0,
    productionDelayDays: 0
  };
  guestEmail = '';
  paymentProviders: Array<PaymentProvider> = [];
  loading = false;
  accessKey: string | undefined = undefined;
  store: StoreViewModel = {
    id: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeStatusId: StoreStatus.Editing,
    countryId: "",
    countryName: "",
    currency: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    },
    referenceNumber: ""
  };

  get breadcrumbSection() {
    return ['login', 'details', 'order-pack'].includes(this.currentSection) ? 'info' : 'pay';
  }

  get totalPrice() {
    if(this.summaryTotal.length) {
      let total = 0;
      let sum = this.summaryTotal.reduce((a, b) => a + b, total);
      
      return sum; 
    }
    return 0    
  }

  get shareLink() {
    if(this.cart.id) {
      let routeData = this.$router.resolve({name: 'Cart', params: {id: this.cart.id, accessKey: this.cart.accessKey}});
      return `${window.location.origin}${routeData.href}`
    }
    return ''
  }

  get cartCurrency() {
    // if(this.store.id) {
    //   return this.store.currency;
    // } 
    return this.currency();
  }

  created() {   
    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', 'Checkout | ')

    this.loadInitialData()
    .then(() => {
      this.checkPaymentProviders()

      if(this.isLoggedIn || (this.cart.customerContact && this.cart.customerContact.email)) {
        this.currentSection = 'details'
      } else {
        this.currentSection = 'login'
      }

      if(this.cart.storeId) {
        this.setMetaData(meta, {}, '', 'Online Store Checkout | ')
      }
    })
  }

  handleCrumb(type: 'cart' | 'info') {
    if(type === 'cart') {
      if(this.accessKey || !this.cart.storeId) {
        this.$router.push({name: 'Cart', params: {id: this.cart.id, accessKey: this.accessKey || undefined}})
      } else if (this.cart.storeId) {
        this.$router.push({name: 'StoreCart', params: {id: this.cart.id}})
      }
    } else {
      this.currentSection = 'details'
    }
  }

  changeSection(section: 'login' | 'details' | 'order-pack' | 'payment') {
    this.currentSection = section;
  }

  handleGuest(email:string) {
    this.guestEmail = email;
    this.changeSection('details');
  }

  updateSummary(product: any) {
    this.summaryTotal[product.id] = product.price * this.quantityPerItem(product);
  }

  async getDeliveryOptions() {
    await Carts.cartsGetCartDeliveryOptionsIdGet(this.id, this.cart.countryId, this.accessKey || undefined)
    .then((res) => {
      if (res.data.succeeded) {
        let options = res.data.resultData as Array<OrderCartDeliveryOptionViewModel>;
        this.deliveryDetails = options.filter((option: OrderCartDeliveryOptionViewModel) => { return option.deliveryTypeId === this.cart.deliveryTypeId }) as any;
      }
    })
    .catch((error) => {
console.log(error)
      this.loading = false
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
    });
  }

  async updateDeliveryDetails(details:OrderCartViewModel, section: 'login' | 'details' | 'order-pack' | 'payment') {  
    this.loading = true;
    await Carts.cartsUpdateCartIdPut(this.id, this.accessKey || undefined, details)
    .then((res) => {
      if(res.data.succeeded) {
        // TODO should not checkout on next? Check with liam
        this.checkoutCart(res.data.resultData!.id)
      .then((x) => {
        this.loadInitialData()
        .then(() => {
          this.changeSection(section);
        })
      })
      .catch((error) => {
        console.log(error)
        this.loading = false   
      });
      }       
    })
    .catch((error) => {
      console.log(error)
      this.loading = false;
      let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
    });
  }

  async checkPaymentProviders() {
    this.loading = true
    await Carts.cartsGetPaymentProvidersForOrderIdGet(this.id, this.accessKey || undefined)
    .then((res) => {
      if(res.data.succeeded) {
        this.paymentProviders = res.data.resultData as Array<PaymentProvider> 
      }
      this.loading = false;
    })
    .catch((error) => {
      console.log(error)
      this.loading = false;
      let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  extractNumber(str: string): number | null {
    const match = str.match(/\[(\d+)\]/);
    if (match && match[1]) {
        return parseInt(match[1], 10);
    }
    return null;
  }

  async checkoutCart(id:string) {
    return await new Promise<void>((resolve, reject) => {
      this.loading = true;
    Carts.cartsCheckoutIdPut(id, this.accessKey || undefined)
    .then((res) => {
      if(res.data.succeeded) {
        resolve()
      }
      this.loading = false;
    })
    .catch((error) => {
      console.log(error)
      this.loading = false;
      let errors = error.response.data.errors;
        errors.forEach((error: ApplicationError) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });

          if(error.friendlyMessage.includes("is not a valid size")) {
            const index: number | null = this.extractNumber(error.propertyName)

            if(typeof index == 'number') {
              const productId = this.cart.items[index].id
              this.$router.push({ name: "Cart", params: { id: this.id }, query: { sizesFor: productId } })
            }
          }
        });
        reject()
      });
    })
  }

  copyLink() {
    // Write to the clipboard
    if(this.shareLink) {
      let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
      if (!isMobile) {
        navigator.clipboard.writeText(this.shareLink);
        this.$notify({ type: "success", text: "The cart link has been copied to your clipboard." });
      }
      else {
        const sharing = async () => {
          if (navigator.share) {
            await navigator.share({
              title: 'CLIFTON | Truly Custom Clothing',
              text: 'Access my CLIFTON cart!',
              url: this.shareLink
            })
            .then(() => {
                this.$notify({ type: "success", text: "Cart link copied to clipboard." });
              }
            )
            .catch(err => {
                if (err.toString().indexOf("cancel")) {
                  this.$notify({ type: "warning", text: "Copy of cart link cancelled." });
                }
                else {
                  this.$notify({ type: "error", text: `Error: ${err}` });
                }
              }
            );
          }
          else {
            navigator.clipboard.writeText(this.shareLink);
            this.$notify({ type: "success", text: "The cart link has been copied to your clipboard." });
          }
        }

        sharing();
      }
    }
  }

  async getCountry(id: string) {
    return new Promise((resolve, reject) => {
      Countries.countriesGet()
      .then((res) => {
        if (res.data.succeeded && res.data.resultData) {
          const country = res.data.resultData.find((country) => country.id === id) as CountryViewModel;
          resolve(country)
        }
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
        reject(error)
      });
    })
  }

  async getStoreInfo() {
    if(this.cart.id && this.cart.storeId) {
    this.loading = true;
    await Stores.storesIdGet(this.cart.storeId)
      .then(async (res) => {
        if (res.data.succeeded) {
          this.store = res.data.resultData as StoreViewModel;
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
  }

  async loadInitialData() {
    if(this.id) {
      this.loading = true;
      await Carts.cartsGetCartGet(this.id, this.accessKey || undefined)
      .then(async (res) => {
        if(res.data.succeeded) {
          this.cart = res.data.resultData as OrderCartViewModelApplicationResultResultData;

          if(this.cart.storeId) {
            await this.getStoreInfo();
          }
          
          if ((this.store.id && (this.store.countryId != this.country().id)) || (!this.store.id && (this.cart.countryId != this.country().id))) {
            const country = await this.getCountry(this.store.id ? this.store.countryId : this.cart.countryId);
            store.dispatch("location/changeLocation", { country: country });
          }

          await this.getDeliveryOptions().then(() => {
            this.summaryTotal = [];
            this.cart.items.forEach((product: any) => {
              this.summaryTotal.push(
                product.price * this.quantityPerItem(product)
              );
            });
          })    
        }
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
  }
}
