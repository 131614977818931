
import { mixins, Options, Vue } from "vue-class-component";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import FeaturedCategories from "@/components/featured/FeaturedCategories.vue";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import { BlogViewModel } from "@/api-client";
import { Blogs } from "@/network/api";
import { FormatDate, LoadImage, SetMetaData, UploadPath } from "@/mixins/utilities";
import { useMeta } from 'vue-meta'
import Markdown from 'vue3-markdown-it';
import Spinner from "@/components/spinner/Spinner.vue";


@Options({
  components: {
    Breadcrumbs,
    FeaturedCategories,
    StatementBanner,
    Markdown,
    Spinner
  },
  props: {
    slug: { default: "" },
  },
})
export default class BlogPostPage extends mixins(LoadImage, UploadPath, SetMetaData, FormatDate) {
  post: BlogViewModel = {
    name: "",
    slug: "",
    isFeatured: false,
    previewImageUrl: "",
    headerImageUrl: "",
    content: "",
    categories: [],
    authorName: '',
    authorImageUrl: ''
  };
  slug = "";
  banner: any = {};
  postLink: any = '';
  locHost: any = '';
  loading = false

  get formattedBlogPost() {
    if (this.post) {
      return this.post.content;
    }
    return "There is no blog content available.";
  }

  created() {
    this.loadInitialData();
    this.locHost = `${window.location.protocol}//${window.location.host}`;
    this.postLink = this.locHost + this.$route.fullPath;
    console.log(this.postLink);
  }

  mounted() {
    const {meta} = useMeta({})

    this.$watch('post.slug', () => {
      this.setMetaData(meta, this.post, 'slug')
      meta.og.type = 'article' // override default
      meta.og = {
        ...meta.og,
        'article:author': this.post.authorName || '',
        'article:published_time': this.post.publishedDate || '',
        'article:section': this.post.categories[0].name || '',
        'article:tag': this.post.seoKeywords || ''
      }
    })
  }

  loadInitialData() {
    if (this.slug) {
      this.loading = true
      Blogs.blogsSingleBlogSlugGet(this.slug).then((res) => {
        if(res.data.succeeded) {
          this.post = res.data.resultData as BlogViewModel;
          let imgSrc = this.post.headerImageUrl ? `${this.uploadPath}/${this.post.headerImageUrl}` : "/img/blog/blog-banner-thin-s.jpeg";
          this.loadImage(imgSrc)
            .then((img: any) => (this.banner = img))
            .catch((err) => console.error(err));
          }
          this.loading = false
      })
      .catch((error) => {
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
        this.$router.push("/404");
      });
    }
  }
}
