import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_GenericPage = _resolveComponent("GenericPage")!
  const _component_FeaturedProducts = _resolveComponent("FeaturedProducts")!
  const _component_StatementBanner = _resolveComponent("StatementBanner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_GenericPage, { heading: 'Shipping & Returns' }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_Spinner, {
              key: 0,
              visible: _ctx.loading
            }, null, 8, ["visible"]))
          : (_openBlock(), _createBlock(_component_Markdown, {
              key: 1,
              breaks: true,
              html: true,
              class: "p-sm-primary py-3 py-sm-5 col-11 col-lg-9",
              source: _ctx.content
            }, null, 8, ["source"]))
      ]),
      _: 1
    }),
    _createVNode(_component_FeaturedProducts),
    _createVNode(_component_StatementBanner, { name: "enquiries" })
  ], 64))
}