
import { CalculatePrice, Currency, GetPriceByQuantity, QuantityPerItem, ThousandSeparator, UploadPath } from "@/mixins/utilities";
import { mixins, Options, Vue } from "vue-class-component";
import SizeNameSelector from "@/components/checkout-process/cart/SizeNameSelector.vue";
import ProfileProductTile from "@/components/profile/ProfileProductTile.vue";
import PreviewImage from "@/components/images/PreviewImage.vue";
import BasicVueModal from "@/components/misc/BasicVueModal.vue";
import { DeliveryType, OrderCartItemViewModel, OrderCartViewModel, OrderStatus } from "@/api-client";
import { store } from "@/store";

@Options({
  components: { SizeNameSelector, PreviewImage, ProfileProductTile, BasicVueModal },
  props: {
    product: { default: null },
    cart: { default: null },
    isCustomerQuote: { default: false },
    isStoreCustomerCart: { default: false },
    i: { default: 1 },
    storeCurrency: { default: null },
    magnify: {default: false}
  },
  emits: ["reload", "removeId", "update"],
  beforeRouteLeave(to, from, next) {
    this.showModal = false;
  },
})
export default class BasketProductTile extends mixins(UploadPath, Currency, CalculatePrice, GetPriceByQuantity, QuantityPerItem, ThousandSeparator) {
  newQuantity = 0;
  i = 1;
  magnifyDesign = false;
  cart: OrderCartViewModel = {
    id: "",
    items: [],
    countryId: "",
    isGift: false,
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.Cart,
    currencyId: "",
    currencyPricingMultiplyer: 1,
    requiresCustomerApproval: true,
    additionalCostsConvertedTotal: 0,
    orderDocuments: [],
    isQuote: false,
    referenceNumber: '',
    deliveryAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    billingAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    customerContact: {
      id: "",
      name: "",
      email: "",
      phoneNumber: "",
    },
    quoteMessage: '',
    accessKey: '',
    allowedPaymentProviders: [],
    voucher: null,
    paymentsTotal: 0,
    paymentsConvertedTotal: 0,
    customerContacts: []
  };
  product: OrderCartItemViewModel = {
    id: "",
    price: 0,
    totalPrice: 0,
    orderId: "",
    customizedProduct: {
      id: "",
      title: "",
      lastModifiedDate: "",
      productId: "",
      productSlug: "",
      name: "",
      code: "",
      canAddToStore: false,
      previewImageUrl: "",
      backPreviewImageUrl: "",
      customerCanUpdate: false,
      adminCanUpdate: false,
      orderReferenceNumber: "",
      enquiryReferenceNumbers: [],
      orderId: "",
      style: {
        id: "",
        name: "",
        code: "",
        previewImageUrl: "",
        frontShadowMaskUrl: "",
        backShadowMaskUrl: "",
        manufacturerReference: "",
        factoryId: "",
      },
      design: {
        id: "",
        name: "",
        code: "",
        imageUrl: "",
      },
      layers: [],
      items: [],
      extras: [],
    },
    quantities: [],
  };
  showModal = false;
  productTotal = 0;
  debounce: any;
  isStoreCustomerCart = false;
  storeCurrency = null;
  isCustomerQuote = false;

  get productName() {
    return this.cart.storeId && this.product.customizedProduct.storeProductName ? this.product.customizedProduct.storeProductName : this.product.customizedProduct.name;
  }

  get productTitle() {
    return this.cart.storeId && this.product.customizedProduct.storeProductName ? this.product.customizedProduct.name : this.product.customizedProduct.title;
  }

  created() {
    if (this.product.id) {
      this.productTotal = this.product.price * this.quantityPerItem(this.product);
      this.newQuantity = this.quantityPerItem(this.product);
    }

    this.$watch("product", () => {
      clearTimeout(this.debounce);
      this.productTotal = this.product.price * this.quantityPerItem(this.product);
      this.newQuantity = this.quantityPerItem(this.product);
      console.log("prod update");
    });
  }

  get total() {
    return this.productTotal;
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
