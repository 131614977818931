
import { mixins, Options, Vue } from "vue-class-component";
import { CampaignViewModel } from "@/api-client";
import { Campaigns } from "@/network/api";
import { SetMetaData, UploadPath } from "@/mixins/utilities";
import { useMeta } from "vue-meta";
import Spinner from "@/components/spinner/Spinner.vue";
import SplideCarousel from "@/components/carousel/SplideCarousel.vue";
import ClientSection from "@/components/clients/ClientSection.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import EnquiryForm from "@/components/enquiries/EnquiryForm.vue";
import ChooseCreateOrderBanner from "@/components/banners/ChooseCreateOrderBanner.vue"

@Options({
  components: {
    Spinner, SplideCarousel, ClientSection, HeadingBanner, Breadcrumbs, EnquiryForm, ChooseCreateOrderBanner
  },
  props: {
    slug: { default: "" },
  },
})
export default class CampaignPage extends mixins(SetMetaData, UploadPath) {
  loaded = false
  page: CampaignViewModel = {
    id: "",
    name: "",
    startDate: "",
    endDate: "",
    campaignBannerItems: [],
    campaignClients: [],
    bannerImageUrl: ""
  };
  slug = "";
  loading = false;
  campaignNotFound = false;

  created() {
    this.loadInitialData();
  }

  mounted() {
    const { meta } = useMeta({});

    this.$watch("page.id", () => {
      meta.title = `${this.page.name} | CLIFTON | Truly Custom Clothing`;
      meta.og = {
        title:`${this.page.name} | CLIFTON | Truly Custom Clothing`
      }
    });
  }

  loadInitialData() {
    this.campaignNotFound = false;
    if (this.slug) {
      this.loading = true;
      Campaigns.campaignsSingleCampaignSlugGet(this.slug)
      .then((res) => {
        if (res.data.succeeded) {
          this.page = res.data.resultData as CampaignViewModel;
        }
        this.loaded = true
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loaded = true
        this.loading = false;
        let errors = error.response.data.errors;
        if(errors?.[0]?.friendlyMessage == "Campaign has ended") {
          this.campaignNotFound = true;
        } else {
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        }
      });
    }
  }
}
