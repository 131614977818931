
import { CalculatePrice, FormatDate, ThousandSeparator, UploadPath, FormatEnum } from "@/mixins/utilities";
import { mixins, Options } from "vue-class-component";
import PreviewImage from "@/components/images/PreviewImage.vue";
import OrderListProductTile from "@/components/profile/order-history/OrderListProductTile.vue";
import OrderListInfoTile from "@/components/profile/order-history/OrderListInfoTile.vue";
import { OrderHistoryViewModel, OrderPublicStatus } from "@/api-client";

@Options({
  components: { PreviewImage, OrderListProductTile, OrderListInfoTile },
  props: {
    order: {default: null},
    pageName: { default: 'Profile' }
  },
  emits: ["loading", "showDetails"]
})
export default class OrderListTile extends mixins(UploadPath, CalculatePrice, ThousandSeparator, FormatDate, FormatEnum) {
  order: OrderHistoryViewModel = {
    id: "",
    referenceNumber: "",
    orderPublicStatusId: OrderPublicStatus.Processing,
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    additionalCostsConvertedTotal: 0,
    currency: {
      id: "de7c7489-fcc5-40d4-ad7c-f9de27a97f18",
      name: "Pound Sterling",
      code: "GBP",
      symbol: "£",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    orderTotal: 0,
    orderTotalIncludingVoucher: 0,
    orderItems: [],
    deliveryTotal: 0,
    adhocTotal: 0,
    courierLink: "",
    orderInvoices: [],
    customerContacts: [],
    paymentsConvertedTotal: 0,
    paymentsTotal: 0
  };

  calculatedPrice(price: number) {
    if(this.orderCurrency && (price === 0)) {
      return (0).toFixed(this.orderCurrency.decimalPlaces);
    } else if (this.orderCurrency && price) {
      return this.calculatePrice(price, this.orderCurrency.pricingMultiplier, this.orderCurrency.decimalPlaces);
    }
    return "Error";
  }

  get orderCurrency() {
    if (this.order.id) {
      return this.order.currency;
    }
    return null;
  }

  get awaitingAction() {
    if(this.order.showOrderPackApprovalLink) {
      return 'order pack approval';
    } else if(this.order.showAssetResupplyLink) {
      return 'asset resupply';
    } else if(this.order.showOutstandingPaymentLink) {
      return 'outstanding payment';
    }
    return false;
  }

  goToAwaitingAction() {
    let routeData:any = null

    if(this.order.showOrderPackApprovalLink) {
      routeData = `${process.env.VUE_APP_ROOT_ADMIN}/orderpack/approval/${this.order.orderPackIdAwaitingApproval}`
    } else if(this.order.showAssetResupplyLink) {
      routeData = this.$router.resolve({ name: 'ResupplyAssets', params: { id: this.order.id } });
    } else if(this.order.showOutstandingPaymentLink) {
      routeData = this.$router.resolve({ name: 'OrderPayments', params: { id: this.order.id } });
    }

    if(typeof routeData === 'string') {
      window.open(routeData, '_blank');
    } else if (routeData) {
      window.open(`${routeData.href}`, '_blank');
    }
  }
}
