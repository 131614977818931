
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { mixins, Options, Vue } from "vue-class-component";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import ProductTile from "@/components/products/ProductTile.vue";
import { store } from "@/store";
import { CalculatePrice, Currency, UploadPath } from '@/mixins/utilities';
import { ref } from '@vue/reactivity';

@Options({
  components: {Splide, SplideSlide, ProductTile},
  props: {
    imageArray: { default: [] },
    tileCategory: { default: '' },
    infoLinkTo: { default: '' },
    isCategory: { default: false },
    tileBgUrl: { default: '' },
    onSale: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
})


export default class SplideSlider extends mixins(CalculatePrice, Currency, UploadPath) {
  imageArray: any = [];
  thumbnailsConfig = {
    //type: 'loop',
    //fixedWidth: '25%',
    perPage: 4,
    fixedHeight: 700,
    pagination: false,
    perMove: 1,
    autoplay: true,
    resetProgress: true,
    clones: 0,
    pauseOnHover: true,
    pauseOnFocus: false,
    interval: 5000,
    cover: true,
    mediaQuery: 'max',
    rewind: true,
    rewindSpeed: 1500,
    breakpoints : {
      1200: {
        fixedHeight: 500
      },
      991: {
        //fixedWidth: '50%',
        rewindSpeed: 2000,
        perPage: 2,
        fixedHeight: 500
      },
      575: {
        perPage: 1,
        //fixedWidth: '100%',
        rewindSpeed: 2500,
        fixedHeight: 300
      }  
    }
  };

  getFromToPrice(defaultPrice: any, defaultQty: any, minQty: any) {
    if(!defaultPrice) {
      return ``
    }

    return `From ${this.currency().symbol}${this.calculatePrice(defaultPrice, this.currency().pricingMultiplier)} each for ${defaultQty} items | Minimum ${minQty}`
  }
}
