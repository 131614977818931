import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "block-content text-center col-10 col-sm-11 col-xl-8 col-xxl-7" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "d-flex w-100 h-100 position-absolute img-wrap"
}
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["info-page-block-container col-12 col-sm-6 flex-centered relative", {'order-rev-1': _ctx.order === 3, 'order-rev-2': _ctx.order === 4}]),
    style: _normalizeStyle(_ctx.bgImage
    ? `order:${_ctx.order};`
    : `background-color:${_ctx.bgColor};order:${_ctx.order};`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("h4", {
          class: "h-md-secondary mb-4 text-uppercase",
          style: _normalizeStyle(`color:${_ctx.textColour};`)
        }, _toDisplayString(_ctx.heading), 5)
      ]),
      _createElementVNode("div", {
        class: "blurb-text",
        innerHTML: _ctx.text,
        style: _normalizeStyle(`color:${_ctx.textColour};`)
      }, null, 12, _hoisted_2)
    ]),
    (_ctx.bgImage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.bgImage,
            class: "w-100 h-100",
            alt: _ctx.label
          }, null, 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true)
  ], 6))
}