
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    bgColor: { default: "#F2ECEA" },
    bgImage: { default: "" },
    label: { default: "" },
    heading: { default: "" },
    text: { default: "" },
    textColour: { default: "#00233B" },
    number: { default: "" },
    order: { default: null },
  },
})
export default class InfoPageBlock extends Vue {

}
