
import { mixins, Options, Vue } from "vue-class-component";
import { Categories, Products, ProductTags } from "@/network/api";
import FilterBar from "@/components/products/FilterBar.vue";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import PageNotFoundBanner from "@/components/misc/PageNotFoundBanner.vue";
import CategoryTile from "@/components/products/CategoryTile.vue";
import ProductTile from "@/components/products/ProductTile.vue";
import { CategoryListViewModel, CategoryListViewModelPaginatedList, CategoryViewModel, ProductListViewModel, ProductListViewModelPaginatedList, ProductTagViewModel } from "@/api-client";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import Spinner from "@/components/spinner/Spinner.vue";
import utilities from "@/composables/utilities";
import { LoadImage, SetMetaData, UploadPath } from "@/mixins/utilities";
import { useMeta  } from 'vue-meta'
import { FacebookViewContentEvent } from '@/seo/facebook-pixel-helper'

@Options({
  components: {
    FilterBar,
    StatementBanner,
    CategoryTile,
    Breadcrumbs,
    ProductTile,
    Spinner,
    PageNotFoundBanner
  },
  props: { slug: { default: [] } },
  beforeRouteUpdate(to, from) {
    this.clearArrays();
  },
  beforeRouteLeave(to, from) {
    this.clearArrays();
  },
})
export default class CategoriesPage extends mixins(LoadImage, UploadPath, SetMetaData, FacebookViewContentEvent) {
  categories: Array<CategoryListViewModel> = [];
  categoryPages: CategoryListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 12,
  };
  category: CategoryViewModel = {
    id: "",
    name: "",
    parentSlug: "",
    slug: "",
    fullSlug: "",
    seoTitle: "",
    seoKeywords: "",
    seoDescription: "",
    isFeatured: false,
    isDisabled: false,
    hasProducts: false,
    blurb: "",
    thumbnailImages: [],
    bannerImages: [],
    parentCategories: [],
  };
  singleCategoryPages: CategoryListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 12,
  };
  title = "";
  bannerImage = "";
  slug: Array<string> = [];
  joinedSlug = "";
  filters: Array<ProductTagViewModel> = [];
  categoryProducts: Array<ProductListViewModel> = [];
  productPages: ProductListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 12,
  };
  selectedFilters: Array<any> = [];
  searchTerm = "";
  pageIndex = 1;
  loading = true;
  searchLoading = false;
  debounce: any = null;
  originalBanner: any = {};
  notFoundFlag = false;

  created() {
    this.joinedSlug = utilities.joinSlug(this.slug);

    if (this.joinedSlug) {
      this.loadNestedCategories(this.joinedSlug);
    } else {
      this.loadAllCategories();
    }

    this.$watch("joinedSlug", () => {
      // console.log("joined", this.joinedSlug);

      if (this.joinedSlug) {
        this.loadNestedCategories(this.joinedSlug);
      } else {
        this.loadAllCategories();
      }
    });
  }

  isScrolledIntoView(el: any) {
    let rect = el.getBoundingClientRect() as DOMRect;
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  scroll() {
    window.onscroll = () => {
      let allRefs = this.$refs as any;
      let scrolledTo = allRefs.bottom;

      if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
        this.loadMoreItems();
      }
    };
  }

  mounted() {
    this.scroll();

    const {meta} = useMeta({})

    this.$watch('category.id', () => {
      this.setMetaData(meta, this.category, 'id')
      if(this.category.id) {
        this.facebookViewContentEvent()
      }
    })
  }

  clearArrays() {
    //empty all arrays for lazy loading
    this.category = {
      id: "",
      name: "",
      parentSlug: "",
      slug: "",
      fullSlug: "",
      seoTitle: "",
      seoKeywords: "",
      seoDescription: "",
      isFeatured: false,
      isDisabled: false,
      hasProducts: false,
      blurb: "",
      thumbnailImages: [],
      bannerImages: [],
      parentCategories: [],
    };
    this.pageIndex = 1;
    (this.searchTerm = ""), (this.selectedFilters = []);
    this.categories = [];
    this.categoryProducts = [];
    this.loading = true;
    this.notFoundFlag = false;
    this.categoryPages = {
      hasNextPage: false,
      hasPreviousPage: false,
      items: [],
      pageIndex: 1,
      totalCount: 1,
      totalPages: 1,
      pageSize: 12,
    };
    this.productPages = {
      hasNextPage: false,
      hasPreviousPage: false,
      items: [],
      pageIndex: 1,
      totalCount: 1,
      totalPages: 1,
      pageSize: 12,
    };
    this.singleCategoryPages = {
      hasNextPage: false,
      hasPreviousPage: false,
      items: [],
      pageIndex: 1,
      totalCount: 1,
      totalPages: 1,
      pageSize: 12,
    };
    clearTimeout(this.debounce);
    this.originalBanner = {};
    const alert = document.querySelector("[data-id='404'] .vue-notification") as HTMLElement;
    if (alert != null) {
      alert.click();
      // this.$notify.close(404);
    }
  }

  imagePath(item: any) {
    if (item.thumbnailImages.length > 0) {
      return `${this.uploadPath}/${item.thumbnailImages[0].imageUrl}`;
    }
    return ``;
  }

  updated() {
    if (this.slug) {
      this.joinedSlug = utilities.joinSlug(this.slug);
    } else {
      this.joinedSlug = "";
    }
  }

  get splitTitle() {
    if (this.title.includes("&")) {
      return this.title.replace("& ", "& \n");
    } else {
      return this.title;
    }
  }

  getFilterTags() {
    ProductTags.productTagsParentCategorySlugGet(this.joinedSlug)
      .then((res) => {
        if(res.data.succeeded) {
          this.filters = res.data.resultData as Array<ProductTagViewModel>;
        }
      })
      .catch((error) => {
console.log(error)
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  getProducts(pageIndex = 1, pageSize = 12) {
    Products.productsParentCategorySlugGet(pageIndex, pageSize, this.joinedSlug, this.selectedFilters, this.searchTerm)
      .then((res) => {
        if(res.data.succeeded) {
          this.productPages = res.data.resultData as ProductListViewModelPaginatedList;
          this.productPages.items.map((item) => {
            this.categoryProducts.push(item);
          });
        }

        this.loading = false;
        clearTimeout(this.debounce);
      })
      .catch((error) => {
console.log(error)
        this.loading = false;
        console.log(error);
        clearTimeout(this.debounce);
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  handleFilterChange(filters: Array<any> = [], search = "") {
    console.log("filterchange");
    this.loading = true;
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.pageIndex = 1;
      this.selectedFilters = filters;
      this.searchTerm = search;
      this.categoryProducts = [];
      this.getProducts(this.pageIndex);
    }, 400);
  }

  loadAllCategories(pageIndex = 1, pageSize = 12) {
    // console.log("loading all cats");
    this.loadImage("/img/categories-image-thin-s.jpg")
      .then((img: any) => (this.originalBanner = img))
      .catch((err) => console.error(err));

    Categories.categoriesGet(pageIndex, pageSize)
      .then((res) => {
        if(res.data.succeeded) {
          this.categoryPages = res.data.resultData as CategoryListViewModelPaginatedList;
          this.categoryPages.items.map((item: CategoryListViewModel) => {
            this.categories.push(item);
          });
          // console.log("pushed cats", this.categories);

          
        }
        this.loading = false;
      })
      .catch((error) => {
console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  loadSingleCategory(pageIndex = 1, pageSize = 12, slug: string) {
    Categories.categoriesParentCategorySlugGet(pageIndex, pageSize, slug)
      .then((res) => {
        if(res.data.succeeded) {
          this.singleCategoryPages = res.data.resultData as CategoryListViewModelPaginatedList;
          this.singleCategoryPages.items.map((item) => {
            this.categories.push(item);

            
          });
          this.loading = false;
          console.log("single cat items", this.categories);
        }       
      })
      .catch((error) => {
console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  loadNestedCategories(slug: string) {
    this.loading = true;
    Categories.categoriesSingleCategorySlugGet(slug)
      .then((res) => {
        if(res.data.succeeded) {
          this.category = res.data.resultData as CategoryViewModel;

          if (this.category.name) {
            this.title = this.category.name;
          }

          if (this.category.bannerImages!.length) {
            this.loadImage(`${this.uploadPath}/${this.category.bannerImages![0].imageUrl}`)
              .then((img: any) => (this.originalBanner = img))
              .catch((err) => console.error(err));
            // this.bannerImage = `${this.uploadPath}/${
            //   this.category.bannerImages![0].imageUrl
            // }`;
          } else {
            this.loadImage("/img/clifton-hero-with-image-bg-1-thin-s.jpg")
              .then((img: any) => (this.originalBanner = img))
              .catch((err) => console.error(err));
            //this.bannerImage = "/img/clifton-hero-with-image-bg-1-thin-s.jpg";
          }

          if (this.category.hasProducts) {
            // console.log("loading single prods");
            this.getFilterTags();
            this.getProducts(this.pageIndex);
          }

          if (slug && !this.category.hasProducts) {
            // console.log("loading single cate");
            this.loadSingleCategory(this.pageIndex, 12, slug);
          }
        }
        this.loading = false;
      })
      .catch((error) => {
console.log(error)
        this.notFoundFlag = true;
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ id: 404, type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  loadMoreItems() {
    if (this.loading === false && (this.categoryPages.hasNextPage === true || this.productPages.hasNextPage === true || this.singleCategoryPages.hasNextPage === true)) {
      this.loading = true;
      this.pageIndex += 1;
      console.log("adding 1 to index");

      if (this.joinedSlug) {
        this.loadNestedCategories(this.joinedSlug);
      } else {
        this.loadAllCategories(this.pageIndex);
      }
    }
  }
}
