
import { mixins, Options, Vue } from "vue-class-component";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import FeaturedProducts from "@/components/featured/FeaturedProducts.vue";
import GenericBanner from "@/components/banners/GenericBanner.vue";
import InfoPageBlock from "@/components/general-information/InfoPageBlock.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";

@Options({
  components: {
    Breadcrumbs,
    StatementBanner,
    FeaturedProducts,
    InfoPageBlock,
    GenericBanner,
    HeadingBanner
  },
})
export default class CustomStoreInfoPage extends mixins(SetMetaData) {
  blockContent = [
    {
      bgImage: "/img/custom-stores/one.svg",
      label: 'Step 1',
      bgColor: "",
      textColour: "#00233B",
      heading: "Choose your product",
      text: `<p><a href='/all'>Browse and select</a> from our large range of products and customize the design for your group.</p>`,
    },
    {
      bgImage: "/img/custom-stores/two.svg",
      label: 'Step 2',
      bgColor: "",
      textColour: "#F2ECEA",
      heading: "Create your online store",
      text: `<p><a target="_blank" href="/profile?section=online-stores">Click here</a> to set up your own online store featuring your designs. Take control by setting prices for each item, allowing you to generate a profit or subsidize as needed.</p><p class="smaller">NOTE: you will need to have a free CLIFTON account!</p>`,
    },
    {
      bgImage: "/img/custom-stores/three.svg",
      label: 'Step 3',
      bgColor: "#00233B",
      textColour: "#F2ECEA",
      heading: "Collect sizes & payment",
      text: `<p>Share the private link to your store for members to  browse the options, select the perfect size, and securely place their orders at the prices you set.</p>`,
    },
    {
      bgImage: "/img/custom-stores/four.svg",
      label: 'Step 4',
      bgColor: "",
      textColour: "#00233B",
      heading: "place your order",
      text: `<p>Once the agreed date comes we will close the store, make your items to order and then send in one shipment to the organizer.</p>`,
    },
  ];

  commonQuestions = [
    {
      column: 1,
      question: "What payment methods are accepted?",
      answer: `<p>The secure checkout accepts payment via all major credit/debit cards (Visa, MasterCard, AMEX, Discover etc) and Apple Pay.</p>`,
    },
    {
      column: 1,
      question: "Can I set up an online store ONLY to collect orders (and pay later)?",
      answer: `<p>Absolutely! You have full freedom to set the price for each item as you wish. This gives you the opportunity to fully subsidize each order and settle the payment in one go when the online store closes.</p>`,
    },
    {
      column: 1,
      question: "Can people just keep ordering from this store at discounted prices forever?",
      answer: `<p>Our online stores are designed to be open for a specific amount of time determined by you. Each group member is assigned a closing date to ensure we can produce and deliver your items in time for your deadline. Don't worry, if needed, we can push back the closing date within reason. We're here to make sure you have a smooth and stress-free experience!</p>`,
    },
    {
      column: 1,
      question: "Does this cost me anything to set up?",
      answer: `<p>Nope. When we say free online store, we mean it - no hidden fees or “gotcha” charges.</p>`,
    },
    {
      column: 2,
      question: "Can we select the currency for the store?",
      answer: `<p>Yes. We can provide your online store in any currency you prefer. Just set your delivery country & currency you prefer when creating your online store.</p>`,
    },
    {
      column: 2,
      question: `Can I see who has ordered and who hasn't?`,
      answer: `<p>Yup. The organiser can see orders as they come in and “nudge” the slackers in the group who need to complete their order.</p>`,
    },
    {
      column: 2,
      question: "Can we set a custom price for each item in our online store?",
      answer: `<p class="pb-3">Absolutely! We totally get it if you're looking to make some profit from your order. The best part is, you have complete freedom to set your selling price. And guess what? We even show you the estimated profit before you even open your online store. How cool is that? You just send out the online store link, and we'll take care of collecting the payments. Once your shop is closed, we'll quickly send you the difference via bank transfer. Easy peasy!</p>`,
    },
    {
      column: 2,
      question: "Ok, I need one of these. How do I set it up?",
      answer: `<p><a target="_blank" href="/profile?section=online-stores">Click here</a> to set up your own online store or contact us and we can do it for you!</p><p class="mt-3"><b>NOTE:</b> you will need to have a free CLIFTON account!</p>`,
    },
  ];

  created() {
    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', 'Create Your Own Store | ')
  }
}
