
import { BankersRounding, CalculatePrice, CompareObjects, Country, FormatDate, ScrollTop, ThousandSeparator } from "@/mixins/utilities";
import Spinner from "@/components/spinner/Spinner.vue";
import { mixins, Options } from "vue-class-component";
import { CountryViewModel, CurrencyViewModel, CustomizedProductCreateUpdateModel, CustomizedProductViewModel, OrderCartDeliveryOptionViewModel, OrderHistoryViewModel, OrderPublicStatus, StoreCustomizedProductListViewModel, StoreCustomizedProductListViewModelPaginatedList, StoreCustomizedProductsIdDeleteRequest, StoreCustomizedProductsPostRequest, StoresGetRequest, StoresIdDeleteRequest, StoreStatus, StoreViewModel } from "@/api-client";
import StoreListTile from "@/components/profile/custom-stores/StoreListTile.vue";
import { CartItems, Countries, CustomizedProducts, Orders, StoreCustomizedProducts, Stores } from "@/network/api";
import Datepicker from "@vuepic/vue-datepicker";
import { Form, Field, ErrorMessage } from "vee-validate";
import BasicVueModal from "@/components/misc/BasicVueModal.vue";
import AddSavedDesignsToStore from "@/components/profile/custom-stores/AddSavedDesignsToStore.vue";
import BasciPromptModal from '@/components/misc/BasicPromptModal.vue'
import CustomStoreProductTile from "@/components/profile/custom-stores/CustomStoreProductTile.vue";
import OrderStoreRecipients from "@/components/profile/order-history/OrderStoreRecipients.vue";

import { RouteLocation } from "vue-router";
import { store } from "@/store";

type originalPrices = {
  deliveryFee: number,
  designs: Array<{ id: string, price: number }>
}

@Options({
  components: { OrderStoreRecipients, Spinner, StoreListTile, Form, Field, ErrorMessage, Datepicker, BasicVueModal, AddSavedDesignsToStore, CustomStoreProductTile, BasciPromptModal },
  emits: ["close", "update"],
  props: {
    id: { type: String, default: "" },
    isAdminView: { type: Boolean, default: false }
  }
})
export default class CreateEditCustomStore extends mixins(ScrollTop, FormatDate, Country, CompareObjects, BankersRounding, CalculatePrice, ThousandSeparator) {
  loading = false;
  storeId: any = "";
  store: StoreViewModel = {
    id: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeStatusId: StoreStatus.Editing,
    countryId: "",
    countryName: "",
    orderId: null,
    orderStatusId: null,
    currency: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    },
    referenceNumber: "",
    description: null
  };
  showDeliveryPriceCalculator = false;
  deliveryPriceCalculatorCost = 0;
  storeClone: StoreViewModel = {
    id: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeStatusId: StoreStatus.Editing,
    countryId: "",
    countryName: "",
    orderId: null,
    orderStatusId: null,
    currency: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    },
    referenceNumber: "",
    description: null
  };
  storeProductsPages: StoreCustomizedProductListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 0,
    totalPages: 0,
    pageSize: 0,
  };
  storeProducts: Array<StoreCustomizedProductListViewModel> = [];
  order: OrderHistoryViewModel = {
    id: "",
    referenceNumber: "",
    orderPublicStatusId: OrderPublicStatus.Processing,
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    additionalCostsConvertedTotal: 0,
    currency: {
      id: "de7c7489-fcc5-40d4-ad7c-f9de27a97f18",
      name: "Pound Sterling",
      code: "GBP",
      symbol: "£",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    orderTotal: 0,
    orderTotalIncludingVoucher: 0,
    orderItems: [],
    deliveryTotal: 0,
    adhocTotal: 0,
    orderInvoices: [],
    customerContacts: [],
    paymentsConvertedTotal: 0,
    paymentsTotal: 0
  };
  showHowItWorks = false;
  showDetails = true;
  showDesigns = true;
  showSummary = true;
  showDeliveryFee = true;
  showDeliveryDetails = false;
  showAddSavedDesign = false;
  storeTitle = "";
  selectedId = ''
  allCountries: Array<CountryViewModel> = [];
  selectedCurrency: CurrencyViewModel = {
    id: "",
    name: "",
    code: "USD",
    symbol: "",
    pricingMultiplier: 0,
    decimalPlaces: 2,
  }
  storeTotal = 0;
  originalPrices: originalPrices = {
    deliveryFee: 0,
    designs: []
  };
  deliveryOptions: Array<OrderCartDeliveryOptionViewModel> = [];
  termsChecked = false;
  customerDeliveryEstimate = 50;
  storeProductAdded = {
    added: false,
    isDuplicate: false 
  };
  savedDesignLoading = false;
  showRecipients = false;
  isAdminView = false;

  get isEditable() {
    return ["Editing", "Rejected"].includes(this.store.storeStatusId);
  }

  get canSaveDetails() {
    const storeCompare = {...this.store, deliveryFeePerCustomer: null};
    const storeCloneCompare = {...this.storeClone, deliveryFeePerCustomer: null };
    return this.compareObjects(storeCompare, storeCloneCompare);
  }

  get canSaveDelivery() {
    return this.store.deliveryFeePerCustomer === this.storeClone.deliveryFeePerCustomer
  }

  get getProductSellingTotals() {
    let total = 0;
    this.storeProducts.forEach((product) => {
      total += (product.price * product.expectedQuantity)
    })
    
    return total;
  }

  get totalDesignsQuantity() {
    let totalQty = 0;
    this.storeProducts.forEach((product) => {
      totalQty += product.expectedQuantity
    })
    return totalQty;
  }

  get totalDifference() {
    const buyingTotal = this.calculatePrice(this.storeTotal, this.selectedCurrency.pricingMultiplier);
    const price = this.getProductSellingTotals - +buyingTotal;
    return price;
  }

  get storeLink() {
    if(this.storeClone.id && (this.storeClone.storeStatusId === 'Approved')) {
      const routeData = this.$router.resolve({name: 'CustomStoreOrder', params: {id: this.storeClone.id}});
      return `${window.location.origin}${routeData.href}`
    }
    return ''
  }

  // get isOpen() {
  //   if(this.storeClone.id && this.storeClone.endDate) {
  //     const today = new Date().getTime();
  //     const closeDate = new Date(this.storeClone.endDate).getTime();
  //     return today > closeDate ? false : true;
  //   }
  //   return false;
  // }

  get notStarted() {
    if(this.storeClone.id && this.storeClone.startDate) {
      const today = new Date().getTime();
      const startDate = new Date(this.storeClone.startDate).getTime();
      return today < startDate ? true : false;
    }
    return true;
  }

  async created() { 
    await this.loadCountries();    

    if(this.$route.params?.id && this.isAdminView) {
      this.storeId = this.$route.params?.id;
      this.loadInitialData();
    } else if((this.$route.query.store && this.$route.query.store !== "new")) {
      this.storeId = this.$route.query.store;
      this.loadInitialData();
    } else {
      this.showHowItWorks = true;
      this.selectedCurrency = {...this.country().currency};
      this.storeClone.countryId = this.country().id;
    }

    this.$watch('showDeliveryPriceCalculator', () => {
      if(this.showDeliveryPriceCalculator) {
        this.deliveryPriceCalculatorCost = this.storeClone.deliveryFeePerCustomer || 0;
      }
    })
  }

  copyLink() {
    navigator.clipboard.writeText(this.storeLink as string);
    this.$notify({ type: "success", text: "The online store link has been copied to your clipboard." });
  }

  getRecipients(){
    // this.$router.replace({name: 'Profile', query: {section: 'order-history', order: this.order.id, recipients: 'all'}})
    this.showRecipients = true;
  }

  resetStoreProductAdded() {
    this.storeProductAdded = { added:false, isDuplicate: false };
  }

  topAnchor() {
    this.scrollTop(`.profile-content-container`, false);
  }

  handleFee() {
    if (this.storeClone.deliveryFeePerCustomer) {
      if (this.storeClone.deliveryFeePerCustomer <= 0) {
        this.storeClone.deliveryFeePerCustomer = 0;
      } else {
        this.storeClone.deliveryFeePerCustomer = +Number(this.storeClone.deliveryFeePerCustomer).toFixed(2);
      }
    }
  }

  handleCustomerEstimate() {
    this.customerDeliveryEstimate = +Number(this.customerDeliveryEstimate).toFixed(0);
  }

  handleCountryChange() {
    if(['Editing', 'Rejected'].includes(this.storeClone.storeStatusId)) {
      const country = this.allCountries.find(country => country.id === this.storeClone.countryId);
      if(country) {
        console.log('global');
        this.selectedCurrency = {...country.currency};
      }
    } else {
      console.log('local');
      this.selectedCurrency = {...this.storeClone.currency}
    }
  }

  saveDeliveryFee() {
    this.storeClone.deliveryFeePerCustomer = this.deliveryPriceCalculatorCost;
    this.showDeliveryPriceCalculator = false;
  }

  handleConversions() {
    const convertedDeliveryFeePerCustomer = this.bankersRounding((this.originalPrices.deliveryFee as number * this.selectedCurrency.pricingMultiplier), this.selectedCurrency.decimalPlaces);
    this.store.deliveryFeePerCustomer = this.storeClone.deliveryFeePerCustomer = +convertedDeliveryFeePerCustomer;

    this.storeProducts.map((product) => {
      const match = this.originalPrices.designs.find(design => design.id === product.id);
      if(match) {
          const convertedPrice = this.bankersRounding((match.price as number * this.selectedCurrency.pricingMultiplier), this.selectedCurrency.decimalPlaces);
          product.price = +convertedPrice;
      }
      return product;
    })
    this.handleFee()
  }

  deleteDesign() {
    this.loading = true;
    StoreCustomizedProducts.storeCustomizedProductsIdDelete(this.selectedId)
    .then(async (res) => {
      if (res.data.succeeded) {
        this.$notify({ type: "success", text: "Design removed." });
        await this.loadStoreDesigns();
      }
      this.loading = false;
    })
    .catch((error) => {
      this.selectedId = ''
      console.log(error);
      this.loading = false;
      let errors = error.response.data.errors;
      if(errors) {
        errors.forEach((error: any) => {
        this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
      });
      }
    });
  }

  designUpdate(updatedProduct: StoreCustomizedProductListViewModel) {
    this.loading = true;
    const payload: StoreCustomizedProductsIdDeleteRequest  = {
      name: updatedProduct.name,
      expectedQuantity: updatedProduct.expectedQuantity,
      price: +(this.bankersRounding((updatedProduct.price as number / this.selectedCurrency.pricingMultiplier), this.selectedCurrency.decimalPlaces)),
    }

    StoreCustomizedProducts.storeCustomizedProductsIdPut(updatedProduct.id, payload)
    .then((res) => {
      this.storeProducts = this.storeProducts.map(product =>{ 
        if(product.id === updatedProduct.id) {
          this.originalPrices.designs.find(design => design.id === product.id)!.price = res.data.resultData?.price as number;

          // this.originalPrices.designs.forEach(design => {
          //   if(design.id === product.id){
          //     design.price = res.data.resultData?.price as number;
          //   }
          // });

          product = {... updatedProduct};
        }

        return product;
      });
      this.$notify({ type: "success", text: `Changes saved successfully.`, ignoreDuplicates: true });
      this.getProductBuyingTotals();
      this.handleConversions();
      this.loading = false;
    })
    .catch((error) => {
      this.loading = false;
      let errors = error.response.data.errors;
      errors.forEach((error: any) => {
        this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
      });
    })
  }

  formatStartDate(uppercase = true) {
    return this.formatDate(this.storeClone.startDate, uppercase);
  }

  formatEndDate(uppercase = true) {
    return this.formatDate(this.storeClone.endDate, uppercase);
  }

  async loadCountries() {
    await Countries.countriesGet().then((res) => {
      if (res.data.succeeded) {
        this.allCountries = res.data.resultData as Array<CountryViewModel>;
      }
    })
    .catch((error) => {
      console.log(error);
      let errors = error.response.data.errors;
      errors.forEach((error: any) => {
        this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
      });
    });
  }

  async getPriceByExpectedQuantity(product: any):Promise<number> {
    return new Promise((resolve, reject) => {
      if(this.storeClone.countryId) {
        CartItems.cartItemsCalculatePriceForCustomizedProductsPost(this.storeClone.countryId, false, [{customizedProductId: product.id, quantity: +product.defaultQuantity }])
        .then((res) => {
          if (res.data.succeeded) {
            resolve(res.data.resultData?.price as number)
          }
        })
        .catch((error) => {
          console.log(error);
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
          reject(error)
        });
      } else {
        resolve(0);
      }
    })
  }

  async handleAddToStore(product: any) {
    this.savedDesignLoading = true;
    const recommendedPrice = await this.getPriceByExpectedQuantity(product);
    if(product.canAddToStore) {
      const title = (product.title || product.productName)?.substring(0,20);
      const payload: StoreCustomizedProductsPostRequest  = {
        name: title,
        customizedProductId: product.id,
        storeId: this.storeClone.id,
        expectedQuantity: product.defaultQuantity,
        price: recommendedPrice,
      }
      
      await this.addStoreDesign(payload);  
    } else {
      await this.duplicateDesign(product)
      .then(async (res) => {
        const title = (product.title || product.productName)?.substring(0,20);
        const payload: StoreCustomizedProductsPostRequest  = {
          name: title,
          customizedProductId: res,
          storeId: this.storeClone.id,
          expectedQuantity: product.defaultQuantity,
          price: recommendedPrice,
        }

        await this.addStoreDesign(payload, true);
      })
      .catch((error) => {
        this.$notify({ type: "error", text: 'Error adding design to store.', ignoreDuplicates: true, duration: -1 });
        this.savedDesignLoading = false;
      })  
    }
  }

  closeRecipients() {
    // this.$router.push({ name: "Profile", query: { section: "order-history", order: this.selectedOrder.id } });
    this.showRecipients = false;
  }

  async getCustomizedProduct(id: string): Promise<CustomizedProductViewModel> {
    return new Promise((resolve, reject) => {
      CustomizedProducts.customizedProductsIdGet(id)
      .then(async (res) => {
        if (res.data.succeeded) {
          resolve(res.data.resultData as CustomizedProductViewModel);
        }
      })
      .catch((error) => {
        console.log(error);
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
        reject()
      });
    })
  }

  getProductBuyingTotals() {
    const payload = this.storeProducts.map(product => {
      return { customizedProductId: product.id, quantity: product.expectedQuantity }
    })
    CartItems.cartItemsCalculatePriceForCustomizedProductsPost(this.storeClone.countryId, true, payload)
    .then((res) => {
      if (res.data.succeeded) {
        this.storeTotal = res.data.resultData?.price as number;
        this.deliveryOptions = res.data.resultData?.deliveryOptions as Array<OrderCartDeliveryOptionViewModel>;
      }
    })
    .catch((error) => {
      console.log(error);
      let errors = error.response.data.errors;
      errors.forEach((error: any) => {
        this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
      });
    });
  }

  async duplicateDesign(product: any): Promise<string> {
    let customizedProduct = await this.getCustomizedProduct(product.id);
    let newCustomizedProductId = "";
    let newOrderItem = {
      productId: customizedProduct.productId,
      previewImageUrl: customizedProduct.previewImageUrl,
      backPreviewImageUrl: customizedProduct.backPreviewImageUrl,
      title: customizedProduct.title,
      style: { ...customizedProduct.style },
      design: { ...customizedProduct.design },
      layers: [...customizedProduct.layers],
      items: [...customizedProduct.items],
      extras: [...customizedProduct.extras],
      isForQuote: false,
    } as CustomizedProductCreateUpdateModel;

    newOrderItem.items.forEach((item) => {
      delete item.id;
    });

    return new Promise((resolve, reject) => {
      this.loading = true;
      CustomizedProducts.customizedProductsPost(newOrderItem)
      .then(async (res) => {
        if (res.data.succeeded) {
          newCustomizedProductId = res.data.resultData;
          console.log({newCustomizedProductId});
          resolve(newCustomizedProductId);
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
        reject(error)
      });
    })
  }

  async addStoreDesign(payload: StoreCustomizedProductsPostRequest, duplicate = false) {
    this.loading = true;
    StoreCustomizedProducts.storeCustomizedProductsPost(payload)
    .then(async (res) => {
      if (res.data.succeeded) {
        await this.loadStoreDesigns();
        // this.$notify({ type: "success", text: duplicate ? `<span>Design added to store successfully!<br>As the original design was already linked to a store/order, a duplicate  was created.</span>` : "Design added to store successfully!" , duration: -1});
        this.storeProductAdded = {
          added: true,
          isDuplicate: duplicate 
        };
      }
      this.loading = false;
      this.savedDesignLoading = false;
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
      this.savedDesignLoading = false;
      let errors = error.response.data.errors;
      errors.forEach((error: any) => {
        this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
      });
    });
  }

  addDays(date: Date, days: number) {
    const dateCopy = new Date(date);
    dateCopy.setDate(date.getDate() + days);
    return dateCopy;
  }

  get endDateDisabled() {
    let date = new Date();

    if (this.storeClone.startDate) {
      date = new Date(this.storeClone.startDate);
    }

    const start = this.addDays(date, 1);
    return start;
  }

  get endDateStart() {
    let date = new Date();

    if (this.storeClone.endDate) {
      date = new Date(this.storeClone.endDate);
    } else if (this.storeClone.startDate) {
      date = new Date(this.storeClone.startDate);
    }

    const start = this.addDays(date, 1);
    return start;
  }

  handleDates(value: string, field = "") {
    let start = new Date(this.storeClone.startDate).getTime();
    let end = new Date(this.storeClone.endDate).getTime();

    if (field === "startDate" && this.storeClone.endDate) {
      start = new Date(value).getTime();
      if (start > end) {
        this.storeClone.endDate = "";
      }
    } else if (field === "endDate" && this.storeClone.startDate) {
      end = new Date(value).getTime();
      if (start > end) {
        this.storeClone.startDate = "";
      }
    }
  }

  appendLeadingZeroes(n: any) {
    if (n <= 9) {
      return "0" + n;
    }
    return n;
  }

  handlePayViewOrder() {
    if(this.store.orderId) {
      if(this.storeClone.orderStatusId && ['Cart', 'CartValidated'].includes(this.storeClone.orderStatusId)) {
        const routeData = this.$router.resolve({ name: "StoreCart", params: { id: this.store.orderId} });
        window.open(routeData?.href, '_blank');
      } else {
        const routeData = this.$router.resolve({ name: "Profile", query: { section: 'order-history', order: this.store.orderId} });
        window.open(routeData?.href, '_blank');
      }
    }
  }

  submitForApproval() {
    this.loading = true;
    Stores.storesSubmitStoreForApprovalIdPut(this.storeClone.id || this.storeId)
    .then((res) => {
      if (res.data.succeeded) {
        this.topAnchor();
        this.$notify({ type: "success", text: "Online store successfully submitted for approval." });
        this.loadInitialData();
      }
      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
      let errors = error.response.data.errors;
      errors.forEach((error: any) => {
        this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
      });
    });
  }

  submit() {
    // this.formatDatesForAPI();
    const payload: StoresGetRequest | StoresIdDeleteRequest = {
      ...this.storeClone,
      deliveryFeePerCustomer: +(this.bankersRounding((this.storeClone.deliveryFeePerCustomer as number / this.selectedCurrency.pricingMultiplier), this.selectedCurrency.decimalPlaces))
    }
    if (this.storeClone.id) {
      this.loading = true;
      Stores.storesIdPut(this.storeClone.id, payload)
        .then((res) => {
          if (res.data.succeeded) {
            // this.topAnchor();
            this.store = res.data.resultData as StoreViewModel;
            this.storeClone = {... this.store};
            this.storeTitle = this.storeClone.title;
            this.originalPrices.deliveryFee = this.storeClone.deliveryFeePerCustomer as number;
            this.handleConversions();
            this.$emit("update", this.storeClone);
            this.$notify({ type: "success", text: "Changes saved successfully." });
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
    } else {
      this.loading = true;
      Stores.storesPost(payload)
        .then((res) => {
          if (res.data.succeeded) {
            this.topAnchor();
            this.store = res.data.resultData as StoreViewModel;
            this.storeClone = {... this.store};
            this.storeId = this.storeClone.id;
            this.storeTitle = this.storeClone.title;
            this.originalPrices.deliveryFee = this.storeClone.deliveryFeePerCustomer as number;
            this.handleConversions();
            this.$router.replace({ name: "Profile", query: { section: "online-stores", store: this.storeClone.id } });
            this.$notify({ type: "success", text: "Online store created." });
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
    }
  }

  async loadOrder() {
    if(this.storeClone.orderId) {
      this.loading = true;
        await Orders.ordersGetOrderOrderIdGet(this.storeClone.orderId)
        .then((res) => {
          if (res.data.succeeded) {
            this.order = res.data.resultData as OrderHistoryViewModel;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error)
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
    }
  }

  async loadStoreDesigns() {
    this.loading = true;
    await StoreCustomizedProducts.storeCustomizedProductsStoreIdGet(1, 99999, this.storeClone.id || this.storeId)
      .then((res) => {
        if (res.data.succeeded) {
          this.storeProductsPages = res.data.resultData as StoreCustomizedProductListViewModelPaginatedList;
          this.storeProducts = this.storeProductsPages.items as Array<StoreCustomizedProductListViewModel>;

          this.originalPrices.designs = [];
          this.storeProducts.forEach((product) => {
            this.originalPrices.designs.push({id: product.id, price: product.price})
          })
        }

        this.getProductBuyingTotals();
        this.handleConversions()
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  async loadInitialData() {
    this.loading = true;
    await Stores.storesIdGet(this.storeId || this.storeClone.id)
      .then(async (res) => {
        if (res.data.succeeded) {
          this.store = res.data.resultData as StoreViewModel;
          this.storeClone = {... this.store};
          this.storeTitle = this.storeClone.title;
          this.originalPrices.deliveryFee = this.storeClone.deliveryFeePerCustomer as number;

          this.handleCountryChange();
          await this.loadStoreDesigns();
          await this.loadOrder();

          if(["Approved", "Closed"].includes(this.storeClone.storeStatusId)) {
            // this.showDetails = false;
            this.showDesigns = false;
            this.showSummary = false;
            this.showDeliveryFee = false;
          }
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }
}
