
  import { mixins, Options, Vue } from "vue-class-component";
  import GenericPage from "@/components/general-information/GenericPage.vue";
  import StatementBanner from "@/components/banners/StatementBanner.vue";
  import TermlyPolicy from "@/components/misc/TermlyPolicy.vue";
  import FeaturedProducts from "@/components/featured/FeaturedProducts.vue";
  import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";
  
  @Options({
    components: {
      GenericPage, StatementBanner, TermlyPolicy, FeaturedProducts
    }
  })
  export default class CookiePolicyPage extends mixins(SetMetaData) {
    created() {
      const {meta} = useMeta({})
    
      this.setMetaData(meta, {}, '', 'Cookie Policy | ')
    }
  }
  