
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { mixins, Options, Vue } from "vue-class-component";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { UploadPath } from '@/mixins/utilities';
import { ref } from 'vue';

@Options({
  components: {Splide, SplideSlide},
  props: {
    imageArray: { default: [] },
    altText: { default: '' },
  },
})
export default class SplideThumbnailSlider extends mixins(UploadPath) {
  selectedId = '';
  imageArray = [];
  splide:any = ref();
  mainImageConfig = {
    type: 'fade',
    heightRatio: 0.5,
    pagination: false,
    rewind: false,
    arrows: false,
    cover: true,
  };

  thumbnailsConfig = {
    type: 'loop',
    rewind: true,
    fixedWidth: '25%',
    fixedHeight: 100,
    isNavigation: true,
    start: 0,
    gap: 1,
    focus: 0,
    cloneStatus: false,
    trimSpace: true,
    pagination: false,
    cover: true,
    updateOnMove: true,
    arrows: 'slider',
    mediaQuery: 'max',
    breakpoints : {
      1350: {
        gap: 1,
        fixedHeight: 80,
      },
      1100: {
        gap: 3,
      },
      992: {
        gap: 1,
        fixedHeight: 80,
      },
      575: {
        fixedHeight: 60,
      },
      400: {
        gap: 3,
        fixedHeight: 60,
      },
    },
  };

  created() {
    if(!this.selectedId && this.imageArray.length) {
      this.selectedId = (this.imageArray[0] as any).id;
    }
  }

  mounted() {
    const main = this.$refs.main as any;
    const thumbs = this.$refs.thumbs as any;    

    if ( thumbs && thumbs.splide ) {
      main.sync(thumbs.splide);
    }

    main.splide.on( 'move', this.handleMove);
  }

  hasLoaded(image:any) {
    if(Object.prototype.hasOwnProperty.call(image, 'loaded')) {
      return image.loaded;
    }
    return false;
  }

  handleMove(newIndex: number, prevIndex: number, destIndex: number) {
    this.selectedId = (this.imageArray[newIndex] as any).id;
    (this.imageArray[newIndex] as any).loaded = true
  }

  videoUrl (imageUrl: string) {
    if(imageUrl.includes('player.vimeo.com')) {
      const url = imageUrl + '&amp;autoplay=1&amp;loop=1&amp;muted=1'
      return url.replaceAll('&amp;', '&');
    }
    return ''
  }
}
