
import { CategoryViewModel } from "@/api-client";
import { Options, Vue } from "vue-class-component";
import utilities from "@/composables/utilities";
import { Categories } from "@/network/api";

@Options({
  props: {
    originSlug: { default: null },
    category: { default: null },
    productName: { default: null },
    productSlug: { default: null },
    basicPage: { default: null },
    basicRoute: { default: '/' },
    routeName: { default: null },
    height: { default: null }
  },
})
export default class Breadcrumbs extends Vue {
  category: CategoryViewModel = {
    id: "",
    name: "",
    parentSlug: "",
    slug: "",
    fullSlug: "",
    seoTitle: "",
    seoKeywords: "",
    seoDescription: "",
    isFeatured: false,
    isDisabled: false,
    hasProducts: false,
    blurb: "",
    thumbnailImages: [],
    bannerImages: [],
    parentCategories: [],
  };
  originSlug = "";
  originParent = "";
  productName = "";
  finalLevel = 0;
  fullSlug?: string = "";
  fetchedCategory:CategoryViewModel = {
    id: "",
    name: "",
    parentSlug: "",
    slug: "",
    fullSlug: "",
    seoTitle: "",
    seoKeywords: "",
    seoDescription: "",
    isFeatured: false,
    isDisabled: false,
    hasProducts: false,
    blurb: "",
    thumbnailImages: [],
    bannerImages: [],
    parentCategories: [],
  };
  productNameWithSlash: string | null = null;

  get currentPage() {
    return window.location.pathname;
  }

  created() {
    this.handleCategory()

    this.$watch("category", () => {
      this.handleCategory()
    });
  }

  getCategoryName(crumb:string) {
    if(this.fetchedCategory.id) {
      if(!this.fetchedCategory.parentCategories?.length && (this.fetchedCategory.slug === crumb)) {
      return this.fetchedCategory.name;
    } else if(this.fetchedCategory.parentCategories?.length) {
      let name = this.fetchedCategory.parentCategories.find(category => category.slug === crumb)?.name;
      return name || crumb
    } else {
      return crumb;
    }
    } else {
      if(!this.category.parentCategories?.length && (this.category.slug === crumb)) {
        return this.category.name;
      } else if(this.category.parentCategories?.length) {
        let name =  this.category.parentCategories.find(category => category.slug === crumb)?.name;
        return name || crumb
      } else {
        return crumb;
      }
    }
  }

  async handleCategory() {
    if (this.category && !this.originSlug) {
      
        this.fullSlug = this.category.fullSlug;

        if (this.productName && !this.productName.includes("/")) {
          this.fullSlug = this.category.fullSlug + "/" + this.productName;
        } else (
          this.productNameWithSlash = this.productName
        )
      } else {
        if(this.originSlug) {
          await this.getCategoryInfo(this.originSlug)

          let originArray = this.originSlug.split("/");
          this.originParent = originArray[originArray.length - 1];
          if(this.productName && !this.productName.includes("/")) {
            this.fullSlug = this.originSlug + "/" + this.productName;
          } else if(this.productName) {
            this.fullSlug = this.originSlug
            this.productNameWithSlash = this.productName
          }
        }
      }
  }

  get fullSlugArray() {
    if (this.fullSlug) {
      let slugs = [] as Array<string>;

      slugs = this.fullSlug.split("/");
      if(this.productNameWithSlash) {
        slugs.push(this.productNameWithSlash);
      }
      this.finalLevel = slugs.length - 1;
      return slugs;
    }
    return [];
  }

  handleSlug(breadcrumb: string, index: number) {
    if (this.fullSlug) {
      if (index == 0) {
        return utilities.splitSlug(breadcrumb);
      } else {
        return utilities.splitSlug(
          this.fullSlug.split(breadcrumb)[0] + breadcrumb
        );
      }
    }
    return "";
  }

  async getCategoryInfo(slug:string) {
    if(slug) {
      await Categories.categoriesSingleCategorySlugGet(slug)
      .then((res) => {    
        if(res) {
          this.fetchedCategory = res.data.resultData as CategoryViewModel;
        }
      })
      .catch((error) => {
        console.log(error)
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
  }
}
