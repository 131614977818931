import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid info-paragraph-container w-100 d-flex p-0 mb-0 mb-sm-4 mb-md-5" }
const _hoisted_2 = { class: "row col-12 g-0 flex-column flex-md-row align-items-start align-items-md-start justify-content-start justify-content-md-start" }
const _hoisted_3 = { class: "img-container col-5 col-md-3 d-block" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-container align-self-center align-self-md-start col-11 col-md-8 col-xl-6 ms-0 ms-md-5 mt-4 mt-sm-5 mt-md-0" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.imageSrc,
          class: "m-0 p-0",
          alt: "",
          loading: "lazy"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h1", {
          class: "mb-4 info-title text-start text-uppercase",
          innerHTML: _ctx.heading
        }, null, 8, _hoisted_6),
        _createElementVNode("p", {
          class: "info-text",
          innerHTML: _ctx.text
        }, null, 8, _hoisted_7)
      ])
    ])
  ]))
}