
import { mixins, Options, Vue } from "vue-class-component";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import FilterBar from "@/components/products/FilterBar.vue";
import BlogPostTile from "@/components/blog/BlogPostTile.vue";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import FeaturedProducts from "@/components/featured/FeaturedProducts.vue";
import InfoPageParagraph from "@/components/general-information/InfoPageParagraph.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";

@Options({
  components: {
    Breadcrumbs, FilterBar, BlogPostTile, StatementBanner, FeaturedProducts, InfoPageParagraph, HeadingBanner
  }
})
export default class HowItWorksPage extends mixins(SetMetaData) {
  content = [
    {
      title: "1. CHOOSE YOUR APPAREL & ACCESSORIES",
      text: "Need some custom sports shirts or made-to-order varsity blazers?  You've come to the right place. Tell us what you need, and we'll help you make it happen.",
      src: '/img/how-it-works/shirt.png'
    },
    {
      title: "2. DESIGN IT YOUR WAY",
      text: 'Ready to put your own unique stamp on your apparel? Pick the style and your design. Add your own custom logos and any custom text or signature details you want in print or embroidery. Need a little help? Our friendly expert team are here for it!',
      src: '/img/how-it-works/pencil.png'
    },
    {
      title: "3. ORDER, PAY & RELAX",
      text: `Submit your purchase via our secure checkout - we accept all major debit cards, credit cards, Apple Pay and bank transfer for all major currencies. Need a free online store to collect orders and payments? <a class="link-to" href="/create-an-online-store" target="_blank">Click here to create one</a>.`,
      src: '/img/how-it-works/thumb.png'
    },
    {
      title: "4. YOUR CLIFTON ORDER IS UNDERWAY!",
      text: "Great news - now that you have paid and approved your custom CLIFTON design proof, we will cut, sew and dispatch your order to your prefered address worldwide via your choice of Standard or Express delivery. Just sit back, relax and let us take care of the rest.",
      src: '/img/how-it-works/box.png'
    }
  ]

  created() {
    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', 'Designing Customwear, the CLIFTON Way | ')
  }
}
