
import { mixins, Options } from "vue-class-component";
import OrderInvoiceDetail from "@/components/profile/order-history/OrderInvoiceDetail.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";
import { OrderInvoiceViewModel } from "@/api-client";
import { Orders } from "@/network/api";
import Spinner from "@/components/spinner/Spinner.vue";
import { FormatDate, ScrollTop } from "@/mixins/utilities";

@Options({
  components: { OrderInvoiceDetail, Datepicker, Spinner },
  props: {
    orderIdProp: { default: '' },
    pageName: { default: "Profile" }
  },
})
export default class OrderInvoiceList extends mixins(FormatDate, ScrollTop) {
  invoices:Array<OrderInvoiceViewModel> = [ ];
  date: any = null;
  selectedDates: any = {
    start: undefined,
    end: undefined,
  };
  orderId:any = '';
  orderIdProp:any = '';
  orderRef:any = '';
  invoiceInfo:any = {id: ''};
  selectedInvoice:any = {id: ''};
  showInvoiceDetail = false;
  loading = false;
  pageName = "Profile";

  created() {
    //generally sending through invoice object here, not making any call
    //still nice to have the orderId on hand
    if (!this.orderId) {
        if (this.$route.query.invoicelist) {
            this.orderId = this.$route.query.invoicelist;
        }
        else if (this.orderIdProp) {
            this.orderId = this.orderIdProp;
        }
    }
    //if list isn't passed through, we can use the orderId to grab it
    this.loadInitialData().then(() => {
      if (this.$route.query.invoice) {
        this.showInvoiceDetail = true;
        this.selectedInvoice.id = this.$route.query.invoice;
      }
    });

    // this.$watch("date", () => {
    //     if (this.date) {
    //         this.selectedDates = {
    //         start: new Date(this.date![0]),
    //         end: new Date(this.date![1]),
    //         };
    //     } else {
    //         this.selectedDates = {
    //         start: undefined,
    //         end: undefined,
    //         };
    //     }
    //     this.loading = false;
    // });

    this.loading = false;
  }

  async loadInitialData() {
    this.loading = true;
    if(!this.orderId) {
        this.loading = false;
        return;
    }
    await Orders.ordersGetOrderOrderIdGet(this.orderId)
      .then((res) => {
        if (res.data.succeeded) {
            this.invoices = res.data.resultData?.orderInvoices as any;
            this.orderRef = res.data.resultData?.referenceNumber as any;
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        if(errors) {
            errors.forEach((error: any) => {
                this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            });
        }
      });
    this.loading = false;
  }

  topAnchor() {
    this.scrollTop(`.${this.pageName.toLowerCase()}-content-container`, false);
  }

  closeInvoiceDetails(id: any) {
    this.orderId = id;
    this.selectedInvoice = "";

    if(this.pageName === 'CheckOrderStatus') {
      this.$router.replace({ name: "CheckOrderStatus", query: {order: this.$route.query.order, postal: this.$route.query.postal, invoicelist: this.orderId } });
    } else {
      this.$router.push({ name: "Profile", query: { section: "order-history", invoicelist: this.orderId } });
    }

    this.showInvoiceDetail = false;
    this.loadInitialData();
  }

  getInvoice(invoice: string) {
    this.selectedInvoice = invoice;
    this.showInvoiceDetail = true;

    if(this.pageName === 'CheckOrderStatus') {
      this.$router.replace({ name: "CheckOrderStatus", query: {order: this.$route.query.order, postal: this.$route.query.postal, invoicelist: this.orderId, invoice: this.selectedInvoice.id } });
    } else {
      this.$router.replace({ name: "Profile", query: { section: "order-history", invoicelist: this.orderId, invoice: this.selectedInvoice.id } });
    }
  }
}
