import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Teleport as _Teleport, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-866ed922"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative custom-store-order-product-tile-container col-12" }
const _hoisted_2 = { class: "col-12 custom-store-order-product-tile-link" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = {
  key: 0,
  class: "custom-store-order-product-box col-12 d-flex flex-column justify-content-start align-items-center w-100"
}
const _hoisted_5 = { class: "svg-custom-store-order-product-box-info w-100 position-absolute" }
const _hoisted_6 = { class: "info-container primary-font-bold col-12 d-flex m-0 p-0" }
const _hoisted_7 = { class: "magnify-preview-body d-flex flex-wrap h-100" }
const _hoisted_8 = { class: "col-12 d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PreviewImage = _resolveComponent("PreviewImage")!
  const _component_SplideSlide = _resolveComponent("SplideSlide")!
  const _component_Splide = _resolveComponent("Splide")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BasicVueModal = _resolveComponent("BasicVueModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.isSVG)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass(["col-12 svg-container d-flex position-relative", `bg-${_ctx.bg}`])
                }, [
                  _createVNode(_component_Splide, {
                    ref: "splide",
                    class: "carousel-splide",
                    options: _ctx.spliderConfig
                  }, {
                    default: _withCtx(() => [
                      (_ctx.imageUrl)
                        ? (_openBlock(), _createBlock(_component_SplideSlide, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_PreviewImage, {
                                onLoaded: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('loaded'))),
                                isFront: true,
                                class: _normalizeClass(["product-image-container col-12 p-2", `bg-${_ctx.bg}`]),
                                style: _normalizeStyle(_ctx.border && 'border: 2px solid #e6dad8;'),
                                path: `${_ctx.uploadPath}/${_ctx.imageUrl}`
                              }, null, 8, ["class", "style", "path"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.backImageUrl)
                        ? (_openBlock(), _createBlock(_component_SplideSlide, { key: 1 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_PreviewImage, {
                                style: _normalizeStyle(_ctx.border && 'border: 2px solid #e6dad8;'),
                                class: _normalizeClass(["product-image-container col-12 p-2", `bg-${_ctx.bg}`]),
                                path: `${_ctx.uploadPath}/${_ctx.backImageUrl}`
                              }, null, 8, ["style", "class", "path"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["options"]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", {
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.flip && _ctx.flip(...args))),
                        class: "text-light bg-primary flex-centered text-uppercase col-12",
                        style: {"z-index":"1"}
                      }, [
                        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-camera-rotate" })
                      ])
                    ])
                  ])
                ], 2)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", {
        class: "position-absolute magnify-button",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.magnifyDesign = true))
      }, [
        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-magnifying-glass-plus" })
      ])
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_BasicVueModal, {
        show: _ctx.magnifyDesign,
        onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.magnifyDesign = false)),
        class: "preview"
      }, {
        body: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_PreviewImage, {
              class: _normalizeClass(["product-image-container zoom col-12 col-sm-6 p-1 h-100", `bg-${_ctx.bg}`]),
              path: `${_ctx.uploadPath}/${_ctx.imageUrl}`
            }, null, 8, ["class", "path"]),
            _createVNode(_component_PreviewImage, {
              class: _normalizeClass(["product-image-container zoom col-12 col-sm-6 p-1 h-100", `bg-${_ctx.bg}`]),
              path: `${_ctx.uploadPath}/${_ctx.backImageUrl}`
            }, null, 8, ["class", "path"])
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.magnifyDesign = false)),
              class: "btn-sm-secondary flex-centered"
            }, " Close ")
          ])
        ]),
        _: 1
      }, 8, ["show"])
    ]))
  ], 64))
}