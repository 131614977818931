
import { Options, Vue } from "vue-class-component";
import ProfileProductTile from "@/components/profile/ProfileProductTile.vue";
import ShareModal from "@/components/share/ShareModal.vue";
import { CustomizedProductListViewModel, CustomizedProductListViewModelPaginatedList, StoreCustomizedProductListViewModel } from "@/api-client";
import { CustomizedProducts } from "@/network/api";
import Spinner from "@/components/spinner/Spinner.vue";

@Options({
  components: { ProfileProductTile, ShareModal, Spinner },
  props: { 
    user: { default: null },
    storeProducts: { type: Array, default: () => [] },
    storeProductAdded: { default: { added: false, isDuplicate: false } },
    isLoading: { default: false }
  },
  emits: ['add', 'reset']
})
export default class AddSavedDesignsToStore extends Vue {
  savedDesignPages: CustomizedProductListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 12,
  };
  savedDesigns: Array<CustomizedProductListViewModel> = []
  shareId = "";
  pageIndex = 1;
  loading = false;
  productsLoaded = true;
  loaded = 0;
  showShareModal = false
  productTitle = "";
  previewPath = "";
  storeProducts: Array<StoreCustomizedProductListViewModel> = [];
  storeProductAdded = { 
    added: false,
    isDuplicate: false
  };
  isLoading = false;

  created() {
    this.getSavedDesigns();

    this.$watch('isLoading', () => {
      this.loading = this.isLoading;
    })
  }

  // isInStore(id: string) {
  //   return this.storeProducts.findIndex(product => product.id === id) >= 0 ? true : false;
  // }

  getSavedDesigns(pageIndex = 1, pageSize = 6) {
    this.loading = true;   
    CustomizedProducts.customizedProductsGetCustomizedProductsGet(pageIndex, pageSize)
    .then((res) => {
      if(res.data.succeeded) {
        this.savedDesignPages = res.data.resultData!;
        this.savedDesignPages.items.forEach((item:any) => {
          //this is for lazy loading
          this.savedDesigns.push(item);
        });
        console.log(this.savedDesigns);      
      }
        this.loading = false;

    })   
    .catch((error) => {
      console.log(error)
      this.loading = false;
      let errors = error.response.data.errors;
      errors.forEach((error: any) => {
        this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
      });
    });
  }

  loadMoreDesigns() {
    if ((this.loading === false) && (this.savedDesignPages.hasNextPage === true)) {
      this.loading = true;
      this.pageIndex += 1;

      this.getSavedDesigns(this.pageIndex);
    }
  }

  determineLoad() {
      this.loaded += 1;
      if(this.loaded >= (this.savedDesignPages.totalCount - 2)) {
        this.productsLoaded = true;
      }
  }

  addProductToStore(product: any) {
    this.loading = true;
    const isInStore = this.storeProducts.find(storeProduct => storeProduct.id === product.id)
    if(isInStore && product.canAddToStore) {
      product.canAddToStore = false;
    }
    this.$emit('add', product);
  }

  beforeUnmount() {
    this.savedDesigns = [];
  }
}
