
import { mixins, Options, Vue } from "vue-class-component";
import Spinner from "@/components/spinner/Spinner.vue";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";
import OrderInvoiceDetail from "@/components/profile/order-history/OrderInvoiceDetail.vue";

@Options({
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: { Spinner, OrderInvoiceDetail },
})
export default class QuotePage extends mixins(SetMetaData) {
  id = ''
  loading = false;

  created() {
    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', 'Print Quote | ')

    //this.loadInitialData()
  }

  // async loadInitialData() {
  //   if(this.id) {
  //     this.loading = true;     
  //       await Orders.ordersCreateQuoteInvoiceOrderIdPost(this.id)
  //       .then((res) => {
  //         if (res.data.succeeded) {
  //           debugger
  //         }
  //         this.loading = false;
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         this.loading = false;
  //         let errors = error.response.data.errors;
  //         errors.forEach((error: any) => {
  //           this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
  //         });
  //       });
  //     }
  //   }   
  }
