
import { mixins, Options, Vue } from "vue-class-component";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import FeaturedProducts from "@/components/featured/FeaturedProducts.vue";
import InfoPageParagraph from "@/components/general-information/InfoPageParagraph.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";

@Options({
  components: {
    Breadcrumbs, StatementBanner, FeaturedProducts, InfoPageParagraph, HeadingBanner
  }
})
export default class AboutUsPage extends mixins(SetMetaData) {
  content = [
    {
      title: "We're A Family Business",
      text: `<p>Like most good ideas, CLIFTON started with two brothers recognizing a common problem with custom group apparel. After a lot of research they discovered that it is often poor quality and rarely made-to-order.</p><p>Our idea was to solve that, while bringing a whole new gold standard into the custom clothing industry.</p>`,
      src: '/img/about-us/couple-line.png'
    },
    {
      title: "When We Say Bespoke, We Mean It",
      text: `<p>We believe that buying customwear should feel as unique and personalized as your order, where the customer's experience is everything, and even the smallest details - be that an embroidered logo or unusual custom accessory - is easily and efficiently taken care of.</p><p>After looking for quality custom apparel for ourselves and seeing what was on offer, we decided we could create a far better, more original solution for companies seeking a truly exceptional product - and customer experience. So - we did.</p>`,
      src: '/img/about-us/scissors.png'
    },
    {
      title: "It Started With A Simple Idea",
      text: `<p>We started from humble beginnings, and are proud to see how far we've come. Despite not coming from a haute couture background, we set out to listen to our customers, experiment with total design versatility and learn from each and every custom order.</p><p>We've never looked back and most importantly, we've never stopped improving on every aspect of our made-to-order clothing. From the manufacturing to ethical supply chain and carbon neutral shipping, we're thrilled to design the kind of quality customwear and online shopping experience that customers keep coming back for.</p>`,
      src: '/img/about-us/lightbulb.png'
    },
    {
      title: "Growth Through Loyalty, Innovation & Responsibility",
      text: `<p>What started as two brothers selling personalized hoodies for local colleges and varsity groups, has grown into a brilliant global brand dedicated to designing and delivering great quality customized clothing.</p><p>From Olympic teams to household name brands, blue chip firms to major media and entertainment companies, we're proud to dress some of the biggest professional and public names in truly bespoke customwear that stands out from the crowd.</p>`,
      src: '/img/about-us/steps.png'
    }
  ]

  created() {
    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', 'Our Story | ')
  }
}
