
import { mixins, Options } from "vue-class-component";
import Spinner from "@/components/spinner/Spinner.vue";
import { FormatDate, ThousandSeparator, CalculatePrice, ScrollTop } from "@/mixins/utilities";
import { OrderInvoiceViewModel } from "@/api-client";
import { Orders } from "@/network/api";
import Markdown from 'vue3-markdown-it';

@Options({
  components: { Spinner, Markdown },
  props: {
    invoiceId: { default: '' },
    invoiceRef: { default: '' },
    cartInvoiceId: { default: '' },
    pageName: { default: "Profile" }
  },
})
export default class OrderInvoiceDetail extends mixins(FormatDate, ThousandSeparator, CalculatePrice, ScrollTop) {
  invoiceId:any = '';
  cartInvoiceId:any = '';
  invoice:OrderInvoiceViewModel = {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      orderId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      startDateTime: "2022-08-01T11:57:28.136Z",
      endDateTime: "2022-08-01T11:57:28.136Z",
      orderInvoiceTypeId: "Invoice",
      referenceNumber: "",
      currency: {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          name: "",
          code: "",
          symbol: "",
          pricingMultiplier: 0,
          decimalPlaces: 0
      },
      convertedBalance: 0,
      costs: [
          {
              convertedTotalAmount: 0,
              quantity: 0,
              title: "",
              reason: "",
              dateTimeStamp: "2022-08-01T11:57:28.136Z"
          }
      ],
      payments: [
          {
              convertedTotalAmount: 0,
              paymentProviderId: "EFT",
              paymentDate: "2022-08-01T11:57:28.136Z"
          }
      ],
      dateTimeStamp: '',
      orderReferenceNumber: '',
      voidedDate: '',
      country: {
          id: '',
          name: '',
          isDefault: false,
          code: '',
          deliveryTypes: [],
          allowBankTransfers: false,
          currency: {
              id: '',
              name: '',
              code: '',
              symbol: '',
              pricingMultiplier: 1,
              decimalPlaces: 2
          },
          bankTransferDetails: ""
      },
      invoiceNumber: 1,
      deliveryAddress: {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          companyName: "",
          addressLine1: "",
          addressLine2: "",
          area: "",
          country: "",
          postalCode: "",
          additionalNotes: "",
          ponumber: ""
      },
      billingAddress: {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          companyName: "",
          addressLine1: "",
          addressLine2: "",
          area: "",
          country: "",
          postalCode: "",
          additionalNotes: "",
          ponumber: ""
      }
    };
  loading = false;
  balanceTotals = {
    invoice: 0,
    payment: 0
  };
  orderId:any = '';
  pageName = "Profile";

  created() {
    //should be able to get invoiceId from url to load data
    if (!this.invoiceId && this.$route.query.invoice) {
      this.invoiceId = this.$route.query.invoice;
    }
    this.loadInitialData().then(() => {
      if (this.invoice.orderId) {
        this.orderId = this.invoice.orderId;
      }
    });
  }

  getAddressLineRender(addressLine: any, key: any) {
    key = key.toLowerCase();
    const blacklistKeys = ["id", "companyname", "additionalnotes"];
    if (!addressLine || addressLine == "" || blacklistKeys.indexOf(key) > -1) {
      return;
    }

    var render = addressLine;
    if (key == "ponumber") {
      render = `PO Number: ${addressLine}`;
    }
    return render;
  }

  totalInvoice(costs: any) {
    var total = this.fullTotal(costs);
    this.balanceTotals.invoice = total;
    return total;
  }

  totalPayment(costs: any) {
    var total = this.fullTotal(costs);
    this.balanceTotals.payment = total;
    return total;
  }

  fullTotal(costs: any) {
    var total = 0;
    for (let i = 0; i < costs.length; i++) {
      total += costs[i].convertedTotalAmount
    }
    return total;
  }

  displayPrice(price: number) {
    if (price || price == 0) {
      var rounded = this.calculatePrice(price, 1, 2);
      return rounded;
    }
    return "Error";
  }

  currencyDisplay(currency: any) {
    return `${currency.code} ${currency.symbol}`;
  }

  async loadInitialData() {
    if (this.invoiceId) {
      this.loading = true;
      await Orders.ordersGetOrderInvoiceDataOrderInvoiceIdGet(this.invoiceId)
        .then((res) => {
          if (res.data.succeeded) {
            this.invoice = res.data.resultData as any
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          let errors = error.response.data.errors;
          if(errors) {
            errors.forEach((error: any) => {
                this.$notify({ type: "error", text: error.friendlyMessage });
            });
          }
        });
    } else if (this.cartInvoiceId) {
      this.loading = true;
      await Orders.ordersCreateQuoteInvoiceOrderIdPost(this.cartInvoiceId)
        .then((res) => {
          if (res.data.succeeded) {
            this.invoice = res.data.resultData as any
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          let errors = error.response.data.errors;
          if(errors) {
            errors.forEach((error: any) => {
                this.$notify({ type: "error", text: error.friendlyMessage });
            });
          }
        });
    }
    this.loading = false;
  }

  invoiceDate(dateString: string) {
    let date = new Date(dateString);
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, '-');
  }

  topAnchor() {
    this.scrollTop(`.${this.pageName.toLowerCase()}-content-container`, false);
  }

  printInvoice() {
    window.print();
  }
}
