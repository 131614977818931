
import { mixins, Options } from "vue-class-component";
import { StripeElements, StripeElement } from "vue-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Payments } from "@/network/api";
import { ref } from "@vue/reactivity";
import Spinner from "@/components/spinner/Spinner.vue";
import { FacebookAddPaymentInfoEvent } from '@/seo/facebook-pixel-helper'

@Options({
  props: {
    cartId: { type: String, required: true },
    isActive: { default: true },
    isOutstanding: { default: false },
    isStoreCustomer: { default: false },
    accessKey: { default: undefined }
  },
  components: { StripeElements, StripeElement, Spinner },
})
export default class StripeCardElement extends mixins(FacebookAddPaymentInfoEvent) {
  // Apple pay button addition
  // https://stripe.com/docs/stripe-js/elements/payment-request-button

  stripeKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

  stripeLoaded = false;
  isDisabled = false;
  isLoading = false;
  cartId = "";
  isOutstanding = false;
  isStoreCustomer = false;
  messageId = 0
  termsCard = false;
  accessKey: string | undefined = undefined

  stripe: any;
  elements: any;
  error: string | null = null;
  card: any = ref();
  elms: any = ref();

  async beforeMount() {
    const stripePromise = loadStripe(this.stripeKey as string);
    this.isLoading = true;

    stripePromise.then(async (stripe) => {
      this.stripe = stripe;
      this.stripeLoaded = true;

      let clientSecret = await this.getPaymentIntent();

      if(clientSecret) {
        this.elements = this.stripe.elements({ clientSecret, loader: 'always' });
        const paymentElement = this.elements.create("payment");
        paymentElement.mount("#payment-element");
      } else {
        this.error = "Error initiating payment."
      }
      
      this.isLoading = false;
      
    });
  }

  async getPaymentIntent():Promise<string | null> {
    this.error = null;
    return new Promise((resolve, reject) => {
      if(!this.isStoreCustomer) {
      Payments.paymentsGetStripePaymentDetailsIdGet(this.cartId)
      .then((res) => {
        if(res.data.succeeded) {
          resolve(res.data.resultData!.clientSecret);
        }
      })
      .catch((error) => {
        console.log(error);
        let errors = error.response.data.errors;
        if(errors) {
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        }
        resolve(null)
      })
    } else {
      Payments.paymentsGetStripePaymentDetailsForStoreOrderIdGet(this.cartId)
      .then((res) => {
        if(res.data.succeeded) {
          resolve(res.data.resultData!.clientSecret);
        }
      })
      .catch((error) => {
        debugger
        console.log(error);
        let errors = error.response.data.errors;
        if(errors) {
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        }
        resolve(null)
      })
    }
  })
}

  async pay() {
    if (this.isDisabled) {
      return;
    }
    this.$notify.close(this.messageId);

    this.isDisabled = true;
    this.isLoading = true;
    let self = this;
    const accessKey = (this.accessKey != undefined && this.accessKey != null && this.accessKey != '') ? ('/' + this.accessKey) : ''
    const url = `${window.location.origin}/${this.isStoreCustomer ? 'online-store-payment-complete' : !this.isOutstanding ? 'payment-complete' : 'outstanding-payment-complete'}/${this.cartId}${accessKey}`

    this.facebookAddPaymentInfoEvent();
    const { error } = await this.stripe.confirmPayment({
      elements: self.elements,
      confirmParams: {
        return_url: url,
      },
    });
    if(error.message) {
      this.messageId += 1
      this.$notify({ id: this.messageId, type: "error", text: error.message, ignoreDuplicates: true, duration: -1 });
    }
    this.isDisabled = false;
    this.isLoading = false;
  }

  beforeUnmount() {
    this.$notify.close(this.messageId);
  }
}
