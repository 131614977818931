
import { Options, Vue } from "vue-class-component";

@Options ({
  props: {
    imageSrc: { default: '/img/clifton-image-with-image-extra-1.png'},
    heading: { default: 'Heading' },
    text: { default: ' We listen to our customers, try new things & genuinely care about every order - we have decades of experience in designing quality custom leisurewear and sportswear in an ethically sustainable way.' }
  }
})
export default class InfoPageParagraph extends Vue {}
