
import { mixins, Options, Vue } from "vue-class-component";
import Toast from "@/components/toast/Toast.vue";
import { FormatDate, UploadPath } from "@/mixins/utilities";
import PreviewImage from "@/components/images/PreviewImage.vue";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import BasicVueModal from "@/components/misc/BasicVueModal.vue";
import { ref } from "@vue/reactivity";

@Options({
  components: { Toast, PreviewImage, Splide, SplideSlide, BasicVueModal },
  props: {
    imageUrl: {default: ''},
    backImageUrl: {default: ''},
    name: {default: 'name'},
    isSVG: {default: false},
    bg: {default: 'secondary-light'},
    border: {default: false},
  },
  emits: ['loaded', 'remove', 'add'],
})
export default class CustomStoreOrderProductTile extends mixins(UploadPath, FormatDate) {
  locHost = "";
  designLink = "";
  showToast = false;
  imageUrl = '';
  isSVG = false;
  splide:any = ref();
  spliderConfig = {
    type: 'slide',
    drag: false,
    width: '100%',
    autoHeight: true,
    cover: true,
    arrows: false,
    pagination: false,
    rewind: true,
    classes: {arrow: 'custom-arrow'}
  }
  magnifyDesign = false;


  created() {
    // if (this.product?.id && this.isSVG) {
    //   this.locHost = `${window.location.protocol}//${window.location.host}`;
    //   this.designLink = `${this.locHost}/designer?saved-design=${this.product.id}`;
    // }
  }

  mounted() {
    let allRefs = this.$refs as any;
    this.splide = allRefs.splide;
  }

  get determineImg() {
    if (this.imageUrl) {
      if(this.imageUrl === 'c7a0dd001c17cb361548cac1c2f823e6295a5470/c2b88da6325355782906e141a0f405bf.png') {
        return "/img/no-load.png";
      } else {
        return `${this.uploadPath}/${this.imageUrl}`;
      }  
    } else {
      return "/img/no-load.png";
    }
  }

  flip() {    
    this.splide.go('>');
  }

  removeProduct(id: string) {
    this.$emit('remove', id);
  }
}
