import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6e1a90c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkout-breadcrumbs-container container-fluid d-flex justify-content-center justify-content-sm-start align-items-center px-2 px-sm-4 px-xl-5" }
const _hoisted_2 = { class: "d-flex align-items-baseline" }
const _hoisted_3 = { class: "px-3" }
const _hoisted_4 = { class: "px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["p-sm-primary", {'active': _ctx.isActive === 'cart'}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick('cart')))
      }, _toDisplayString(_ctx.isQuote ? 'Quote' : 'Cart'), 3),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-angle-right" })
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["p-sm-primary", {'active': _ctx.isActive === 'info', 'disabled': ['cart'].includes(_ctx.isActive)}]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClick('info')))
      }, " Information ", 2),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-angle-right" })
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["p-sm-primary", {'active': _ctx.isActive === 'pay', 'disabled': ['cart', 'info'].includes(_ctx.isActive)}]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClick('pay')))
      }, " Payment ", 2)
    ])
  ]))
}