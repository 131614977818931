
import { mixins, Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import { UserAddresses } from "@/network/api";
import { UserAddressListViewModel } from "@/api-client";
import { ScrollTop } from "@/mixins/utilities";

@Options({
  components: { Form, Field, ErrorMessage },
  props: {
    selectedAddress: { default: null },
    addressCount: { default: 0 },
  },
  emits: ['close']
})
export default class EditAddress extends mixins(ScrollTop) {
  selectedAddress: UserAddressListViewModel = {
    id: "",
    country: "",
    addressLine1: "",
    addressLine2: "",
    area: "",
    postalCode: "",
    companyName: "",
    isPrimary: false,
  };
  address: UserAddressListViewModel = {
    id: "",
    country: "",
    addressLine1: "",
    addressLine2: "",
    area: "",
    postalCode: "",
    companyName: "",
    isPrimary: false,
  };
  addressCount = 0;
  deliveryAutoComplete = ''

  created() {
    if(this.selectedAddress.id) {
      this.address = {...this.selectedAddress};
    }

    if(this.addressCount === 0) {
      this.address.isPrimary = true;
    }
  }

  topAnchor() {
    this.scrollTop(".profile-content-container", false);
  }

  setPlace(place:any) {
    let streetNum = '';
    let streetName = '';
    let subPremise = "";
    this.address.addressLine1 = ''
    this.address.addressLine2 = ''
    this.address.area = ''
    this.address.country = ''
    this.address.postalCode = ''

    place.address_components.forEach((component: any) => {
      if (component.types.includes("street_number")) {
        streetNum = component.long_name;
      }
      if (component.types.includes("route")) {
        streetName = component.long_name;
      }
      if (component.types.includes("subpremise")) {
        subPremise = component.long_name;
      }
      if(streetNum || streetName) {
        this.address.addressLine1 = `${streetNum}${streetNum && " "}${streetName}${subPremise && ", "}${subPremise}`;
      }
      
      if (component.types.includes("locality")) {
        this.address.addressLine2 = component.long_name;
      } else if (component.types.includes("postal_town")) {
        this.address.addressLine2 = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1")) {
        this.address.area = component.long_name;
      } else if(component.types.includes("administrative_area_level_2")) {
        this.address.area = component.long_name;
      }
      if (component.types.includes("country")) {
        this.address.country = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        this.address.postalCode = component.long_name;
      }
    });
  }

  submitAddress() {
    if(this.address.id) {
      UserAddresses.userAddressesIdPut(this.address.id, this.address)
      .then((res) => {
        if(res.data.succeeded) {
          this.address = res.data.resultData as UserAddressListViewModel;
          this.$notify({ type: "success", text: "Address updated." });
          this.$emit('close');
        }      
      })
      .catch((error) => {
console.log(error)
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    } else {
      UserAddresses.userAddressesPost(this.address)
      .then((res) => {
        if(res.data.succeeded) {
          this.address = res.data.resultData as UserAddressListViewModel; 
          this.$notify({ type: "success", text: "Address saved." });
          this.$emit('close');
        }      
      })
      .catch((error) => {
console.log(error)
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }  
  }
}
