
import { CalculatePrice, CompareObjects, Currency, GetPriceByQuantity, QuantityPerItem, ThousandSeparator, UploadPath } from "@/mixins/utilities";
import { mixins, Options } from "vue-class-component";
import ProfileProductTile from "@/components/profile/ProfileProductTile.vue";
import PreviewImage from "@/components/images/PreviewImage.vue";
import { CurrencyViewModel, StoreCustomizedProductListViewModel } from "@/api-client";
import { CartItems } from "@/network/api";
import { Form, Field, ErrorMessage } from "vee-validate";
import BasciPromptModal from '@/components/misc/BasicPromptModal.vue'
import { vue3Debounce } from 'vue-debounce';

@Options({
  components: { PreviewImage, ProfileProductTile, BasciPromptModal, Form, Field, ErrorMessage },
  props: {
    product: { default: null },
    cart: { default: null },
    i: { default: 1 },
    storeId: {default: ''},
    storeStatus: { default: '' },
    selectedCurrency: { default: null },
    countryId: { defualt: '' },
    isAdminView: { default: false }
  },
  emits: ['designUpdate', 'delete', 'selected', 'recalculateTotals'],
  directives: {
    debounce: vue3Debounce({ lock: true })
  }
})
export default class CustomStoreProductTile extends mixins(UploadPath, Currency, CalculatePrice, GetPriceByQuantity, QuantityPerItem, ThousandSeparator, CompareObjects) {
  newQuantity = 0;
  product: StoreCustomizedProductListViewModel = {
    id: '',
    expectedQuantity: 0,
    price: 0,
    customizedProductPreviewImageUrl: '',
    customizedProductMinimumQuantity: null,
    customizedProductBackPreviewImageUrl: '',
    customizedProductTitle: '',
    customizedProductName: '',
    customizedProductStyleId: "",
    name: ""
  };
  priceByExpectedQuantity = 0;
  showPriceInsights = true;
  customTitle = '';
  productClone: StoreCustomizedProductListViewModel = {
    id: '',
    expectedQuantity: 0,
    price: 0,
    customizedProductPreviewImageUrl: '',
    customizedProductBackPreviewImageUrl: '',
    customizedProductTitle: '',
    customizedProductName: '',
    customizedProductStyleId: "",
    name: ""
  };
  selectedCurrency: CurrencyViewModel = {
    id: "",
    name: "",
    code: "USD",
    symbol: "",
    pricingMultiplier: 0,
    decimalPlaces: 2,
  };
  storeStatus = '';
  countryId = '';

  get canSave() {
    return this.compareObjects(this.product, this.productClone)
  }

  get isEditable() {
    return ["Editing", "Rejected"].includes(this.storeStatus);
  }

  get unitDifference() {
    const convertedPriceByExpectedQuantity = this.calculatePrice(this.priceByExpectedQuantity, this.selectedCurrency.pricingMultiplier);
    const price = this.calculatePrice((this.productClone.price - +convertedPriceByExpectedQuantity), 1);
    return price;
  }

  get totalDifference() {
    const convertedPriceByExpectedQuantity = this.calculatePrice(this.priceByExpectedQuantity, this.selectedCurrency.pricingMultiplier);
    const price = (+this.productClone.price - +convertedPriceByExpectedQuantity) * +this.productClone.expectedQuantity;
    return price;
  }

  created() {
    this.productClone = {... this.product}
    this.getPriceByExpectedQuantity();

    this.$watch('product', () => {
      this.productClone.price = this.product.price;
    }, {deep:true}) 
  }

  differenceWording(num: number) {
    if(num === 0) {
      return 'difference'
    }

    return 0 > num  ? 'loss' : 'profit'
  }
    
  getPriceByExpectedQuantity() {
    if(this.countryId) {
        CartItems.cartItemsCalculatePriceForCustomizedProductsPost(this.countryId, false, [{customizedProductId: this.productClone.id, quantity: +this.productClone.expectedQuantity }])
      .then((res) => {
        if (res.data.succeeded) {
          this.priceByExpectedQuantity = res.data.resultData?.price as number
        }
      })
      .catch((error) => {
        console.log(error);
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
  }

  handleNumber(value :any, {target}:any, prop: 'expectedQuantity' | 'price') {
    if(prop === 'expectedQuantity' && ((target.value < (this.product.customizedProductMinimumQuantity || 1)) || !target.value)) {
      target.value = target._value = (this.productClone[prop] as any) = (this.product.customizedProductMinimumQuantity || 1);
    } else if (target.value <= 0) {
      target.value = target._value = (this.productClone[prop] as any) = '0';
    } else if(prop === 'price') {
      target.value = target._value = this.productClone[prop] = +Number(target.value).toFixed(2);
    } else {
      target.value = target._value =  this.productClone[prop] =+Number(target.value).toFixed(0);
    }

    if(prop === 'expectedQuantity') {
      this.getPriceByExpectedQuantity()
    }
  }

  updateDesign() {
    this.$emit('designUpdate', this.productClone )
  }
}
