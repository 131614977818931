
import { mixins, Options, Vue } from "vue-class-component";
import GenericPage from "@/components/general-information/GenericPage.vue";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import FeaturedCategories from "@/components/featured/FeaturedCategories.vue";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";

@Options({
  components: {
    GenericPage, StatementBanner, FeaturedCategories
  }
})
export default class TncsPage extends mixins(SetMetaData) {
  content = `<p><strong>1. These terms</strong></p>
    <p>1.1 What these terms cover. These are the terms and conditions on which we supply products and our customisation services to you.</p>
    <p>1.2 Why you should read them. Please read these terms carefully before you submit your order to us. These terms tell you who we are, how we will provide products to you, how you and we may change or end the contract, what to do if there is a problem and other important information. If you think that there is a mistake in these terms, please contact us to discuss.</p>
    <p><strong>2. Information about us and how to contact us</strong></p>
    <p>2.1 Who we are. We are Clifton Concepts Limited t/a CLIFTON, a company registered in Hong Kong. Our company registration number is 2468632 and our registered office is at Unit 1112, 11/F&nbsp;Wing On Plaza, 62 Mody Road, TST East, Hong Kong.</p>
    <p>2.2 How to contact us. You can contact us by emailing <a href="mailto:studio@cliftonclothing.com">studio@cliftonclothing.com</a></p>
    <p>2.3 How we may contact you. If we have to contact you we will do so by telephone or by writing to you at the email address you provided to us in your order.</p>
    <p>2.4 “Writing” includes emails. When we use the words “writing” or “written” in these terms, this includes emails.</p>
    <p><strong>3. Our contract with you</strong></p>
    <p>3.1 Our process for sales which are not online</p>
    <p>3.1.1 For sales of products to you which are not online, our process in relation to taking your product order, designing your products, producing your products and delivering them to you is as follows:</p>
    <p>(i) Once you have contacted us with your requirements, we provide you with pricing for your order;</p>
    <p>(ii) If we are going to provide you with an online shop, we let you know for how long this online shop will be open;</p>
    <p>(iii) If you require design work, we provide you with a design pack which shows your requested colours and logos (not to scale or accurate dimensions);</p>
    <p>(iv) You then provide us with your size breakdown or if you have been provided with a link to an online shop (see clause 5), you complete your order requirements through the online shop;</p>
    <p>(v) If an online shop has been provided, we close this after expiry of the amount of time we have agreed with you;</p>
    <p>(vi) We then provide you with our invoice and you pay the invoice;</p>
    <p>(vii) We will then provide you with an Order Pack with the artwork for your order (see clause 6.2);If required, you send us any adjustments to the contents of the Order Pack;</p>
    <p>(viii) You approve the Order Pack in writing;</p>
    <p>(ix) Once cleared payment and approval has been received by us we will let you know of the estimated delivery date (see clause 11.1).</p>
    <p>3.2 Our process for online sales</p>
    <p>3.2.1 For online sales of products to you, our process in relation to taking your product order, designing your products, producing your products and delivering them to you is as follows:</p>
    <p>(i) You complete your order online specifying the products and sizes and our website will provide you with pricing for your order;</p>
    <p>(ii) If your order requires design work, our website provides you with an illustration which shows your requested colours and logos (not to scale or accurate dimensions);</p>
    <p>(iii) If you complete the box requiring an online shop, you complete your order requirements through the online shop (your order can be saved with a login and password and you will not need to pay for your order until you have completed your online shop);</p>
    <p>(iv) You pay online using your credit or debit card;</p>
    <p>(v) We then provide you with the Order Pack (see clause 6.2);</p>
    <p>(vi) If required, you send us any adjustments to the contents of the Order Pack;</p>
    <p>(vii) Once approval of the Order Pack has been received by us we will let you know of the estimated delivery date (see clause 11.1)…</p>
    <p>3.3 How we accept your order.</p>
    <p>3.3.1 For sales which are not online, we will accept your order after the later of (1) you providing a completed size breakdown to us or (2) if we have provided you with the facility of an online shop in which you complete the size breakdown, the closure of the online shop. We will notify you of our acceptance of your order by email and by providing a summary of your order together with the price (the “Order”), at which point a contract will come into existence between you and us.</p>
    <p>3.3.1 For online sales, our acceptance of your order will take place when we email you to accept it (the “Order”), at which point a contract will come into existence between you and us.</p>
    <p>3.4 If we cannot accept your order. If we are unable to accept your order, we will inform you of this in writing and we will not charge you for the products. We might not be able to accept your order because the products to be produced to your specification are out of stock, because of unexpected limits on our resources which we could not reasonably plan for, because we have identified an error in the price or description of the products.</p>
    <p>3.5 Your order number. We will assign an order number to the Order and tell you what it is when we confirm the Order. It will help us if you can tell us the order number whenever you contact us about the Order.</p>
    <p><strong>4. Design Work</strong></p>
    <p>4.1 If you have requested that we provide design work for your products, we will provide you with a design pack which has your design with your colours and logos. The design will not be accurate in relation to the dimensions of any logos but will be illustrative of the final design. For product sales which are online, the design work will be provided by way of an illustration in your draft order. For sales which are not online, design work takes place before you provide the size breakdown to us or before we provide you with an online shop facility.</p>
    <p><strong>5. Online Store</strong></p>
    <p>5.1 At our discretion, we will offer you an online store. This enables you to select your products, designs & delivery requirements. If an online store is created and approved, you agree that you will purchase the expected number of items for the quoted price (per product) or you will pay us the amount of money equating to the shortfall. Please note that the online store does not enable you or your friends or colleagues to order and have products sent to them directly; instead it is a means for us to collate your requirements and deliver them to one address, unless otherwise agreed in writing.</p>
    <p><strong>6. Our Products and the Order Pack</strong></p>
    <p>6.1 Products on our website or marketing material are for illustrative purposes. Although we have made every effort to be as accurate as possible in relation to the base product, we cannot guarantee that a device’s display of the colours accurately reflects the colour or appearance of the products.</p>
    <p>6.2 The order pack. When we have accepted the Order, we will send you the order pack (the “Order Pack”) which will be accessible via a link provided to you. The Order Pack is important as it is prepared to ensure that we make your products correctly. The Order Pack includes (where applicable):</p>
    <p>6.2.1 your design mock ups;</p>
    <p>6.2.2 the fabric references;</p>
    <p>6.2.3 the colour references;</p>
    <p>6.2.4 the customisation sizes; and</p>
    <p>6.2.5 the size breakdowns (including fit),</p>
    <p>for all the items ordered.</p>
    <p>6.3 Making sure your information is accurate. We recommend that you zoom in on the product designs in the Order Pack to double-check all layouts, spellings, fabric colours, print pantones, embroidery threads, sizes and fit for every item ordered. You can find information and tips on how to measure on our website or by contacting us. Screen images may appear differently on one computer to another and the visuals are for illustration purposes only. If anything is unclear or further clarification is required we recommend you contact us before providing your approval.</p>
    <p>6.4 It is your responsibility to ensure that everything shown in the Order Pack is accurate, correct and reflects your detailed requirements before approving. Once you have provided us with your approval (and for product sales which are not online we have received payment in accordance with clause 10.4.2), we will proceed to production. We cannot amend the contents of the Order Pack once we have received your approval unless otherwise agreed in our absolute discretion. Furthermore as items are bespoke to your requirements, they cannot be exchanged once manufactured.</p>
    <p><strong>7. Content you provide to us</strong></p>
    <p>7.1 It is important that the information, content and logos you provide to us for inclusion in the products (the “Content”) does not breach third party rights.</p>
    <p>7.2 Accordingly you agree that:</p>
    <p>7.2.1 the Content is not copied entirely or substantially from any other work or material or any other source;</p>
    <p>7.2.2 as far as you are aware, the Content does not breach and will not breach, the rights of any third party; and</p>
    <p>7.2.3 the Content does not contain anything that is defamatory or obscene, or unlawful in any other way.</p>
    <p><strong>8. Your rights to make changes</strong></p>
    <p>8.1 Prior to approving the Order Pack, you can make changes to the contents of the Order Pack, provided that this does not vary the Order. Any changes you request to the Order Pack must be sent to us and we will send you a revised Order Pack which we recommend you review and you have to approve, as set out in clauses 6.2 to 6.4 inclusive.</p>
    <p>8.2 If you request any changes which vary the Order as set out in clause 3.3, we shall notify you of this and in agreement with you, we will either vary the Order and send you a revised order confirmation which will then constitute the Order, or the Order will not be varied. If the Order cannot be varied, we will notify this to you.</p>
    <p><strong>9. Our rights to make changes</strong></p>
    <p>9.1 Minor changes to the products. We may change the products ordered by you in minor ways:</p>
    <p>9.1.1 to reflect changes in relevant laws and regulatory requirements ; and</p>
    <p>9.1.2 to implement technical adjustments and improvements (including design & fabric adjustments).</p>
    <p>9.2 Wherever reasonably possible, we will notify you in advance of any such changes.</p>
    <p><strong>10. Price and payment</strong></p>
    <p>10.1 Where to find the price for the products. We take all reasonable care to ensure that the price of the products advised to you is correct. The price of the products will be:</p>
    <p>10.1.1 For product sales which are not online, the price set out in the Order;</p>
    <p>10.1.2 For online product sales, the price set out on our website ordering pages.</p>
    <p>10.2 Delivery. Unless stated otherwise the price for the products excludes package and delivery, and this will be charged in addition. The package and delivery costs are:</p>
    <p>10.2.1 set out in the Order for product sales which are not online;</p>
    <p>10.2.2 set out on our website ordering pages for online product sales.</p>
    <p>10.3 When you must pay and how you must pay:</p>
    <p>10.3.1 For online product sales, you must pay for the products at the time of ordering from our website;</p>
    <p>10.3.2 For product sales which are not online, you must pay for the products set out in the Order in full before we manufacture and/or dispatch them. Cleared payment is due in full as per the due date shown on your invoice. We accept payment by Visa, Mastercard and Amex. Orders over 100 pieces may incur a debit or credit card surcharge.</p>
    <p>10.4 We can charge interest if you pay late. If you do not make any payment to us by the due date we may charge interest to you on the overdue amount at the rate of 3% a month above the base lending rate of Standard Chartered Bank (Hong Kong) from time to time. This interest shall accrue on a daily basis from the due date until the date of actual payment of the overdue amount, whether before or after judgment. You must pay us interest together with any overdue amount.</p>
    <p>10.5 What to do if you think an invoice is wrong. If you think an invoice is wrong please contact us promptly to let us know.</p>
    <p><strong>11. Providing the products</strong></p>
    <p>11.1 When we will deliver the products. When you have confirmed your approval to the Order Pack, we will let you know of the estimated delivery date.&nbsp;Lead times vary with production volumes, with current lead times advertised on our website 'How It Works', 'FAQs' and each product&nbsp;page, and when you approve your order pack your estimated delivery date will be communicated to you via email.</p>
    <p>11.2 We are not responsible for delays outside our control. If our supply of the products is delayed by an event outside our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event.</p>
    <p>11.3 If you are not at home when the product is delivered. If no one is available at your address to take delivery and the products cannot be posted through your letterbox, we will leave you a note informing you of how to rearrange delivery or collect the products from a local depot.</p>
    <p>11.4 If you do not re-arrange delivery. If, after a failed delivery to you, you do not re-arrange delivery or collect the products from a delivery depot we will contact you for further instructions and may charge you for storage costs and any further delivery costs. If, despite our reasonable efforts, we are unable to contact you or re-arrange delivery or collection we may end the contract and clause 14.2 will apply.</p>
    <p>11.5 When you become responsible for the product. The products will be your responsibility from the time we deliver the products to the address you gave us.</p>
    <p>11.6 When you own products. You own the products once we have received payment in full or we have delivered the products to you, whichever is the later.</p>
    <p>11.7 What will happen if you do not give required information to us. We need certain information from you so that we can supply the products to you, for example, quantity, size, design information and delivery address. If you do not give us this information within a reasonable time of us asking for it, or if you give us incomplete or incorrect information, we may either end the contract (and clause 14.2 will apply) or make an additional charge of a reasonable sum to compensate us for any extra work that is required as a result. We will not be responsible for supplying the products late or not supplying any part of them if this is caused by you not giving us the information we need within a reasonable time of us asking for it.</p>
    <p>11.8 Reasons we may suspend the supply of products to you. We may have to suspend the supply of a product to:</p>
    <p>11.8.1 deal with technical problems or make minor technical changes;</p>
    <p>11.8.2 update the products to reflect changes in relevant laws and regulatory requirements;</p>
    <p>11.8.3 make changes to the products as notified by us to you (see clause 9).</p>
    <p>11.9 Your rights if we suspend the supply of products. We will contact you in advance to tell you we will be suspending supply of the products, unless the problem is urgent or an emergency. You may contact us to end the contract for a product if we suspend it, or tell you we are going to suspend it, in each case if such period of suspension is going to be a period of more than one month and we will refund any sums you have paid in advance for the products in respect of the period after you end the contract.</p>
    <p><strong>12. Your rights to end the contract</strong></p>
    <p>12.1 You can end your contract with us in the following circumstances. Your rights when you end the contract will depend on what you have bought, whether there is anything wrong with it, how we are performing and when you decide to end the contract:</p>
    <p>12.1.1 If what you have bought is faulty we will have the product repaired or replaced, see clause 15; or</p>
    <p>12.1.2 If you want to end the contract because of something we have done or have told you we are going to do (excluding those set out in clause 9), see clause 12.2.</p>
    <p>12.2 Ending the contract because of something we have done or are going to do (excluding those set out in clause 9). If you are ending a contract for a reason set out at 12.2.1 to 12.2.2 below the contract will end immediately and we will refund you in full for any products which have not been provided. The reasons are:</p>
    <p>12.2.1 we have told you about a change (excluding those set out in clause 9) or an error in the price or description of the product you have ordered and you do not wish to proceed ;</p>
    <p>12.2.2 we have suspended supply of the products for technical reasons (excluding those set out in clause 9), or notify you we are going to suspend them for technical reasons, in each case for a period of more than one month.</p>
    <p>12.3 Note that because the products are made to your specification or personalised, you do not have the right to change your mind and seek a refund.</p>
    <p><strong>13. How to end the contract with us</strong></p>
    <p>13.1 Tell us you want to end the contract. To end the contract with us which at law you can only do as set out in clause 12.1, please let us know by doing one of the following:</p>
    <p>13.1.1 Email us at <a href="mailto:studio@cliftonclothing.com">studio@cliftonclothing.com</a>. Please provide your name, home address, order number, details of the order and, where available, your phone number and email address.</p>
    <p>13.2 Returning products after ending the contract. If you end the contract, which at law you can only do as set out in clause 12.1, after products have been dispatched to you or you have received them, you must return them to us.</p>
    <p>13.3 When we will accept a return:</p>
    <p>13.3.1 if the products are faulty; or</p>
    <p>13.3.2 if you are ending the contract because we have told you of an upcoming change to the product or these terms, or an error in pricing;</p>
    <p>13.3.3 In all other circumstances a return will not be accepted.</p>
    <p>13.4 How we will refund you. We will refund you the price you paid for the products excluding delivery costs, by the method you used for payment. However, we may make deductions from the price to reflect any reduction in the value of the products if this has been caused by your handling them in a way which would not be permitted in a shop.</p>
    <p>13.5 When your refund will be made. We will make any refunds due to you as soon as possible.</p>
    <p><strong>14. Our rights to end the contract</strong></p>
    <p>14.1 We may end the contract if you break it. We may end the contract for a product at any time by writing to you if:</p>
    <p>14.1.1 you do not make any payment to us when it is due and you still do not make payment within 14 days of us reminding you that payment is due;</p>
    <p>14.1.2 you do not, within a reasonable time of us asking for it, provide us with information that is necessary for us to provide the products, for example, measurements, designs, logos or delivery address;</p>
    <p>14.1.3 you do not, within a reasonable time, allow us to deliver the products to you or collect them from us.</p>
    <p>14.2 You must compensate us if you break the contract. If you end the contract in the situations set out in clause 14.1 we will deduct or charge you reasonable compensation for the net costs we will incur as a result of your breaking the contract and if the compensation is less than the money you have paid to us in advance for products we have not provided, we will refund you this surplus.</p>
    <p>14.3 We may withdraw the products. We may write to you to let you know that we are going to stop providing the products. We will let you know at least 2 weeks in advance of our stopping the supply of the products and will refund any sums you have paid in advance for products which will not be provided.</p>
    <p><strong>15. If there is a problem with the product</strong></p>
    <p>15.1 How to tell us about problems. If you have any questions or complaints about the product, please contact us via <a href="mailto:studio@cliftonclothing.com">studio@cliftonclothing.com</a></p>
    <p>15.2 Summary of your legal rights. We are under a legal duty to supply products that are in conformity with this contract.</p>
    <p>15.3 Your obligation to return rejected products for repair or replacement. If you wish to exercise your right to repair or replacement you must notify us in writing within 21 days, subject to us accepting the return as detailed in clause 13.3.</p>
    <p><strong>16. We are not liable for personal or business losses</strong></p>
    <p>16.1 We only supply the products for domestic and private use. If you use the products for any commercial, business or re-sale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
    <p><strong>17. Other important terms</strong></p>
    <p>17.1 We may transfer this agreement to someone else. We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract. If you are unhappy with the transfer you may contact us to end the contract within one month of us telling you about it and we will refund you any payments you have made in advance for products not provided.</p>
    <p>17.2 You need our consent to transfer your rights to someone else. You may only transfer your rights or your obligations under these terms to another person if we agree to this in writing.</p>
    <p>17.3 Nobody else has any rights under this contract. This contract is between you and us. No other person shall have any rights to enforce any of its terms. Neither of us will need to get the agreement of any other person in order to end the contract or make any changes to these terms.</p>
    <p>17.4 If a court finds part of this contract illegal, the rest will continue in force. Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</p>
    <p>17.5 Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date. For example, if you miss a payment and we do not chase you but we continue to provide the products, we can still require you to make the payment at a later date.</p>
    <p>&nbsp;</p>`;

    created() {
      const {meta} = useMeta({})
    
      this.setMetaData(meta, {}, '', 'Ts & Cs | ')
    }
}
