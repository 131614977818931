
import { mixins, Options, Vue } from "vue-class-component";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import ProductTile from "@/components/products/ProductTile.vue";
import SizeGuidelines from "@/components/products/SizeGuidelines.vue";
import SplideSlider from "@/components/carousel/SplideSlider.vue";
import SplideThumbnailSlider from "@/components/carousel/SplideThumbnailSlider.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import { PriceViewModel, ProductImageViewModel, ProductViewModel } from "@/api-client";
import { Products } from "@/network/api";
import Spinner from "@/components/spinner/Spinner.vue";
import { CalculatePrice, Country, Currency, FormatDate, ScrollTop, SetMetaData, UploadPath } from "@/mixins/utilities";
import { store } from "@/store";
import { useMeta } from "vue-meta";
import Markdown from "vue3-markdown-it";
import EnquiryForm from "@/components/enquiries/EnquiryForm.vue";
import { FacebookViewContentEvent } from '@/seo/facebook-pixel-helper'

@Options({
  components: { SizeGuidelines, StatementBanner, ProductTile, Breadcrumbs, SplideSlider, SplideThumbnailSlider, Spinner, Markdown, EnquiryForm },
  props: {
    productSlug: String,
    originSlug: String,
    categorySlug: { default: [] },
  },
  beforeRouteUpdate(to, from) {
    this.product = {
      id: "",
      name: "",
      slug: "",
      seoTitle: "",
      seoKeywords: "",
      seoDescription: "",
      isFeatured: false,
      isDisabled: false,
      blurb: "",
      thumbnailImages: [],
      galleryImages: [],
      sizeGuidelines: "",
      description: "",
      isNew: false,
      code: "",
      prices: [],
      productionSpeeds: [],
      styles: [],
      primaryCategory: {
        id: "",
        name: "",
        parentSlug: "",
        slug: "",
        fullSlug: "",
        isFeatured: false,
        isDisabled: false,
        hasProducts: false,
        blurb: "",
        thumbnailImages: [],
      },
      relatedProducts: [],
    };
  },
})
export default class ProductPage extends mixins(UploadPath, CalculatePrice, Currency, Country, FormatDate, SetMetaData, ScrollTop, FacebookViewContentEvent) {
  featuredProducts: Array<ProductViewModel> = [];
  product: ProductViewModel = {
    id: "",
    name: "",
    slug: "",
    seoTitle: "",
    seoKeywords: "",
    seoDescription: "",
    isFeatured: false,
    isDisabled: false,
    blurb: "",
    thumbnailImages: [],
    galleryImages: [],
    sizeGuidelines: "",
    description: "",
    isNew: false,
    code: "",
    prices: [],
    productionSpeeds: [],
    styles: [],
    primaryCategory: {
      id: "",
      name: "",
      parentSlug: "",
      slug: "",
      fullSlug: "",
      isFeatured: false,
      isDisabled: false,
      hasProducts: false,
      blurb: "",
      thumbnailImages: [],
    },
    relatedProducts: [],
  };
  toggleInfo = true;
  togglePricing = true;
  toggleDelivery = true;
  productSlug = "";
  originSlug = "";
  selectedImage: ProductImageViewModel = {
    id: "",
    imageUrl: "",
  };
  loading = true;
  productLink: any = "";
  locHost: any = "";
  test = "test";
  categorySlug = [];

  get blocksNeeded() {
    if (this.product.galleryImages) {
      if (this.product.galleryImages.length < 5) {
        return 5 - this.product.galleryImages.length;
      }
    } else {
      return 5;
    }
    return 0;
  }

  created() {
    this.loadProductData().then(() => {
      store.dispatch("user/addRecentlyViewedProduct", this.product);
    });

    this.locHost = `${window.location.protocol}//${window.location.host}`;
    this.productLink = this.locHost + this.$route.fullPath;

    this.$watch("productSlug", () => {
      this.loadProductData().then(() => {
        store.dispatch("user/addRecentlyViewedProduct", this.product);
      });
    });
  }

  mounted() {
    const { meta } = useMeta({});

    this.$watch("product.id", () => {
      if(this.product.id) {
        this.facebookViewContentEvent()
      }
      const fullProductPath = `${window.location.origin}/all/${this.product.primaryCategory?.fullSlug}/product/${this.product.slug}`;
      const schema = {
        type: "application/ld+json",
        json: {
          "@context": "https://schema.org/",
          "@type": "Product",
          name: this.product.name,
          image: this.product.galleryImages.map((img) => `${this.uploadPath}/${img.imageUrl}`),
          description: this.product.seoDescription || this.product.description,
          brand: {
            "@type": "Brand",
            name: "CLIFTON",
          },
          offers: {
            "@type": "Offer",
            url: fullProductPath,
            price: this.calculatePrice(this.product.defaultQuantityPrice as number, this.currency().pricingMultiplier),
            priceCurrency: this.currency().code,
            itemCondition: "https://schema.org/NewCondition"
          },
        },
      };
      this.setMetaData(meta, { ...this.product, canonicalUrl: fullProductPath, script: schema }, "id", "");
    });
  }

  imagePath(item: any) {
    if (item.thumbnailImages[0] && item.thumbnailImages.length > 0) {
      return `${this.uploadPath}/${item.thumbnailImages[0].imageUrl}`;
    }
    return ``;
  }

  handleToEquire() {
    // let leadIn = window.location.pathname;
    // this.$router.push({ name: 'Enquiries', query: {from: leadIn} })
    this.scrollTop("#enquire");
  }

  determineDelivery(type: any, option: string) {
    let deliveryType = {} as any;
    this.country().deliveryTypes.map((item: any) => {
      if (item.deliveryTypeId === type.deliveryTypeId) {
        deliveryType = item;
      }
    });

    if (option === "date") {
      let date = new Date();
      let deliveryDays = deliveryType.deliveryDays;
      let totalDays = deliveryDays + type.productionDays;
      date.setDate(date.getDate() + totalDays);
      return this.formatDate(date);
    }

    if (option === "price") {
      return deliveryType.pricePerOrder + type.pricePerOrder;
    }
  }

  get formattedDescription() {
    if (this.product.description) {
      return this.product.description;
    }
    return "There is no product description available.";
  }

  get formattedBlurb() {
    if (this.product.blurb) {
      return this.product.blurb;
    }
    return "";
  }

  get sortedPrices() {
    return this.product.prices.sort((a: any, b: any) => {
      if (!a.minimumQuantity || a.minimumQuantity < b.minimumQuantity) {
        return -1;
      }
      if (a.minimumQuantity > b.minimumQuantity) {
        return 1;
      }
      return 0;
    });
  }

  getFromToPrice(defaultPrice: any, defaultQty: any, minQty: any) {
    if (!defaultPrice) {
      return ``;
    }

    return `From ${this.currency().symbol}${this.calculatePrice(defaultPrice, this.currency().pricingMultiplier)} each for ${defaultQty} items | Minimum ${minQty}`;
  }

  async loadProductData() {
    this.loading = true;
    await Products.productsSingleProductSlugGet(this.productSlug)
      .then((res) => {
        if (res.data.succeeded) {
          this.product = res.data.resultData as ProductViewModel;
          this.selectedImage = this.product.galleryImages![0] as ProductImageViewModel;
        }

        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }
}
