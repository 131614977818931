
import { Vue, Options } from "vue-class-component";

@Options({
  props: { 
    isActive: { default: "cart" },
    isQuote: { default: false },
  },
  emits: ['toCrumb']
})

export default class CheckoutBreadcrumbs extends Vue {
  isActive: 'cart' | 'info' | 'pay' = 'cart';

  handleClick(type: 'cart' | 'info' | 'pay') {
    // this.isActive = type;

    this.$emit('toCrumb', type)
  }
}
