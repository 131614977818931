
import { OrderHistoryViewModel, OrderPublicStatus } from "@/api-client";
import { CalculatePrice, FormatDate, IsLoggedIn, ThousandSeparator } from "@/mixins/utilities";
import OrderListProductTile from "@/components/profile/order-history/OrderListProductTile.vue";
import OrderListInfoTile from "@/components/profile/order-history/OrderListInfoTile.vue";
import { Vue, Options, mixins } from "vue-class-component";

@Options({
  props: {
    order: { default: null },
    bankDetails: { default: null },
    bankTransfer: { default: false },
    formattedBankDetails: { default: null }
  },
  components: { OrderListProductTile, OrderListInfoTile },
})
export default class CompleteOrderSummary extends mixins(IsLoggedIn, FormatDate, CalculatePrice, ThousandSeparator) {
  order: OrderHistoryViewModel = {
    id: "",
    referenceNumber: "",
    orderPublicStatusId: OrderPublicStatus.Processing,
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    additionalCostsConvertedTotal: 0,
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    orderTotal: 0,
    orderTotalIncludingVoucher: 0,
    orderItems: [],
    deliveryTotal: 0,
    adhocTotal: 0,
    orderInvoices: [],
    customerContacts: [],
    paymentsConvertedTotal: 0,
    paymentsTotal: 0
  };
  bankTransfer = false;

  get voucherValue() {
    if(this.order.voucher && this.order.currency) {
      if(this.order.voucher.voucherTypeId === 'Absolute') {
        return Number(this.calculatePrice(this.order.voucher.value, this.order.currency.pricingMultiplier));
      } else if(this.order.voucher.voucherTypeId === 'Percentage') {
        return Number(this.order.orderTotal * this.order.voucher.value)
      }
    }
    return 0
  }

  calculatedPrice(price: number) {
    if(this.order.currency && (price === 0)) {
      return (0).toFixed(this.order.currency.decimalPlaces);
    } else if (this.order.currency && price) {
      return this.calculatePrice(price, this.order.currency.pricingMultiplier, this.order.currency.decimalPlaces);
    }
    return "Error";
  }
}
