
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { mixins, Options, Vue } from "vue-class-component";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { UploadPath } from '@/mixins/utilities';
import { BannerItemAdminViewModel } from '@/api-client';
import Markdown from 'vue3-markdown-it';
import { marked } from "marked";

@Options({
  components: { Splide, SplideSlide, Markdown },
  props: {
    imageArray: { default: [] },
    altText: { default: '' }
  },
})
export default class SplideCarousel extends mixins(UploadPath) {
  spliderConfig = {
    type: 'loop',
    autoplay: true,
    pagination: true,
    resetProgress: true,
    pauseOnHover: true,
    pauseOnFocus: false,
    interval: 5000,
    arrows: true,
  }

  checkContent(image: BannerItemAdminViewModel) {
    if (image.boxTitle || image.boxDescription || image.buttonText) {
      return true;
    }
    return false;
  }

  formattedMarkdown(text: string) {
    if (text) {
      let markedDetails = marked(text)
      markedDetails = markedDetails.replaceAll('<p>', "");
      markedDetails = markedDetails.replaceAll('</p>', "");
      markedDetails = markedDetails.replaceAll('<div>', "");
      markedDetails = markedDetails.replaceAll('</div>', "");
      markedDetails = markedDetails.replaceAll('\n', "<br>");

      // Remove <br> if at the end of string
      markedDetails = markedDetails.replace(/<br\s*\/?>$/, '');
      
      return markedDetails;
    }
    return "";
  }
}
