
import { Options, Vue } from "vue-class-component";
import GenericPage from "@/components/general-information/GenericPage.vue";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import FeaturedProducts from "@/components/featured/FeaturedProducts.vue";
import { GeneralSettings } from "@/network/api";
import Markdown from "vue3-markdown-it";
import Spinner from "@/components/spinner/Spinner.vue";

@Options({
  components: {
    GenericPage, StatementBanner, FeaturedProducts, Markdown, Spinner 
  }
})
export default class ShippingAndReturnsPage extends Vue {
  loading = true;
  content = "";
  
  created() {
    this.loadInitialData()
  }

  loadInitialData() {
    this.loading = true;
    GeneralSettings.generalSettingsKeyGet('shipping-and-returns')
    .then((res) => {
        if (res.data.succeeded && res.data.resultData) {
          this.content = res.data.resultData;
        } else {
          this.$notify({ type: "error", text: "Page content not found.", ignoreDuplicates: true, duration: -1 });
        }

        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }
}
