import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "section-products-container container-fluid w-100"
}
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplideSlider = _resolveComponent("SplideSlider")!

  return (_ctx.featuredProducts.length >= 4)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SplideSlider, {
            imageArray: _ctx.featuredProducts,
            isCategory: false,
            tileCategory: 'Featured Product',
            infoLinkTo: 'Product'
          }, null, 8, ["imageArray"])
        ])
      ]))
    : _createCommentVNode("", true)
}