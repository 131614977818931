
import { mixins, Options, Vue } from "vue-class-component";
import { Products } from "@/network/api";
import { ProductViewModel } from "@/api-client";
import SplideSlider from "../carousel/SplideSlider.vue";
import { UploadPath } from "@/mixins/utilities";

@Options({
  components: {
    SplideSlider
  },
})
export default class FeaturedProducts extends mixins(UploadPath) {
  featuredProducts: Array<ProductViewModel> = [];

  created() {
    this.loadInitialData();
  }

  loadInitialData() {
    Products.productsFeaturedGet(1, 99999)
      .then((res) => {
        console.log(res);
        this.featuredProducts = res.data.resultData
          ?.items as Array<ProductViewModel>;
      })
      .catch((error) => {
console.log(error)
        console.log(error);
      });
  }
}
